import React, {useState, useContext, useRef, useEffect} from "react";
import {
    Box, Button,Divider, Paper,Typography, Table, TableBody,TableCell,TableContainer,TableRow, CircularProgress} from "@mui/material";
import {grey} from "@mui/material/colors";
import MainContext from "../../context";
import Axios, {AxiosError} from 'axios';
import {useAuthUser} from "react-auth-kit";
import Grid from "@mui/material/Unstable_Grid2";
import { InitTasks } from "./StaffChart";
import { tabsData } from "../../components/data/tabsData.sidebar";
import axios from 'axios';
import {PDFFile} from "./index";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {format} from "date-fns";

function TableRows({checkin_table, offDate, absenceDate})
{
    if (checkin_table.length > 0)
    {
        return checkin_table.map((rowsData, ind) =>
        {
            let fontcolorD = "#626060";
            let fontcolorS = "#626060";
            let fontcolorE = "#626060";

            if (offDate.includes(rowsData[1])){
                fontcolorD = "#f44336a1";
            }else if (absenceDate.includes(rowsData[1])){
                fontcolorD = "#f44336a1";
            }
            let hours = ""
            let minutes = ""
            let startTime = ""
            let index = ""
            if (rowsData[2] !== null){
                index = (rowsData[2]).indexOf(":");
                if (index !== -1) {
                    hours = parseInt((rowsData[2]).substring(0, index));
                    minutes = parseInt( (rowsData[2]).substring(index + 1, (rowsData[2]).length));
                    startTime = hours * 60 + minutes
                    if ( 9 * 60 < startTime){
                        fontcolorS = "#801717";
                    }
                }
            }


            if (rowsData[3] !== null){
                index = (rowsData[3]).indexOf(":");
                if (index !== -1 && startTime !== ""){
                    hours = parseInt((rowsData[3]).substring(0, index));
                    minutes = parseInt( (rowsData[3]).substring(index + 1, (rowsData[3]).length));
                    if (hours * 60 + minutes - startTime < 9 * 60 + 30){
                        fontcolorE = "#801717";
                    }
                }
            }


            return (
                <TableRow key={ind}  className="myTasksRow" >
                    <TableCell sx={{textAlign:"left"}} >
                        <Typography
                            variant = "caption"
                            className="performance4"
                            sx = {{color: fontcolorD}}
                        >
                            {rowsData[1]}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{textAlign:"center"}} >
                        <Typography
                            variant = "caption"
                            className="performance4"
                            sx = {{color: fontcolorS}}
                        >
                            {rowsData[2]}
                        </Typography>
                    </TableCell>
                    <TableCell  sx={{textAlign:"right"}} >
                        <Typography
                            variant = "caption"
                            className="performance4"
                            sx = {{color: fontcolorE}}
                        >
                            {rowsData[3]}
                        </Typography>
                    </TableCell>
                </TableRow>
            );
        });
    }
}

const StaffActivity = ({staffDetail}) => {

    const auth = useAuthUser();
    // const [ipDetails, setIpDetails] = useState([]);
    const {Staff, userInfo, projList, staffPerformance, staffOffDate, projectInvolved, taskHolder,overdue, progress,OffSiteDays, OffDays,absenceDays,tripDates, checkin,} = useContext(MainContext);
    const data = tabsData()[1];

    const staffHourse = useState(InitTasks()[1]);
    let userIndex = Staff.findIndex(item => item === userInfo.name);
    let userHourse = staffHourse[0][userIndex];

    // output from another function
    const monthHours = 160.0;
    let overTime = 0
    if (userHourse){
        overTime = userHourse - monthHours;
    }

    let projectManager = 0;
    let table0 = projList.filter(row =>  ((!(Array.isArray(row[4])) && (staffDetail.name === row[4])) || ((Array.isArray(row[4])) && (row[4].includes(staffDetail.name)))))
    projectManager = table0.length;

    let absenceDate = []
    // const currentDate = new Date();






    // function totalMinutes(time) {
    //     let index = (time).indexOf(":");
    //     let hours = parseInt((time).substring(0, index));
    //     let minutes = parseInt( (time).substring(index + 1, (time).length));
    //     let totalMinutes = hours * 60 + minutes;
    //     return totalMinutes;
    // }




    useEffect( () => {
        staffPerformance(staffDetail.name, staffDetail.group)
    }, []);


    const itemEls = useRef({})
    const inputRef = useRef();

    const handleClick = () => {
        inputRef.current.focus();
    };


    return (
            < >
                <Box sx = {{width: "100%", mt: 7, textAlign: "center"}}>
                    <Typography variant="subtitle1" sx={{fontWeight: 700, color: "#031830",lineHeight: 0.5}}>
                        {staffDetail.name}
                    </Typography>
                    <Typography variant="caption" sx={{fontWeight: 400, color: "#031830"}}>
                        {staffDetail.position}
                    </Typography>
                    <Divider variant="middle"  sx = {{border: "2px solid gray", mt: 1}} />
                </Box>

                <Grid container  sx = {{mt : 2 }}>
                    <Grid xs= {9}>
                        <Grid container>
                            <Grid xs= {3}>
                                <Box className="performanceBox">
                                    <Typography variant="subtitle2" className="performanceHead1">
                                        management records
                                    </Typography>
                                    {/*<Typography variant="caption" className="performanceHead2" >*/}
                                    {/*    Total project as a manager*/}
                                    {/*</Typography>*/}
                                    <Box sx={{textAlign: "center" }}>
                                        <Typography className="performanceHead3" >
                                            {projectManager}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs= {3}>
                                <Box className="performanceBox">
                                    <Typography variant="subtitle2" className="performanceHead1">
                                        No. of tasks assigned
                                    </Typography>
                                    {/*<Typography variant="caption" className="performanceHead2">*/}
                                    {/*    Total tasks in a month*/}
                                    {/*</Typography>*/}
                                    <Box sx={{textAlign: "center" }}>
                                        <Typography className="performanceHead3" sx = {{display: 'inline-block'}}>
                                            {taskHolder}
                                        </Typography>
                                        {/*<Typography sx = {{display: 'inline-block', fontWeight: 700, fontSize: "1.5rem", color: "#031830", pl: 1 }}  >*/}
                                        {/*    hr(s)*/}
                                        {/*</Typography>*/}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs= {3}>
                                <Box className="performanceBox">
                                    <Typography variant="subtitle2" className="performanceHead1">
                                        Off-site activity
                                    </Typography>
                                    {/*<Typography variant="caption" className="performanceHead2"></Typography>*/}
                                    <Box sx={{textAlign: "center" }}>
                                        <Typography className="performanceHead3" sx = {{display: 'inline-block'}}  >
                                            {OffSiteDays}
                                        </Typography>
                                        <Typography sx = {{display: 'inline-block', fontWeight: 700, fontSize: "1.0rem", color: "#031830", pl: 1 }}  >
                                            day(s)
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs= {3}>
                                <Box className="performanceBox">
                                    <Typography variant="subtitle2" className="performanceHead1">
                                        Business Trip
                                    </Typography>
                                    {/*<Typography variant="caption" className="performanceHead2"></Typography>*/}
                                    <Box sx={{textAlign: "center" }}>
                                        <Typography className="performanceHead3" sx = {{display: 'inline-block'}} >
                                            {tripDates}
                                        </Typography>
                                        <Typography sx = {{display: 'inline-block', fontWeight: 700, fontSize: "1.0rem", color: "#031830", pl: 1 }}  >
                                            day(s)
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container  sx = {{fontSize: "1.2rem" }} color = {grey[600] }>
                            <Grid xs= {3}>
                                <Box className="performanceBox">
                                    <Typography variant="subtitle2" className="performanceHead1">
                                        Vacation per month
                                    </Typography>
                                    <Box sx={{textAlign: "center" }}>
                                        <Typography className="performanceHead3" sx = {{display: 'inline-block'}} >
                                            {OffDays}
                                        </Typography>
                                        <Typography sx = {{display: 'inline-block', fontWeight: 700, fontSize: "1.0rem", color: "#031830", pl: 1 }}  >
                                            day(s)
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid xs= {4}>
                                <Box className="performanceBox">
                                    <Typography variant="subtitle2" className="performanceHead1">
                                        Overdue tasks
                                    </Typography>

                                    <Box sx={{textAlign: "center" }}>
                                        {/*<Box sx = {{fontWeight: 700, fontSize: "2.75rem", color: "#801717", display: 'inline-block', mt: 2 }} >*/}
                                        {/*    <Typography sx = {{fontWeight: 700, fontSize: "2.75rem", color: "#801717",  }} >*/}
                                        {/*        {overdue}*/}
                                        {/*    </Typography>*/}
                                        {/*</Box>*/}

                                        <Box sx={{textAlign: "center", display: 'inline-block' }} >
                                            <Typography className="performanceHead3"  >
                                                {overdue}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ position: 'relative', display: 'inline-block', mr: 5, mt:2, float: "right" }}>
                                            { progress > 0 ?
                                                <>
                                                    <CircularProgress  variant="determinate" value = {progress}  />
                                                    <Box
                                                        sx={{
                                                            top: 0,
                                                            left: 0,
                                                            bottom: 0,
                                                            right: 0,
                                                            position: 'absolute',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="caption" component="div" color="#031830">
                                                            {`${Math.round(progress)}%`}
                                                        </Typography>
                                                    </Box>
                                                </>
                                                : null
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs= {2}>
                                <Box className="performanceBox">
                                    <Typography variant="subtitle2" className="performanceHead1">
                                        Absence days
                                    </Typography>
                                    {/*<Typography variant="caption" className="performanceHead2"></Typography>*/}
                                    <Box sx={{textAlign: "center" }}>
                                        <Typography className="performanceHead3" >
                                            {absenceDays}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs= {3}>
                                <Box className="performanceBox">
                                    <Typography variant="subtitle2" className="performanceHead1">
                                        Project involved
                                    </Typography>
                                    <Box
                                        sx = {{
                                            height: '130px',
                                            overflowY: "scroll",
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box
                                            sx = {{
                                                height: "100%",
                                                overflowY: "scroll",
                                                display: 'flex',
                                                flexDirection: 'column',
                                                textAlign: "center",
                                                margin: "20px",
                                                //mb: 2,
                                            }}
                                        >
                                            {projectInvolved.map((project, ind) => (
                                                <Typography key={ind} variant="caption" className="performanceHead2" >
                                                    {project}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs= {3} id = "performance">
                        <Box className="checkinBox">
                            <Typography variant="subtitle2" className="performanceHead1" sx = {{textAlign: "center"}}>
                                Checkin - Checkout
                            </Typography>
                            <Box
                                sx = {{
                                    height: '298px',
                                    overflowY: "scroll",
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box
                                    sx = {{
                                        height: "100%",
                                        overflowY: "scroll",
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: "center",
                                        margin: "25px 40px 38px 40px"
                                    }}
                                >
                                    <TableContainer sx={{textAlign: "center", backgroundColor: "white"}}>
                                        <Table sx={{width: "100%"}}>
                                            <TableBody>
                                                <TableRows
                                                    checkin_table={checkin}
                                                    offDate={staffOffDate}
                                                    absenceDate={absenceDate}

                                                />
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>



                <Grid container>
                    <Grid xs= {8}> </Grid>
                    <Grid xs= {4}>
                        <PDFDownloadLink
                            document={<PDFFile
                                staffDetail = {staffDetail}
                                projectManager = {projectManager}
                                taskHolder = {taskHolder}
                                offsiteActivities = {OffSiteDays}
                                Overtime = {overTime}
                                offDays = {OffDays}
                                Overdue = {overdue}
                                Trips = {tripDates}
                                Absence = {absenceDays}
                            />}

                            filename="FORM"
                        >
                            {({loading}) => (loading ?
                                    <Box sx={{ml: "10px", mt: 3, pr: 2 , backgroundColor: "red"}} id = "calender">
                                        <Button
                                            className="editBtn"
                                            sx={{backgroundColor: "#153250",float: "right"}}
                                        >
                                            Loading Document ...
                                        </Button>
                                    </Box>
                                    :
                                    <Box sx={{ml: "10px", mt: 3, pr: 2, backgroundColor: "red"}} id = "calender">
                                        <Button
                                            className="editBtn"
                                            sx={{backgroundColor: "#153250",float: "right"}}
                                        >
                                            Download activity record
                                        </Button>
                                    </Box>
                            )}
                        </PDFDownloadLink>
                    </Grid>
                </Grid>

            </>

    );
};
export default StaffActivity;

