import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import {ArrowDropDownRounded, ArrowRightRounded } from '@mui/icons-material';
import {Box} from "@mui/material";
import {useContext, useState} from "react";
import MainContext from "../../context";
import {SidebarHeader, SidebarFooter} from "./index";
import { tabsData } from "../data/tabsData.sidebar";
import Pdf from '../../assets/Management/userManual.pdf';

const SidebarTabs = () => {

    const {userInfo, tabClick, pageNumber, handlePageNumber, subTabs, projList } = useContext(MainContext);

    const data = tabsData();
    const [openCollapse, setOpenCollapse] = useState(false);

    const handleClick = (id) => {
        //setOpenCollapse(!openCollapse);
        setOpenCollapse((prevstate) => ({ ...prevstate, [id]: !prevstate[id]}));
    };
    const tabs2 = [
        { label: "Airworthiness"},
        { label: "ISM"},
        { label: "Projects" },
        { label: "Proposals" },
    ];

    const tabs4 = [
        { label: "Progress Chart"},
        { label: "Insert Input"},
        { label: "Activity Record"},
    ];
    const tabs1 = [
        { label: "My Dashboard", subTab: false, subTabItems: []},
        { label: "Projects",  subTab: true, subTabItems: subTabs},
        { label: "Management Panel", subTab: true, subTabItems: tabs4 },
        { label: "Administrator", subTab: false, subTabItems: [] },
        { label: "User Manual", subTab: false, subTabItems: [] },
    ];
    let temp = 0;
    let tabIndexes = {}
    for (let i=0; i < tabs1.length; i++)
    {
        if (tabs1[i].subTab === false)
        {
            tabIndexes[i.toString()] = temp;
            temp += 1;
        }else{
            for (let j=0; j < (tabs1[i].subTabItems).length; j++){
                tabIndexes[i.toString() + "+" + j.toString()] = temp;
                temp += 1;
            }
        }
    }

    let projectManager = 0;
    let table0 = projList.filter(row =>  ((!(Array.isArray(row[4])) && (userInfo.name === row[4])) || ((Array.isArray(row[4])) && (row[4].includes(userInfo.name)))))
    projectManager = table0.length;

    return(
        <Box
            sx={{
                height: "100vh",
                overflowY: "scroll",
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <SidebarHeader   />

            <Box
                sx = {{mt: "3rem",textAlign: "left"}}
            >
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: "#153250", fontSize: "0.75rem"}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    value={pageNumber}
                    onChange={handlePageNumber}
                    id = "sidbarlist"
                >
                    {tabs1.map((tab, index) => (
                        <Box
                            key={index}

                        >

                            { (tab.label !== "User Manual") ?
                                <ListItemButton
                                    key={index}
                                    onClick={() =>  {tab.subTab ? handleClick(index) : tabClick(tabIndexes[index.toString()], tab.label )}}
                                    disabled={((tab.label === "Management Panel" && userInfo.admin === "No" &&  projectManager === 0) || (tab.label === "Administrator" && userInfo.admin === "No") ) ? true: false}
                                >
                                    {tab.subTab ? (openCollapse[index] ? <ArrowRightRounded sx={{color: "#c3bebe", ml: -1}}/> : <ArrowDropDownRounded sx={{color: "#c3bebe",  ml: -1}}/>) : null}
                                    <ListItemText key={index} primary= {tab.label} />
                                </ListItemButton>
                               :
                                <ListItemButton
                                    key={index}
                                     href = {Pdf} target = "_blank"
                                >
                                    <ListItemText key={index} primary= {tab.label} />
                                </ListItemButton>
                            }

                            {(tab.subTab && ( (tab.label === "Administrator" && userInfo.admin === "Yes") || ((tab.label === "Management Panel") && (userInfo.admin === "Yes" || projectManager > 0)) || tab.label === "Projects")   ) ?
                                <Collapse
                                    key={"Collapse" + index}
                                    in={openCollapse[index]} timeout="auto" unmountOnExit>
                                    <List
                                        key={"list" + index}
                                        component="div"
                                        disablePadding
                                    >
                                        {tab.subTabItems.map((subtab, subindex) => (
                                            <ListItemButton sx={{ pl: 4 }}
                                                            key={parseInt(index.toString() + subindex.toString())}
                                                            onClick={() => tabClick(tabIndexes[index.toString() + "+" + subindex.toString()] , subtab.label) }
                                                            disabled={(tab.label === "Management Panel" && subtab.label === "Activity Record" && userInfo.admin === "No" ) ? true: false}
                                            >
                                                <ListItemText key={parseInt(index.toString() + subindex.toString())} primary={subtab.label} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                               </Collapse>
                              : null}
                        </Box>
                    ))}
                </List>
            </Box>

            <SidebarFooter />

        </Box>

    );
}

export default SidebarTabs;
