import React, {useEffect} from "react";
import { useState, useContext} from "react";
import MainContext from "../../context";
import {red} from "@mui/material/colors";
import axios, {AxiosError} from "axios";
import {useAuthUser} from "react-auth-kit";

function createArray(N) {
    return [...Array(N).keys()].map(i => i );
}

export function InitTasks() {
    const { Staff, Category, projData, projList, setError } = useContext(MainContext);
    let tasks = [];
    let temp = 0;
    let staffHours_current = [];
    let staffHours_next = [];
    const currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    let currentYear = currentDate.getFullYear();
    const auth = useAuthUser();
    let state = {}

    function detrmineTime(){
        for (let i=0; i < Staff.length; i++ )
        {
            let totalHours_current = 0
            //let totalHours_next = 0
            for (let j=0; j < projList.length; j++)
            {
                let table = []
                table = projData[j].filter(row => row[2] === Staff[i][0])
                if (table.length > 0)
                {
                    //table_rows = table_rows.concat(table)
                    for (let k=0; k < table.length; k++)
                    {
                        let date0 =  new Date(table[k][11]);
                        let date1 = new Date(table[k][4]);
                        let month0 = date0.getMonth();
                        let month1 = date1.getMonth();
                        let day0 =  date0.getDate();
                        let day1=  date1.getDate();
                        if (month0 !== month1)
                        {
                            if (currentMonth === month0){
                                totalHours_current += ((30 - day0 + 1) * parseInt(table[k][6]))/ (day1 + (30 - day0) + 1)
                                //totalHours_next += ((day1) * parseInt(table[k][6]))/ (day1 + (30 - day0) + 1)
                            }else if (currentMonth === month1){
                                totalHours_current += ((day1) * parseInt(table[k][6]))/ (day1 + (30 - day0) + 1)
                            }
                        }else
                        {
                            if (currentMonth === month0)
                            {
                                totalHours_current += parseInt(table[k][6]);
                            }
                            // else if (currentMonth + 1 === month0  ){
                            //     totalHours_next += parseInt(table[k][6]);
                            // }
                        }
                    }
                }
            }
            staffHours_current.push(Math.ceil(totalHours_current))
        }
        state =
            {
                labels: Staff.map(i => i[0]).flat(),
                datasets: [
                    {
                        id: 1,
                        label: "Current month manhours",
                        backgroundColor: "#e7a25a",
                        borderColor: "#e7a25a",
                        borderWidth: 1,
                        data: staffHours_current,
                        barPercentage: 20,
                        barThickness: 20,
                    },
                    {
                        id: 2,
                        label: "Next month manhours",
                        backgroundColor: "#2a647b",
                        borderColor: "#2a647b",
                        borderWidth: 1,
                        data: staffHours_next,
                        barPercentage: 20,
                        barThickness: 20,
                    },
                ],
            }
    }

    async function fetchData() {
        const month = new Date().toLocaleString('default', { month: 'long' });
        try {
            const response = await axios.post(auth().website + "/GetStafftimes",
                [month]
            );
            staffHours_next = response.data
            detrmineTime()
        } catch (err) {
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);
        }
    }
   fetchData()
   return [state, staffHours_current];
}

export function GetStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;
    for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }
    return [start, end];
}
