import React from "react";
import { useContext, useEffect, useRef} from "react";
import {Box, Button, Divider, Paper, Typography} from "@mui/material";
import MainContext from "../../context";
import {ArchivedTableRows} from "./index";
import {grey} from "@mui/material/colors";

const ArchivedPage = ({pageTitle}) => {
    const { finishedProject, elRefs, originalRef} = useContext(MainContext);
    return(
        <>
            <Box
                sx={{
                    height: "100vh",
                    overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: "#F0F2F3",
                }}
            >
                <Box sx = {{mb: 2, ml:5, mr:5,mt: 2}} ref={originalRef}>
                    <h3 className= "headerClass"  > {pageTitle} </h3>
                </Box>
                <Divider variant="middle"  sx={{ height: 2,  mt: 1,  backgroundColor:grey[400]}} />
                <Box
                    sx = {{
                        height: "100%",
                        overflowY: "scroll",
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: "1.2rem",
                        mb: 2, ml:4, mr:4,
                    }}
                    id = "taskPage"
                >
                    <Box sx={{mt: 4}}>
                        {finishedProject.length > 0 ?
                            finishedProject.map((item, key) =>
                            {
                                let index = key
                                let typeP = "Finished";
                                return(
                                    <Box
                                        key = {key}
                                        id="projectContainer"
                                        sx = {{ backgroundColor: "white", borderRadius: "15px", mb: 3 }} >
                                        <Box key={key} sx={{ mt:2, ml:2, mr: 2}}  ref={elRefs[key]}>
                                            <ArchivedTableRows
                                                index = {index}
                                                typeP = {typeP}
                                            />
                                        </Box>
                                    </Box>
                                );
                            })
                            :
                            <Box sx = {{ margin: "15% auto", boxShadow: "0 0 10px 5px #0000002e",maxWidth: "600px", textAligh: "center", borderRadius: "3px"}} >
                                <Box sx = {{textAlign: "center", p : 3}}>
                                    <Typography> There is no finished project yet! </Typography>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>

        </>
    );
}
export default ArchivedPage;

