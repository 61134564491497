import React from "react";
import {useContext} from "react";
import MainContext from "../../context";

function createArray(N) {
    return [...Array(N).keys()].map(i => i );
}

export function ProjHours({Cat}) {
    const { projData, projList } = useContext(MainContext);

    let projectDiffHours = [];
    let projectIndexes = []
    let typeProject = []
    if (Cat === "In-progress"){
        typeProject = projList;
        projectIndexes = createArray(projList.length);
    }else{
        let indices = []
        for(let i=0; i < Cat.length;i++) {
            if (Cat[i] === "-"){
                indices.push(i);
                break
            }
        }
        for(let i = Cat.length -1; i > -1;i--) {
            if (Cat[i] === "-"){
                indices.push(i);
                break
            }
        }
        let str1 = Cat.substring(1, indices[0]);
        let str2 = Cat.substring(indices[0] + 1, indices[1])
        let str3 = Cat.substring(indices[1] + 1, Cat.length)
        let project_index = projList.findIndex(row => row[2] === str2 && row[3] === str1 && row[8] === str3 )


        //let project_index = projList.findIndex(row => (row[3] === Cat.substr(1, indices[0] - 1)) && (row[2] === Cat.substr(indices[0] + 1, indices[1] - (indices[0] + 1)))  && (row[8] === Cat.substr(indices[1] + 1, Cat.length - indices[1])) )
        typeProject = [projList[project_index]]
        projectIndexes.push(project_index)
    }

    let typeProjectDiff = []
    if (typeProject.length > 0){
        for (let j = 0; j < typeProject.length; j++)
        {
            let index = projectIndexes[j];
            let DiffTime = 0;
            let estimatedTime = 0;
            if (projData[index].length > 0)
            {
                for (let k = 0; k < projData[index].length; k++)
                {
                    if (projData[index][k][7] !== "" && projData[index][k][13] === "")
                    {
                        DiffTime += parseInt(projData[index][k][7]) - parseInt(projData[index][k][6]);
                        estimatedTime += parseInt(projData[index][k][6])
                    }
                }
            }
            let key = {"project": "#" + projList[index][3] + "-" + projList[index][2] + "-" + projList[index][8], "DiffTime": DiffTime, "estimHours": estimatedTime }
            typeProjectDiff.push(key)
        }
    }
    projectDiffHours.push(typeProjectDiff)

    return projectDiffHours;
}





