import React, { useState, useEffect, createRef } from "react";
import MainLayout from "../layouts/MainLayout";
import { Sidebar } from "../components/sidebar";
import PagesContainer from "./PagesContainer";
import SidebarContainer from "./SidebarContain";
import MainContext from "../context";
import {MainPage, Test, Test2, MentionDrawer} from "../pages/projmngmt/index";
import axios, {AxiosError} from "axios";
import {Box, Typography} from "@mui/material";
import {useAuthUser} from 'react-auth-kit';
import { tabsData } from "../components/data/tabsData.sidebar";
import {addDays, format, subDays} from 'date-fns';
function createArray(N) {
    return Array.from({length: N}, (_, index) => index + 1);
}

const TaskManagement = () => {
    const [openModal, setOpenModal] = useState(false);

    const [elRefs, setElRefs] = useState([]);
    const [targetRefIndex, setTargetRefIndex] = useState("");
    const [userInfo, setUserfInfo] = useState({
        name: "",
        position: "",
        group:"",
        manager:"",
        mangergroup:"",
        admin:"",
    });
    const data = tabsData()[1];

    const [projData, setProjData] = useState([]);
    const [projList, setProjList] =  useState([]);
    const [projProgress, setProjProgress] = useState([]);
    const [subTasks, setSubTasks] = useState([]);
    const [tempId, setTempId] = useState([]);
    const [maxOrder, setMaxOrder] = useState([]);

    const [finishedProject, setFinishedProject ] =useState([]);
    const [finishProjData, setFinishProjData ] = useState([]);
    const [finishProjProgress, setFinishProjProgress ] =  useState([]);

    const [onholdProject, setOnholdProject ] =useState([]);
    const [onholdProjData, setOnholdProjData ] = useState([]);
    const [onholdProjProgress, setOnholdProjProgress ] =  useState([]);
    const [onholdProjSubTasks,setOnholdProjSubTasks ] = useState([]);
    const [onholdProjMaxOrder,setOnholdProjMaxOrder ] = useState([]);

    const auth = useAuthUser()
    const headCells = [{"id": 0 , "label": 'ID', "width": 25}, {"id": 1 , "label": 'Task', "width": 280}, {"id": 2 ,"label": 'Main Task', "width":60}, {"id": 3 ,"label": 'Owner', "width": 100}, {"id": 4 ,"label": 'Priority', "width": 80}, {"id": 5,"label": 'Estimated H', "width":80}, {"id": 6 ,"label": 'Predecessor ', "width": 100}, {"id": 7,"label": 'Start date', "width": 140 }, {"id": 8,"label": 'Due date', "width":140 }, {"id": 9 ,"label": 'Status', "width": 80 }, {"id":10 ,"label": 'Actual H', "width":80},{"id": 11 ,"label": 'Progress %', "width":130},{"id": 12 ,"label": 'Comment', "width":100} ];
    const Category = ["DOA - Airworthiness", "DOA - ISM", "DOA - Projects", "DOA - Proposals", "POA - Quality System", "POA - Projects", "R & D"];
    const [Staff, setStaff ] =useState([]);
    const [open, setOpen] = useState({});
    const [openF, setOpenF] = useState({});
    const [openH, setOpenH] = useState({});
    let newDate = new Date();
    const currentDate = newDate.toISOString().split('T')[0];
    const [subTabs, setSubTabs] = useState([]);
    const [offRecord, setOffRecord ] = useState([]);
    const [ProjectTitle, setProjectTitle] = useState([]);
    const [customerName, setCustomerName] = useState([]);
    const [taskTitle, setTaskTitle] = useState([]);
    const [editedTasks, setEditedTasks] = useState([]);

    useEffect(() => {
        setError("");
        async function fetchData() {
            const date = format(new Date, 'yyyy-MM-dd' )
            try {
                const response = await axios.post(auth().website + "/GetProjectData",[date]);
                setStaff((response.data[0]).sort());
                setOffRecord(response.data[1]);
                setTaskTitle((response.data[2]).sort())
                setProjectTitle((response.data[3]).sort())
                setCustomerName((response.data[4]).sort())
                //setProjList(response.data[5]);
                let archivedList = [];
                let nonArchivedList = [];
                let currentProject = [];
                let closedProject = [];
                let holdProject = [];
                let holdList = [];
                for (let i=0; i < response.data[5].length; i++)
                {
                    if ((response.data[5][i])[4][0] === "["  ){
                        response.data[5][i][4] = JSON.parse(response.data[5][i][4].replace(/'/g, '"'))
                    }
                    if (response.data[5][i][10] === "Finished")
                    {
                        archivedList.push(i);
                        closedProject.push(response.data[5][i]);
                    }
                    else if (response.data[5][i][10] === "On hold")
                    {
                        holdList.push(i);
                        holdProject.push(response.data[5][i]);
                    }
                    else
                    {
                        nonArchivedList.push(i);
                        currentProject.push(response.data[5][i]);
                    }
                }
                setProjList(currentProject);
                setFinishedProject(closedProject);
                setOnholdProject(holdProject);
                let arr = {};
                let tabs = [];
                tabs.push({label: "Finished"});
                tabs.push({label: "On-hold"});
                tabs.push({label: "In-progress"});
                for (let j=0; j < nonArchivedList.length; j++)
                {
                    let i = nonArchivedList[j]
                    arr[i] = true;
                    tabs.push({label: "#" + response.data[5][i][3]  + "-" + response.data[5][i][2] + "-" + response.data[5][i][8] })
                }
                setOpen(arr);
                setSubTabs(tabs)
                let allProjectTask = response.data.splice(6);
                let allSubTasks = [];
                let allTaskOrd = [];
                let allTempId = [];
                let allProjProg = [];
                let allChangedTask = [];
                let nonArcprojectDate = [];
                let arcProjectDate = [];
                for (let k=0; k < nonArchivedList.length; k++)
                {
                    let i = nonArchivedList[k];
                    allProjectTask[i].sort((a, b) => (
                        ((a[11] !== "" && b[11] !== "") && a[11] > b[11] ) ? 1 : ((a[11] !== "" && b[11] !== "") && b[11] > a[11]) ? -1 : ((a[11] !== "" && b[11] !== "") && (a[11] === b[11]) && (a[13] !== "") ) ? 1: 0)
                    );
                    for ( let j= 0; j < (allProjectTask[i]).length; j++ )
                    {
                        if (allProjectTask[i][j][10] !== "") {
                            allProjectTask[i][j][10] = JSON.parse(allProjectTask[i][j][10].replace(/'/g, '"'));
                        }
                    }
                    let subtaskDic = {}
                    let task_order = 0
                    let projProg = 0;
                    let projHours = 0;

                    for ( let j= 0; j < allProjectTask[i].length; j++ )
                    {
                        if (allProjectTask[i][j][13] === "")
                        {
                            task_order += 1
                            allProjectTask[i][j][14] = task_order;

                            if(allProjectTask[i][j][6] === "") {
                                allProjectTask[i][j][6] = 0;
                            }
                            if(allProjectTask[i][j][8] === "" || allProjectTask[i][j][8] === "None"){
                                allProjectTask[i][j][8] = 0;
                            }
                            projProg += Number(allProjectTask[i][j][6]) * Number(allProjectTask[i][j][8]);
                            projHours += Number(allProjectTask[i][j][6]);
                        }
                        else
                        {
                            let key = allProjectTask[i][j][13].toString()
                            if (key in subtaskDic){
                                subtaskDic[key].push(allProjectTask[i][j][0])
                            }else{
                                subtaskDic[key] = [allProjectTask[i][j][0]]
                            }
                            let index0 = allProjectTask[i].findIndex(item => item[0].toString() === key)
                            allProjectTask[i][j][14] = (allProjectTask[i][index0][14]).toString() + "." + (subtaskDic[key].length).toString()
                        }
                    }
                    allSubTasks.push(subtaskDic)
                    allTaskOrd.push(task_order)
                    allTempId.push(1000)
                    let progPercent = 0
                    if (projHours > 0){
                        progPercent = Math.round((projProg / projHours) * 100) / 100;
                    }
                    allProjProg.push([projHours, progPercent ]);
                    allChangedTask.push([]);
                    allProjectTask[i].sort((a, b) => (
                        ((a[14] !== "" && b[14] !== "") && a[14] > b[14] ) ? 1 : ((a[14] !== "" && b[14] !== "") && b[14] > a[14]) ? -1 : 0)                    );
                    nonArcprojectDate.push(allProjectTask[i])
                }
                setProjData(nonArcprojectDate);
                setSubTasks(allSubTasks);
                setMaxOrder(allTaskOrd);
                setTempId(allTempId);
                setProjProgress(allProjProg);
                setEditedTasks(allChangedTask);

                allProjProg = [];
                for (let k=0; k < archivedList.length; k++)
                {
                    let i = archivedList[k];
                    allProjectTask[i].sort((a, b) => (
                        ((a[11] !== "" && b[11] !== "") && a[11] > b[11] ) ? 1 : ((a[11] !== "" && b[11] !== "") && b[11] > a[11]) ? -1 : ((a[11] !== "" && b[11] !== "") && (a[11] === b[11]) && (a[13] !== "") ) ? 1: 0)
                    );
                    for ( let j= 0; j < (allProjectTask[i]).length; j++ )
                    {
                        if (allProjectTask[i][j][10] !== "") {
                            allProjectTask[i][j][10] = JSON.parse(allProjectTask[i][j][10].replace(/'/g, '"'));
                        }
                    }
                    /// Determine host tasks and subtasks
                    let subtaskDic = {}
                    let task_order = 0
                    let projProg = 0;
                    let projHours = 0
                    for ( let j= 0; j < allProjectTask[i].length; j++ )
                    {
                        if (allProjectTask[i][j][13] === "")
                        {
                            task_order += 1
                            allProjectTask[i][j][14] = task_order;
                            projProg += Number(allProjectTask[i][j][6]) * Number(allProjectTask[i][j][8]);
                            projHours += Number(allProjectTask[i][j][6]);
                        }
                        else
                        {
                            let key = allProjectTask[i][j][13].toString()
                            if (key in subtaskDic){
                                subtaskDic[key].push(allProjectTask[i][j][0])
                            }else{
                                subtaskDic[key] = [allProjectTask[i][j][0]]
                            }
                            let index0 = allProjectTask[i].findIndex(item => item[0].toString() === key)
                            allProjectTask[i][j][14] = (allProjectTask[i][index0][14]).toString() + "." + (subtaskDic[key].length).toString()
                        }
                    }
                    let progPercent = 0
                    if (projHours > 0){
                        progPercent = Math.round((projProg / projHours) * 100) / 100;
                    }
                    allProjProg.push([projHours, progPercent ]);
                    allProjectTask[i].sort((a, b) => (
                        ((a[14] !== "" && b[14] !== "") && a[14] > b[14] ) ? 1 : ((a[14] !== "" && b[14] !== "") && b[14] > a[14]) ? -1 : 0)
                    );
                    arcProjectDate.push(allProjectTask[i])
                }
                setFinishProjProgress(allProjProg);
                setFinishProjData(arcProjectDate);

                allSubTasks = [];
                allTaskOrd = [];
                allProjProg = [];
                for (let k=0; k < holdList.length; k++)
                {
                    let i = holdList[k];
                    allProjectTask[i].sort((a, b) => (
                        ((a[11] !== "" && b[11] !== "") && a[11] > b[11] ) ? 1 : ((a[11] !== "" && b[11] !== "") && b[11] > a[11]) ? -1 : ((a[11] !== "" && b[11] !== "") && (a[11] === b[11]) && (a[13] !== "") ) ? 1: 0)
                    );
                    for ( let j= 0; j < (allProjectTask[i]).length; j++ )
                    {
                        if (allProjectTask[i][j][10] !== "") {
                            allProjectTask[i][j][10] = JSON.parse(allProjectTask[i][j][10].replace(/'/g, '"'));
                        }
                    }
                    /// Determine host tasks and subtasks
                    let subtaskDic = {}
                    let task_order = 0
                    let projProg = 0;
                    let projHours = 0
                    for ( let j= 0; j < allProjectTask[i].length; j++ )
                    {
                        if (allProjectTask[i][j][13] === "")
                        {
                            task_order += 1
                            allProjectTask[i][j][14] = task_order;
                            projProg += Number(allProjectTask[i][j][6]) * Number(allProjectTask[i][j][8]);
                            projHours += Number(allProjectTask[i][j][6]);
                        }
                        else
                        {
                            let key = allProjectTask[i][j][13].toString()
                            if (key in subtaskDic){
                                subtaskDic[key].push(allProjectTask[i][j][0])
                            }else{
                                subtaskDic[key] = [allProjectTask[i][j][0]]
                            }
                            let index0 = allProjectTask[i].findIndex(item => item[0].toString() === key)
                            allProjectTask[i][j][14] = (allProjectTask[i][index0][14]).toString() + "." + (subtaskDic[key].length).toString()
                        }
                    }
                    allSubTasks.push(subtaskDic);
                    allTaskOrd.push(task_order);

                    let progPercent = 0
                    if (projHours > 0){
                        progPercent = Math.round((projProg / projHours) * 100) / 100;
                    }

                    allProjProg.push([projHours, progPercent ]);
                    allProjectTask[i].sort((a, b) => (
                        ((a[14] !== "" && b[14] !== "") && a[14] > b[14] ) ? 1 : ((a[14] !== "" && b[14] !== "") && b[14] > a[14]) ? -1 : 0)
                    );
                    arcProjectDate.push(allProjectTask[i])
                }
                setOnholdProjSubTasks(allSubTasks);
                setOnholdProjMaxOrder(allTaskOrd);
                setOnholdProjProgress(allProjProg);
                setOnholdProjData(arcProjectDate);
            }
            catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        fetchData();

        setUserfInfo({
            ["name"]: auth().user_record[0],
            ["position"]: auth().user_record[2],
            ["group"]:auth().user_record[1],
            ["manager"]:auth().user_record[3],
            ["mangergroup"]:auth().user_record[4],
            ["admin"]:auth().user_record[5]
        })
    }, []);

    const archiveProject = async (event, projectNumber,index, act) => {
        event.preventDefault()
        setError("");
        setProjectAnchorEl(null);
        let status = event.target.value;
        if (act !== status)
        {
            if (projectNumber !== "")
            {
                try {
                    const response = await axios.post(auth().website + "/ArchiveProject",
                        [projectNumber, status]
                    );
                    if (response.data === "status updated")
                    {
                        let transferedProj = [];
                        let transferedProjData = [];
                        let transferedProjProg = [];
                        let transferedSubTasks = {};
                        let transferedMaxOrder = 0;
                        let data1  = [];
                        if (act === "On hold")
                        {
                            transferedProj = onholdProject[index];
                            transferedProjData = onholdProjData[index]
                            transferedProjProg = onholdProjProgress[index];
                            transferedSubTasks = onholdProjSubTasks[index];
                            transferedMaxOrder = onholdProjMaxOrder[index];
                            data1 = [...onholdProject];
                            data1.splice(index, 1);
                            setOnholdProject(data1);
                            data1 = [...onholdProjData];
                            data1.splice(index, 1);
                            setOnholdProjData(data1);

                            data1 = [...onholdProjProgress];
                            data1.splice(index, 1);
                            setOnholdProjProgress(data1);

                            data1 = [...onholdProjSubTasks];
                            data1.splice(index, 1);
                            setOnholdProjSubTasks(data1);

                            data1 = [...onholdProjMaxOrder];
                            data1.splice(index, 1);
                            setOnholdProjMaxOrder(data1);

                        }else if (act === "In progress")
                        {
                            transferedProj = projList[index];
                            transferedProjData = projData[index];
                            transferedProjProg = projProgress[index];
                            transferedSubTasks = subTasks[index];
                            transferedMaxOrder = maxOrder[index];

                            data1 = [...projList];
                            data1.splice(index, 1);
                            setProjList(data1);

                            if (type === "In-progress")
                            {
                                let projIndexes = Array(data1.length).fill(0).map((_, index)=> index);
                                setProjectIndexes(projIndexes)
                                setTypeProject(data1)
                            }

                            data1 = [...projData];
                            data1.splice(index, 1);
                            setProjData(data1);

                            data1 = [...projProgress];
                            data1.splice(index, 1);
                            setProjProgress(data1);

                            data1 = [...subTasks];
                            data1.splice(index, 1);
                            setSubTasks(data1);

                            data1 = [...maxOrder];
                            data1.splice(index, 1);
                            setMaxOrder(data1);

                            data1 = [...tempId];
                            data1.splice(index, 1);
                            setTempId(data1);

                            data1 = [...editedTasks];
                            data1.splice(index, 1);
                            setEditedTasks(data1);

                            data1 = [...subTabs];
                            data1.splice(index + 3, 1);
                            setSubTabs(data1);
                        }

                        if (status === "On hold"){
                            transferedProj[10] = "On hold";
                            data1 = [...onholdProject];
                            data1.push(transferedProj);
                            setOnholdProject(data1);
                            setOnholdProjData([...onholdProjData, transferedProjData]);
                            setOnholdProjProgress( [...onholdProjProgress, transferedProjProg]);
                            setOnholdProjSubTasks([...onholdProjSubTasks, transferedSubTasks ]);
                            setOnholdProjMaxOrder([...onholdProjMaxOrder, transferedMaxOrder]);

                        }else if(status === "In progress"){
                            transferedProj[10] = "In progress";
                            data1 = [...projList];
                            data1.push(transferedProj);
                            setProjList(data1);

                            setProjData( [...projData,transferedProjData]);
                            setProjProgress([...projProgress, transferedProjProg]);
                            setTempId([...tempId, 1000]);
                            setEditedTasks([...editedTasks, []]);
                            setSubTasks([...subTasks, transferedSubTasks ]);
                            setMaxOrder([...maxOrder, transferedMaxOrder]);

                            let key = {label: "#" + transferedProj[3]  + "-" + transferedProj[2] + "-" + transferedProj[8] }
                            setSubTabs([...subTabs, key ])

                        }else if(status === "Finished"){

                            transferedProj[10] = "Finished";
                            data1 = [...finishedProject];
                            data1.push(transferedProj);
                            setFinishedProject(data1);

                            setFinishProjData([...finishProjData, transferedProjData]);
                            setFinishProjProgress([...finishProjProgress, transferedProjProg]);
                        }
                        setSnackContent("Confirmation: Project status was updated successfully!")
                        setSnackOpen(true);
                    }
                }catch (err) {
                    if (err && err instanceof AxiosError)
                        setSnackContent(err.response?.data.message);
                    else if (err && err instanceof Error) setSnackContent(err.message);
                    setSnackOpen(true);
                }
            }
        }
        event.target.value = "Select status"

        setOpenModal(false);
    }

    const [projName, setProjName] = useState();
    const [error, setError] = useState("");

    const [temp, setTemp] = useState({
        index:"",
        number:"",
        title: "",
        customer: "",
        manager: [],
        code: "",
        deadline: "",
    });

    const setProjectData = (event, name) => {
        if(name === "manager")
        {
            setTemp({...temp,[name]: [...event.filter((option) => option),] });
        }else if (name !== "deadline"){
            setTemp({...temp,[name]: event.target.value, });
        }else{
            setTemp({...temp,[name]: event });
        }
    }


    const projectInfoUpdate = async (e) => {
        e.preventDefault()
        setError("");
        setProjectAnchorEl(null);
        let Tvalue = ["",temp.number, temp.title,temp.customer, temp.manager, format(temp.deadline, 'yyyy-MM-dd'), "", "",temp.code, 0]
        try {
            const response = await axios.post(auth().website + "/UpdateProjectInfo",Tvalue );
            if (response.data !== "Project updated")
            {
                Tvalue[1] = response.data;
                let data3 = [...projList, Tvalue];
                setProjList(data3);

                if (type === "In-progress"){
                    let projIndexes = Array(data3.length).fill(0).map((_, index)=> index);
                    setProjectIndexes(projIndexes)
                    setTypeProject(data3)
                }

                data3 = [...tempId]
                data3.push(1001)
                let data4 = [...maxOrder]
                data4.push(1)
                const newdata = [[1001, "", "", "", "", "",0, 0, 0, "", "" ,"",0, "", (1).toString()]]
                setProjData([...projData, newdata]);
                setTempId(data3);
                setMaxOrder(data4);

                let key = {label: "#" + Tvalue[3]  + "-" + Tvalue[2] + "-" + Tvalue[8] }
                setSubTabs([...subTabs, key ])

                setEditedTasks([...editedTasks, []])
                setProjProgress([...projProgress, [0,0]])
                setSubTasks([...subTasks, {}])

            }else{
                const data = [...projList]
                data[temp.index][2] = Tvalue[2]
                data[temp.index][3] = Tvalue[3]
                data[temp.index][4] = Tvalue[4]
                data[temp.index][5] = Tvalue[5]
                data[temp.index][8] = Tvalue[8]
                setProjList(data)
            }
            setSnackContent("Confirmation: Project information was saved successfully!")
            setSnackOpen(true);
        } catch (err) {

            if (err && err instanceof AxiosError)
                setSnackContent(err.response?.data.message);
            else if (err && err instanceof Error) setSnackContent(err.message);
            setSnackOpen(true);
        }
        setOpenModal(false);
    }

    const projectInfoUpdate2 = async () => {
        //e.preventDefault()
        setError("");
        let tablekey = deleteInfo.projectId;
        setProjectAnchorEl(null);
        try {
            const response = await axios.post(auth().website + "/UpdateProjectInfo",
                projList[tablekey]
            );
            if (response.data !== "Project updated")
            {
                let data3 = [...projList];
                data3[tablekey][1] =  (response.data).toString();
                setProjList(data3);
            }
            setSnackContent("Confirmation: The project has been successfully saved!")
            setSnackOpen(true);
        } catch (err) {
            setSnackContent("Warning: The saving of the project failed!")
            setSnackOpen(true);
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);
        }

    }

    const StaffHours = (staff, startDate, endDate) => {
        //const {projData, projList,} = useContext(MainContext);
        //let projectIndexes = []
        let totalHours = 0
        let intervalhours = 0
        const currentDate = new Date();
        let currentMonth = currentDate.getMonth();
        let start0 = "";
        let end0 = "";
        let limitedHours = "";
        if (startDate !== "" && endDate !== ""){
            start0 = new Date(startDate).getTime();
            end0 = new Date(endDate).getTime();
            limitedHours = (Math.ceil((Math.abs(start0- end0) + 1) / (1000 * 3600 * 24))) * 8.5;
        }

        for (let j=0; j < projList.length; j++ )
        {
            let table = []
            table = projData[j].filter(row => row[2] === staff)
            if (table.length > 0)
            {
                if ((table.length ===1 && table[0][11] === "") || (table.length ===1 && table[0][4] === "")){
                    totalHours = 0
                }else{
                    for (let k=0; k < table.length; k++ )
                    {
                        let date0 = new Date(table[k][11]);
                        let date1 = new Date(table[k][4]);
                        let month0 = date0.getMonth();
                        let day0 = date0.getDate();
                        let month1 = date1.getMonth();
                        let day1 = date1.getDate();
                        if (month0 !== month1){
                            if (currentMonth === month0)
                            {
                                totalHours += ((30 - day0 + 1) * parseInt(table[k][6])) / (day1 + (30 - day0) + 1)
                            } else if (currentMonth === month1)
                            {
                                totalHours += ((day1) * parseInt(table[k][6])) / (day1 + (30 - day0) + 1)
                            }
                        } else {
                            totalHours += parseInt(table[k][6]);
                        }
                        if (startDate !== "" && endDate !== ""){
                            let start = date0.getTime();
                            let end = date1.getTime();
                            let timeDiff = Math.abs(start- end) + 1;
                            let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                            let hoursPerDay = parseInt(table[k][6]) / diffDays ;
                            let timeDiff0 = 0
                            let diffDays0 = 0
                            if (start <= start0 && end0 <= end){
                                timeDiff0 = Math.abs(end0- start0) + 1;
                                diffDays0 = Math.ceil(timeDiff0 / (1000 * 3600 * 24));
                                intervalhours += hoursPerDay * diffDays0;
                            }else if (start <= start0 && start0 <= end  && end <= end0){
                                timeDiff0 = Math.abs(end- start0) + 1;
                                diffDays0 = Math.ceil(timeDiff0 / (1000 * 3600 * 24));
                                intervalhours += hoursPerDay * diffDays0;
                            }else if (start0 <= start && start <= end0 && end0 <= end){
                                timeDiff0 = Math.abs(end0- start) + 1;
                                diffDays0 = Math.ceil(timeDiff0 / (1000 * 3600 * 24));
                                intervalhours += hoursPerDay * diffDays0;
                            }else if (start0 <= start && end <= end0){
                                timeDiff0 = Math.abs(end- start) + 1;
                                diffDays0 = Math.ceil(timeDiff0 / (1000 * 3600 * 24));
                                intervalhours += hoursPerDay * diffDays0;
                            }
                        }
                    }
                }
            }
        }
        return [totalHours, intervalhours, limitedHours]
    }

    const [changeID, setChangeID] = useState([])

    function findId(tableNum, id) {
        let index0 = projData[tableNum].findIndex(item => item[0].toString() === id.toString())
        return index0;
    }

    function findAId(tableNum, id) {
        let index0 = projData[tableNum].findIndex(item => item[14].toString() === id.toString())
        return index0;
    }

    function calculateProgress(projNum, hostId, currentId, currentSubProg, act)
    {
        let  hostIndex = findId(projNum,hostId);
        let prog = 0;
        let totalH = 0
        if (act === "main")
        {
            if (currentSubProg !== "")
            {
                totalH = currentSubProg;
            }
        }else
        {
            if (projData[projNum][hostIndex][6] !== "")
            {
                totalH = Number(projData[projNum][hostIndex][6])
            }
        }
        if (totalH !== 0)
        {
            let totalProgress = 0
            if (hostId in subTasks[projNum])
            {
                if (act === "insert")
                {
                    totalProgress = currentSubProg
                }
                let record = subTasks[projNum][hostId]
                for (let j = 0; j < record.length; j++) {
                    let subtaskId = record[j]
                    if (subtaskId !== currentId) {
                        let subtaskIndex = findId(projNum, subtaskId)
                        if (projData[projNum][subtaskIndex][6] !== "" && projData[projNum][subtaskIndex][8] !== "") {
                            totalProgress += Number(projData[projNum][subtaskIndex][6]) * Number(projData[projNum][subtaskIndex][8])
                        }
                    }
                }
            }
            prog = (totalProgress / totalH);
        }

        let data3 = [...projProgress];
        let diff = 0;
        let diffHour = 0;
        if (act === "main")
        {
            if (projData[projNum][hostIndex][6] === ""){
                diffHour = totalH
            }else{
                diffHour = totalH - Number(projData[projNum][hostIndex][6]);
            }

            let currentProg = 0;
            if (!(projData[projNum][hostIndex][6] === "" || projData[projNum][hostIndex][8] === "")){
                currentProg = Number(projData[projNum][hostIndex][6])  * Number(projData[projNum][hostIndex][8]);
            }

            diff = (  ((totalH * prog) / (data3[projNum][0] + diffHour)) - ( currentProg / data3[projNum][0])  );
            console.log("diff")
            console.log(diff)
            console.log(prog)
            console.log(currentProg)
        }else
        {
            diff = (Number(projData[projNum][hostIndex][6]) * (prog - Number(projData[projNum][hostIndex][8])) / data3[projNum][0]);
        }


        data3[projNum][1] += diff
        data3[projNum][0] += diffHour
        if (data3[projNum][1] < 0){
            data3[projNum][1] = 0
        }

        setProjProgress(data3)

        return [hostIndex, prog];
    }

    function checkMainHour(projNum, hostId, mainHour){
        let output = true;
        if (hostId in subTasks[projNum])
        {
            console.log("im here in")
            let subHours = 0
            let record = subTasks[projNum][hostId]
            console.log(record)
            for (let j = 0; j < record.length; j++)
            {
                let subtaskId = record[j]
                let subtaskIndex = findId(projNum, subtaskId)
                if (projData[projNum][subtaskIndex][6] !== "")
                {
                    subHours += Number(projData[projNum][subtaskIndex][6]);
                }
            }
            console.log(subHours)
            if (mainHour === ""){
                mainHour =0;
            }
            if (mainHour < subHours ){
                output = false;
            }
        }
        return output
    }

    function calculateETime(projNum, hostId, currentId, allocatedTime)
    {
        let hosttaskID = findId(projNum,hostId);
        let hostETime = Number(projData[projNum][hosttaskID][6]);
        let result = true
        if( hostId.toString() in subTasks[projNum])
        {
           for(let j=0; j < (subTasks[projNum][hostId.toString()]).length; j++)
            {
                let subID = subTasks[projNum][hostId.toString()][j]
                if(subID !== currentId)
                {
                    let subtaskRow = findId(projNum,subID);
                    allocatedTime += Number(projData[projNum][subtaskRow][6]);
                }
            }
            if (hostETime < allocatedTime )
            {
                setSnackContent("Warning: The total estimated hours for subtask of task " +  projData[projNum][hosttaskID][14] + " has exceded from " +  hostETime  +" hours")
                setSnackOpen(true);
                result = false
            }
        }
        return [result, hosttaskID];
    }

    const onValUpdate = (projNum, tableRow, rowColumn, event) =>
    {
        const data = [...projData];
        const dataChanged = [...editedTasks];
        let changedId = dataChanged[projNum];
        if (!(changedId.includes(tableRow)))
        {
            changedId.push(tableRow)
        }
        switch (event.target.name)
        {
            case "progressbar":
                data[projNum][tableRow][rowColumn] = event.target.value;
                if (parseInt(event.target.value)  === 100){
                    data[projNum][tableRow][3] = "Done";
                }
                if (data[projNum][tableRow][13] !== "" && data[projNum][tableRow][6] !== 0)
                {
                    let currentSubProg = data[projNum][tableRow][6] * event.target.value
                    let progResult = calculateProgress(projNum, data[projNum][tableRow][13], data[projNum][tableRow][0], currentSubProg, "insert");

                    if (95.5 < progResult[1]  && progResult[1] < 100){
                        progResult[1] = 100
                    }else{
                        progResult[1] = Math.floor(progResult[1]);
                    }
                    data[projNum][progResult[0]][8] = progResult[1];

                    if (parseInt(data[projNum][progResult[0]][8])  === 100)
                    {
                        data[projNum][progResult[0]][3] = "Done";
                    }
                    if (!(changedId.includes(progResult[0])))
                    {
                        changedId.push(progResult[0])
                    }
                }
                break;
            case "status":
                if (event.target.value === "Done")
                {
                    data[projNum][tableRow][8] = 100;
                    if (data[projNum][tableRow][13] !== "" && data[projNum][tableRow][6] !== 0)
                    {
                        let currentSubProg = data[projNum][tableRow][6] * data[projNum][tableRow][8];
                        let progResult = calculateProgress(projNum, data[projNum][tableRow][13], data[projNum][tableRow][0], currentSubProg, "insert", "Id");

                        if (95.5 < progResult[1] && progResult[1] < 100){
                            progResult[1] = 100
                        }else{
                            progResult[1] = Math.floor(progResult[1]);
                        }
                        data[projNum][progResult[0]][8] = progResult[1];



                        if (parseInt(data[projNum][progResult[0]][8])  === 100)
                        {
                            data[projNum][progResult[0]][3] = "Done";
                        }
                        if (!(changedId.includes(progResult[0])))
                        {
                            changedId.push(progResult[0])
                        }
                    }
                }
                data[projNum][tableRow][rowColumn] = event.target.value;
                break;
            case "estimatedHours":
                let out = checkMainHour(projNum, data[projNum][tableRow][0] , event.target.value)
                console.log("out")
                console.log(out)
                if (out){
                    if(data[projNum][tableRow][13] !== "")
                    {
                        let hostTask = data[projNum][tableRow][13];
                        let allocatedTime = Number(event.target.value);

                        let progResult = ["",""];
                        let dateResult = "";

                        let result = calculateETime(projNum, hostTask, data[projNum][tableRow][0], allocatedTime)
                        if (result[0])
                        {
                            let currentSubProg = 0
                            if (event.target.value !== 0) {
                                currentSubProg = event.target.value * data[projNum][tableRow][8];
                            }
                            progResult = calculateProgress(projNum, data[projNum][tableRow][13], data[projNum][tableRow][0], currentSubProg, "insert");

                            if (progResult[0] !== "")
                            {
                                if (95.5 < progResult[1] && progResult[1] < 100){
                                    progResult[1] = 100
                                }else{
                                    progResult[1] = Math.floor(progResult[1]);
                                }

                                data[projNum][progResult[0]][8] = progResult[1];
                                if (parseInt(data[projNum][progResult[0]][8])  === 100)
                                {
                                    data[projNum][progResult[0]][3] = "Done";
                                }

                                dateResult =  hostIdDate(projNum, data[projNum][tableRow][13], data[projNum][tableRow][0], "", "", "insert")
                                if (dateResult[0][1])
                                {
                                    data[projNum][progResult[0]][11] = dateResult[0][0];
                                    data[projNum][progResult[0]][4] = dateResult[0][1];
                                }else
                                {
                                    data[projNum][progResult[0]][11] = "";
                                    data[projNum][progResult[0]][4] = "";
                                }

                                if(!(changedId.includes(progResult[0])))
                                {
                                    changedId.push(progResult[0])
                                }
                            }
                            data[projNum][tableRow][11] = "";
                            data[projNum][tableRow][4] = "";
                            data[projNum][tableRow][rowColumn] = event.target.value;
                        }
                    }
                    else
                    {
                        if (!(data[projNum][tableRow][0].toString() in subTasks[projNum]))
                        {
                            data[projNum][tableRow][11] = "";
                            data[projNum][tableRow][4] = "";
                        }else
                        {
                            let progResult = calculateProgress(projNum, data[projNum][tableRow][0], data[projNum][tableRow][0], event.target.value, "main");
                            if (95.5 < progResult[1] && progResult[1] < 100){
                                progResult[1] = 100
                            }else{
                                progResult[1] = Math.floor(progResult[1]);
                            }
                            data[projNum][progResult[0]][8] = progResult[1];
                            if (parseInt(data[projNum][progResult[0]][8])  === 100)
                            {
                                data[projNum][progResult[0]][3] = "Done";
                            }
                        }
                        data[projNum][tableRow][rowColumn] = event.target.value;
                    }
                }else{
                    setSnackContent("Warning: The estimated hours for task " +  projData[projNum][tableRow][14] + " is less than allocated subtasks' hours")
                    setSnackOpen(true);
                }
                break;
            case "hostTask":
                let progResult = ["",""];
                let dateResult = "";
                if (event.target.value !== "")
                {
                    let hostId =  data[projNum][findAId(projNum,event.target.value)][0]
                    let allocatedTime = Number(data[projNum][tableRow][6]);
                    let result = calculateETime(projNum, hostId, data[projNum][tableRow][0], allocatedTime)
                    if (result[0]) {
                        let data2 = [...subTasks];
                        if (hostId.toString() in subTasks[projNum]) {
                            data2[projNum][hostId.toString()].push(data[projNum][tableRow][0]);
                        } else {
                            data2[projNum][hostId.toString()] = [data[projNum][tableRow][0]];
                        }
                        data[projNum][tableRow][14] = data[projNum][result[1]][14] + "." + (data2[projNum][hostId.toString()].length).toString()
                        setSubTasks(data2);
                        let currentSubProg = 0
                        if (data[projNum][tableRow][6] !== 0) {
                            currentSubProg = data[projNum][tableRow][6] * data[projNum][tableRow][8];
                        }
                        progResult = calculateProgress(projNum, hostId, data[projNum][tableRow][0], currentSubProg, "insert");
                        data[projNum][tableRow][rowColumn] = hostId;

                        dateResult = hostIdDate(projNum, hostId, data[projNum][tableRow][0], data[projNum][tableRow][11], data[projNum][tableRow][4], "insert")
                        if (progResult[0] !== "")
                        {
                            data[projNum][progResult[0]][2] = "";
                        }
                    }
                }else
                {
                    if(data[projNum][tableRow][rowColumn] !== "")
                    {
                        let key = (data[projNum][tableRow][rowColumn]).toString();
                        let data2 = [...subTasks];
                        if (key in data2[projNum])
                        {
                            let index0 = data2[projNum][key].findIndex(item => item === data[projNum][tableRow][0]);
                            if (index0 !== -1)
                            {
                                data2[projNum][key].splice(index0, 1);
                                setSubTasks(data2)
                                let data3 = [...maxOrder]
                                data3[projNum] += 1
                                data[projNum][tableRow][14] = (data3[projNum]).toString()
                                setMaxOrder(data3)
                            }
                        }
                        progResult = calculateProgress(projNum, data[projNum][tableRow][13], data[projNum][tableRow][0], 0, "omit");
                        dateResult =  hostIdDate(projNum, data[projNum][tableRow][13], data[projNum][tableRow][0], 0, 0, "omit")
                    }
                    data[projNum][tableRow][rowColumn] = "";
                }
                if (progResult[0] !== "")
                {
                    if (95.5 < progResult[1] && progResult[1] < 100){
                        progResult[1] = 100
                    }else{
                        progResult[1] = Math.floor(progResult[1]);
                    }
                    data[projNum][progResult[0]][8] = progResult[1];
                    if (parseInt(data[projNum][progResult[0]][8])  === 100)
                    {
                        data[projNum][progResult[0]][3] = "Done";
                    }

                    if (dateResult[0][1])
                    {
                        data[projNum][progResult[0]][11] = dateResult[0][0];
                        data[projNum][progResult[0]][4] = dateResult[0][1];
                    }else
                    {
                        data[projNum][progResult[0]][11] = "";
                        data[projNum][progResult[0]][4] = "";
                    }
                    if (!(changedId.includes(progResult[0])))
                    {
                        changedId.push(progResult[0])
                    }
                }
               break;
            default:
                data[projNum][tableRow][rowColumn] = event.target.value;
                break;
        };
        setProjData(data);
        dataChanged[projNum] = changedId;
        setEditedTasks(dataChanged);
    }

    const saveProject= async(e, projectNumber) =>
    {
        e.preventDefault()
        setError("");
        setOpenElem(null);
        setOpenElemRow(null)
        setAnchorEl(null);
        let tasksArray = []

        for (let j=0; j < editedTasks[projectNumber].length; j++ )
        {
            let tablerow = editedTasks[projectNumber][j]
            tasksArray.push(projData[projectNumber][tablerow])
        }

        if (tasksArray.length > 0)
        {
            let updatedData = [ projList[projectNumber][1] , tasksArray]
            try
            {
                const response = await axios.post(auth().website + "/UpdateChangedTasks",
                    updatedData
                );
                let dictionary = response.data;
                let data = editedTasks;
                let dataProj = [...projData]
                let rowId = -1;
                for (let i=0; i < data[projectNumber].length; i++)
                {
                    rowId = data[projectNumber][i];
                    let updatedId = -1
                    if (dataProj[projectNumber][rowId][10] !== "")
                    {
                        let depItems = dataProj[projectNumber][rowId][10]["item"];
                        for (let j=0; j < depItems.length; j++)
                        {
                            if (depItems[j] > 1000)
                            {
                                updatedId = dictionary[depItems[j].toString()];
                                dataProj[projectNumber][rowId][10]["item"][j] = updatedId;
                            }
                        }
                    }
                    if (dataProj[projectNumber][rowId][0] > 1000)
                    {
                        updatedId = dictionary[(dataProj[projectNumber][rowId][0]).toString()];
                        dataProj[projectNumber][rowId][0] = updatedId;
                    }

                    if (dataProj[projectNumber][rowId][14] > 1000)
                    {
                        updatedId = dictionary[(dataProj[projectNumber][rowId][14]).toString()];
                        dataProj[projectNumber][rowId][14] = updatedId;
                    }

                    if (dataProj[projectNumber][rowId][13] > 1000)
                    {
                        updatedId = dictionary[(dataProj[projectNumber][rowId][13]).toString()];
                        dataProj[projectNumber][rowId][13] = updatedId;
                    }
                }
                setProjData(dataProj)

                data[projectNumber] = [];
                setEditedTasks(data);

                let data3 = [...projList]
                data3[projectNumber][6] = dictionary["oldest"];
                data3[projectNumber][7] = dictionary["closest"];
                setProjList(data3);

                setSnackContent("Confirmation: The changed tasks for project [# " +   projList[projectNumber][3].toString() + "-" +  projList[projectNumber][2].toString() + "-" + projList[projectNumber][8].toString() +  "] has been successfully saved!!")
                setSnackOpen(true);
            }
            catch (err)
            {
                setSnackContent("Warning: The saving of the project [# " +   projList[projectNumber][3].toString() + "-" +  projList[projectNumber][2].toString() + "-" + projList[projectNumber][8].toString() +  "] failed!")
                setSnackOpen(true);
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setSnackContent("Warning: No task has changed to save!")
            setSnackOpen(true);
        }

    }

    const editTask2 = async() =>
    {
        setError("");
        setOpenElem(null);
        setOpenElemRow(null)
        setAnchorEl(null);
        let tablekey = deleteInfo.projectId;
        let tablerow = deleteInfo.taskId;

        if (changedTaskId.length > 1)
        {
            let projectArr = []
            let taskArr = []
            for (let j=0; j < changedTaskId.length; j++ )
            {
                let tablekey0 = changedTaskId[j][0]
                let tablerow0 = changedTaskId[j][1]
                projectArr.push(projList[tablekey0][1])
                taskArr.push(projData[tablekey0][tablerow0])
            }
            let updatedData = [projectArr, taskArr, clickedRow]
            try {
                const response = await axios.post(auth().website + "/UpdateAllProject",
                    updatedData
                );
                if (response.data !== "updated")
                {
                    dataTemp = [...projData]
                    //dataTemp[tablekey][tablerow][0] = response.data;
                    if (clickedRow !== ""){
                        dataTemp[tablekey][clickedRow][12] = response.data;
                    }
                    setProjData(dataTemp);
                }

                dataTemp = [...[]];
                changesId = [...[]];
                setDedicatedRow(["",""])
                setChangedTaskId([]);

                setSnackContent("Confirmation: The all project's tasks has been successfully saved!!")
                setSnackOpen(true);

            } catch (err) {
                setSnackContent("Warning: The saving of the project task failed!")
                setSnackOpen(true);
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        else
        {
            let updatedData = [projList[tablekey][1], projData[tablekey][tablerow], currentDate.toString(), auth().user, clickedRow]
            try {
                const response = await axios.post(auth().website + "/UpdateProject",
                    updatedData
                );

                if ((response.data)[0] !== "Task Updated")
                {
                    dataTemp = [...projData]
                    //dataTemp[tablekey][tablerow][0] = (response.data)[0];

                    if (clickedRow !== ""){
                        dataTemp[tablekey][clickedRow][12] = (response.data)[0];
                    }
                    setProjData(dataTemp);
                }
                dataTemp = [...[]];
                changesId = [...[]];

                let data3 = [...projList]
                data3[tablekey][6] = (response.data)[1];
                data3[tablekey][7] = (response.data)[2];
                setProjList(data3);

                setSnackContent("Confirmation: The project task has been successfully saved!!")
                setSnackOpen(true);
            } catch (err) {
                setSnackContent("Warning: The saving of the project task failed!")
                setSnackOpen(true);
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
    }


    const [mentionOption, setMentionOption] = useState({
        table:"",
        row: "",
    });
    const [alertOpen, setAlertOpen] = useState(false);
    const [mentionOpen, setMentionOpen] = useState(false)
    const makeMention = () => {
        let tablekey = deleteInfo.projectId;
        let tablerow = deleteInfo.taskId;
        setMentionOption({...mentionOption,["table"]: tablekey,["row"]: tablerow,});
        setMentionOpen(true)
        setAnchorEl(null);

    }
    const [selected, setSelected] = useState([]);
    const [postContent, setPostContent] = useState("");
    //  const [searchPage, setSearchPage] = useState(false);

    const sendMention = async(e) => {
        e.preventDefault()
        setError("");
        let postAccouts = []
        let tablekey = deleteInfo.projectId;
        let tablerow = deleteInfo.taskId;

        let taskName = projData[tablekey][tablerow][1];
        let projectName = "#" +  projList[tablekey][3] + "-" +  projList[tablekey][2] + "-" + projList[tablekey][8] + "'"
        let postSubject = "Task '" + taskName.toString() + "' of project '" + projectName;

        for (let i=0; i < selected.length; i++ ){
            postAccouts.push(selected[i].value)
        }
        if (selected && postContent ){
            try {
                const response = await axios.post(auth().website + "/sendPost",
                    [postAccouts, postContent, postSubject]
                );
                if (response.data === "postContent was sent"){
                    setSnackContent("Confirmation: The notification has been sent successfully!")
                    setSnackOpen(true);
                }
                else{
                    setSnackContent("Warning: The notification failed to send!")
                    setSnackOpen(true);
                    setError("Warning: The notification failed to send!")
                }
            } catch (err) {
                setSnackContent("Warning: The notification failed to send!")
                setSnackOpen(true);
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setError("Warning: No account has been selected or there is no notification content available for sending.")
        }
        setMentionOpen(false)
    }

    const statusOptions = [
        { label: "Done", value: 1 },
        { label: "Working on it", value: 2 },
        { label: "Stuck", value: 3 },
        { label: "Not started", value: 4 }
    ];

    const [pageNumber, setPageNumber] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [type, setType] = useState("1")
    const handlePageNumber = (event, newPage) => {
        setPageNumber(newPage);
    };

    const setProjectInfo = (keys, index, event) =>{
        let data3 = [...projList];
        data3[keys][index] =  event.target.value;
        setProjList(data3);
    }

    const tabClick = (index, projType) =>
    {
        // if (index < subTabs.length + 1 && 0 < index)
        // {
        //     let items = projList.filter(row => row[5] === projType)
        //     console.log(items)
        //     let arrLength = items.length;
        //
        //     setElRefs((elRefs) =>
        //         Array(arrLength)
        //             .fill()
        //             .map((_, i) => elRefs[i] || createRef()),
        //     );
        // }

        if (projType === "Finished"){
            let arr = new Array(finishedProject.length).fill(false);
            setOpenF(arr);
        }else if(projType === "Finished"){
            let arr = new Array(onholdProject.length).fill(false)
            setOpenH(arr);
        }else{
            let arr = new Array(projList.length).fill(false)
            if (projType === "In-progress"){
                setOpen(arr);
            }else{
                if (3 < index && index < projList.length + 4){
                    arr[index - 4] = true
                    setOpen(arr);
                }
            }
        }
        setDrawerOpen(false);
        setPageNumber(index);
        setType(projType);
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [projectAnchorEl, setProjectAnchorEl] = useState(null);
    const [deleteInfo, setDeleteInfo] = useState({
        projectId: "",
        taskId: "",
        staffId: "",
    });
    const [snackContent, setSnackContent] = useState("")
    const [snackOpen, setSnackOpen] = useState(false);
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const TemporaryProject = () =>{
        setOpenModal(true);
    }

    const [confirmedOffDate, setConfirmedOffDate] = useState([]);
    const [declinedOffDate, setDeclinedOffDate] = useState([]);
    const [waitingOffDate, setWaitingOffDate] = useState([]);
    const [offSiteDates, setOffSiteDates] = useState([]);
    const [timeSheet, setTimeSheet] = useState([])
    const [totalHours, setTotalHour] = useState(0)
    const [overTime, setOvertime] = useState(0)
    const [checkinBtn, setCheckinBtn] = useState(false)
    const [checkoutBtn, setCheckoutBtn] = useState(false)
    const [checksInput, setChecksInput] = useState([])
    const [checkinDate, setCheckinDate ] = useState(new Date())

    function totalMinutes(time) {
        let index = (time).indexOf(":");
        let hours = parseInt((time).substring(0, index));
        let minutes = parseInt( (time).substring(index + 1, (time).length));
        let totalMinutes = hours * 60 + minutes;
        return totalMinutes;
    }

    function setTimeData(index0, time1, time2, act){
        let differenceTime = 0
        if (act === 0){
            let str = checksInput[index0].holidayName
            let index1 = (str).indexOf("-");
            let startDate = (str).substring(0, index1 -1);
            let endDate = (str).substring(index1 + 2, (str).length);
            differenceTime = totalMinutes(endDate) - totalMinutes(startDate)
        }

        let totalMonthTime = totalMinutes(time2) -  totalMinutes(time1) + (totalMinutes(totalHours) - differenceTime );

        let minutes = (totalMonthTime) % 60;
        let hours = Math.floor((totalMonthTime ) / 60);

        setTotalHour(hours.toString() + ":" +  minutes.toString());

        let temp0 = totalMonthTime - 160 * 60
        let minutes0 = Math.abs(temp0) % 60;
        let hours0 = Math.floor((temp0 ) / 60);
        setOvertime(hours0.toString() + ":" +  minutes0.toString())


    }

    const checkInOutTable = (Date, time, temp) =>
    {
        let data = [...checksInput]
        if (temp === "in")
        {
            let index0 = data.findIndex(item => item.date === Date)
            if (0 <= index0)
            {
                let index1 = (data[index0].holidayName).indexOf("-");
                if ( 0 <= index1){
                    let str = data[index0].holidayName
                    let enddate = (str).substring(index1 + 2, (str).length);
                    setTimeData(index0, time, enddate, 0)
                    data[index0].holidayName = time.toString() + " - " + enddate
                }else{
                    data[index0].holidayName = time.toString()
                }
            }else{
                data.push({
                    date: Date,
                    holidayName: time.toString()
                })
            }
            setChecksInput(data)
        }
        else if (temp === "out")
        {
            let index0 = data.findIndex(item => item.date === Date)
            if (0 <= index0)
            {
                let str = data[index0].holidayName;
                let index1 = (str).indexOf("-");
                let startDate = ""
                if ( 0 <= index1)
                {
                    startDate = (str).substring(0, index1 -1);
                    setTimeData(index0, startDate, time, 0 )
                }else
                {
                    startDate = str;
                    setTimeData(index0, startDate, time, 1)
                }
                data[index0].holidayName = startDate + " - " + time.toString()
            }
            setChecksInput(data)
        }
    }

    const [clickedRow, setClickedRow ] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [criticalItem, setCriticalItem] = useState(false);
    const [highItem, setHighItem] = useState(false);
    const [lowItem, setLowItem] = useState(false);
    const [dialogOpenTime, setDialogOpenTime] = useState(false);
    const [ dedicatedRow, setDedicatedRow ] = useState(["",""]);
    const [calenderOpen, setCalenderOpen] = useState(false);




    let excludeDate = [];
    let startDates = [];
    let endDates = [];
    let nonFullDate = [];
    let nonFullList0 = []
    let nonFullList = []
    let projtaskID = [];
    let excludeDate0 = [];
    let nonFullDate0 = [];
    let startDates0 = [];
    let endDates0 = [];
    let changesId = [];
    let dataTemp = [];
    let newchangedArr = [];
    let projectDates = [];
    let projectDates0 = [];
    let taskHighlight = [];
    const [excludeIntervals, setExcludeIntervals] = useState([]);
    const [excludeCritical, setExcludeCritical] = useState([]);
    const [partialIntervals, setPartialIntervals] = useState([]);
    const [partialCritical, setPartialCritical] = useState([]);
    const [changedTaskId, setChangedTaskId] = useState([])
    const [partialIntervalsList, setPartialIntervalsList] = useState([]);
    const [partialCriticalList, setPartialCriticalList] = useState([]);

    function assisstThree(startDate,endDate, totalMinuteEnd, totalMinuteStart, state)
    {
        let temp = ""
        let key = {}
        let totalMinute = 0
        let minutesTemp = ""
        let hoursTemp = ""
        let newEndDate = ""
        let shalloNonFullDate = []
        let shalloNonFullList = []
        let shalloExclude = []
        let index0 = ""
        if (state === "Critical")
        {
            shalloNonFullDate = nonFullDate0
            shalloNonFullList = nonFullList0
            shalloExclude = excludeDate0
        }else
        {
            shalloNonFullDate = nonFullDate
            shalloNonFullList = nonFullList
            shalloExclude = excludeDate
        }


        if ((WEH - totalMinuteEnd) < 30)
        {
            index0 = shalloNonFullDate.findIndex(item => format(item.date, 'yyyy-MM-dd')  === format(endDate, 'yyyy-MM-dd'));
            if (index0 !== -1){
                shalloNonFullDate.splice(index0, 1);
                shalloNonFullList.splice(index0, 1);
            }
            shalloExclude.push({start: subDays(startDate , 1), end: endDate})
        }
        else
        {
            let existDate = shalloNonFullDate.filter((t) => (format(startDate, 'yyyy-MM-dd') === format(t.date, 'yyyy-MM-dd')));
            if (existDate.length > 0)
            {
                let indexNonFull = shalloNonFullDate.findIndex(item => format(item.date, 'yyyy-MM-dd') === format(startDate, 'yyyy-MM-dd'));
                temp = {"start": startDate, "end": endDate}
                let index0 = (shalloNonFullList[indexNonFull].lists).findIndex(item => item.start <= temp.start && temp.end <= item.end);
                if (index0 === -1)
                {
                    let index1 = (shalloNonFullList[indexNonFull].lists).findIndex(item => item.end <= temp.start || temp.end <= item.start);
                    let remaintime = 0
                    if (index1 !== -1){
                        remaintime =  shalloNonFullList[indexNonFull].remainMinute - (totalMinuteEnd - totalMinuteStart)
                    }else {
                        let NonFullListItem = (shalloNonFullList[indexNonFull].lists).filter(item => temp.start <= item.start && item.end <= temp.end);
                        let indexTime = 0
                        for (let i = NonFullListItem.length - 1; 0 <= i; i--) {
                            let index2 = shalloNonFullList[indexNonFull].lists.findIndex(item => NonFullListItem[i].start === item.start && NonFullListItem[i].end === item.end);
                            if (index2 !== -1)
                            {
                                let indexStart = (shalloNonFullList[indexNonFull].lists)[index2].start
                                let indexEnd = (shalloNonFullList[indexNonFull].lists)[index2].end
                                indexTime = indexTime + ((indexEnd.getHours() * 60 + indexEnd.getMinutes()) - (indexStart.getHours() * 60 + indexStart.getMinutes()));
                                shalloNonFullList[indexNonFull].lists.splice(index2,1);
                            }
                        }
                        remaintime = shalloNonFullList[indexNonFull].remainMinute - (totalMinuteEnd - totalMinuteStart) + indexTime
                    }
                    if( 0 < remaintime )
                    {
                        (shalloNonFullList[indexNonFull].lists).push(temp)
                        shalloNonFullList[indexNonFull].remainMinute = remaintime

                        minutesTemp = remaintime % 60;
                        hoursTemp = Math.floor(remaintime / 60);
                        shalloNonFullDate[indexNonFull].holidayName = hoursTemp.toString().padStart(2, '0') + ":" + minutesTemp.toString().padStart(2, '0')
                    }else
                    {
                        shalloNonFullDate.splice(indexNonFull, 1);
                        shalloNonFullList.splice(indexNonFull, 1);
                        newEndDate = new Date(endDate)
                        newEndDate.setHours(EH,EM)
                        shalloExclude.push({start: subDays(newEndDate , 1) , end: newEndDate});
                    }
                }
            }
            else
            {
                let inExclude = shalloExclude.filter((date) => format(date.start, 'yyyy-MM-dd') < format(endDate, 'yyyy-MM-dd') && format(endDate, 'yyyy-MM-dd') <= format(date.end, 'yyyy-MM-dd'));
                if (inExclude.length === 0)
                {
                    temp = {"start": startDate, "end": endDate}
                    totalMinute = WH - (totalMinuteEnd - totalMinuteStart)
                    key = {"date": startDate, "lists": [temp], "remainMinute": totalMinute}
                    shalloNonFullList.push(key)
                    minutesTemp = totalMinute % 60;
                    hoursTemp = Math.floor(totalMinute / 60);
                    key = {
                        date: endDate,
                        holidayName: hoursTemp.toString().padStart(2, '0') + ":" + minutesTemp.toString().padStart(2, '0'),
                    }
                    shalloNonFullDate.push(key)
                }

            }
        }
    }

    function assisstForth(startDate, endDate, totalMinuteEnd, totalMinuteStart, state)
    {
        let temp = ""
        let key = {}
        let totalMinute = 0
        let minutesTemp = ""
        let hoursTemp = ""
        let newEndDate = ""
        let shalloNonFullDate = []
        let shalloNonFullList = []
        let shalloExclude = []
        if (state === "Critical")
        {
            shalloNonFullDate = nonFullDate0
            shalloNonFullList = nonFullList0
            shalloExclude = excludeDate0
        }else
        {
            shalloNonFullDate = nonFullDate
            shalloNonFullList = nonFullList
            shalloExclude = excludeDate
        }

        let existDate = shalloNonFullDate.filter((t) => (format(startDate, 'yyyy-MM-dd') === format(t.date, 'yyyy-MM-dd')));
        if (existDate.length > 0)
        {
            let indexNonFull = shalloNonFullDate.findIndex(item => format(item.date, 'yyyy-MM-dd') === format(startDate, 'yyyy-MM-dd'));
            temp = {"start": startDate, "end": endDate}
            let index0 = (shalloNonFullList[indexNonFull].lists).findIndex(item => item.start <= temp.start && temp.end <= item.end);
            if (index0 === -1)
            {
                let remaintime = shalloNonFullList[indexNonFull].remainMinute - (totalMinuteEnd - totalMinuteStart)

                if( 0 < remaintime)
                {
                    (shalloNonFullList[indexNonFull].lists).push(temp)
                    shalloNonFullList[indexNonFull].remainMinute = remaintime

                    minutesTemp = remaintime % 60;
                    hoursTemp = Math.floor(remaintime / 60);
                    shalloNonFullDate[indexNonFull].holidayName = hoursTemp.toString().padStart(2, '0') + ":" + minutesTemp.toString().padStart(2, '0')
                }else
                {
                    shalloNonFullDate.splice(indexNonFull, 1);
                    shalloNonFullList.splice(indexNonFull, 1);
                    newEndDate = new Date(endDate)
                    newEndDate.setHours(EH,EM)
                    shalloExclude.push({start: subDays(newEndDate , 1) , end: newEndDate});
                }
            }
        }
        else
        {
            let inExclude = shalloExclude.filter((date) => format(date.start, 'yyyy-MM-dd') < format(startDate, 'yyyy-MM-dd') && format(startDate, 'yyyy-MM-dd') <= format(date.end, 'yyyy-MM-dd'));
            if (inExclude.length === 0){
                temp = {"start": startDate, "end": endDate}
                totalMinute = WH - (totalMinuteEnd - totalMinuteStart)
                key = {"date": startDate, "lists": [temp], "remainMinute": totalMinute}
                shalloNonFullList.push(key)
                minutesTemp = totalMinute % 60;
                hoursTemp = Math.floor(totalMinute / 60);
                key = {
                    date: startDate,
                    holidayName: hoursTemp.toString().padStart(2, '0') + ":" + minutesTemp.toString().padStart(2, '0'),
                }
                shalloNonFullDate.push(key)
            }
        }
    }

    const calculateList = (startDate, endDate, state) =>
    {
        let totalMinuteEnd = endDate.getHours() * 60 + endDate.getMinutes();
        let totalMinuteStart = startDate.getHours() * 60 + startDate.getMinutes();
        let newEndDate = "";
        let newStartDate = "";
        let index0 = "";
        /////// make dates between start and end in exclude and omit them from nonfull dates

        if (state === "Critical")
        {
            let existDate = nonFullDate0.filter( (t) => (format(startDate, 'yyyy-MM-dd') < format(t.date, 'yyyy-MM-dd') ) && (format(t.date, 'yyyy-MM-dd') < format(endDate, 'yyyy-MM-dd') ));
            if (existDate.length > 0)
            {
                let index0 = "";
                for (let i= existDate.length -1; 0 <= i; i--)
                {
                    index0 = nonFullDate0.findIndex(item => format(item.date, 'yyyy-MM-dd')  === format(existDate[i].date, 'yyyy-MM-dd'));
                    nonFullDate0.splice(index0, 1);
                    nonFullList0.splice(index0, 1);
                }
            }
            if (format(startDate, 'yyyy-MM-dd') < format(endDate, 'yyyy-MM-dd'))
            {
                newEndDate = subDays(new Date(endDate) , 1)
                newEndDate.setHours(EH,EM)
                newStartDate = new Date(startDate)
                newStartDate.setHours(SH,SM)
                if (newStartDate <= newEndDate){
                    excludeDate0.push({start: newStartDate, end: newEndDate})
                }
            }
        }
        else
        {
            let existDate = nonFullDate.filter( (t) => (format(startDate, 'yyyy-MM-dd') < format(t.date, 'yyyy-MM-dd') ) && (format(t.date, 'yyyy-MM-dd') < format(endDate, 'yyyy-MM-dd') ));
            if (existDate.length > 0)
            {
                for (let i= existDate.length -1; 0 <= i; i--)
                {
                    index0 = nonFullDate.findIndex(item => format(item.date, 'yyyy-MM-dd')  === format(existDate[i].date, 'yyyy-MM-dd'));
                    nonFullDate.splice(index0, 1);
                    nonFullList.splice(index0, 1);
                }
            }
            if (format(startDate, 'yyyy-MM-dd') < format(endDate, 'yyyy-MM-dd'))
            {
                newEndDate = subDays(new Date(endDate) , 1)
                newEndDate.setHours(EH,EM)
                newStartDate = new Date(startDate)
                newStartDate.setHours(SH,SM)
                if (newStartDate <= newEndDate){
                    excludeDate.push({start: newStartDate, end: newEndDate})
                }
            }
        }

        /////// make start date in exCludeDate/excludeDate0 or nonfulllists/nonfulllists0
        // assisstThree: start time start in the morning with / widthout end date of evening
        // assisstForth: start time start in bigger than 8: 30 but end at the evening 17: 00
        if (totalMinuteStart - WSH < 30)
        {
            if (format(startDate, 'yyyy-MM-dd') === format(endDate, 'yyyy-MM-dd')) {
                assisstThree(startDate, endDate, totalMinuteEnd, totalMinuteStart, state)
            }
            else
            {
                newEndDate = new Date(startDate)
                newEndDate.setHours(EH, EM)
                if (state === "Critical")
                {
                    index0 = nonFullDate0.findIndex(item => format(item.date, 'yyyy-MM-dd')  === format(startDate, 'yyyy-MM-dd'));
                    if (index0 !== -1){
                        nonFullDate0.splice(index0, 1);
                        nonFullList0.splice(index0, 1);
                    }
                    excludeDate0.push({start: subDays(startDate, 1), end: newEndDate});
                }
                else
                {
                    index0 = nonFullDate.findIndex(item => format(item.date, 'yyyy-MM-dd')  === format(startDate, 'yyyy-MM-dd'));
                    if (index0 !== -1){
                        nonFullDate.splice(index0, 1);
                        nonFullList.splice(index0, 1);
                    }
                    excludeDate.push({start: subDays(startDate, 1), end: newEndDate});
                }
                newStartDate = new Date(endDate)
                newStartDate.setHours(SH, SM)
                assisstThree(newStartDate, endDate, totalMinuteEnd, SH * 60 + SM, state)
            }
        }
        else
        {
            if (format(startDate, 'yyyy-MM-dd') === format(endDate, 'yyyy-MM-dd'))
            {
                assisstForth(startDate, endDate, totalMinuteEnd, totalMinuteStart, state)
            }
            else
            {
                newStartDate = new Date(endDate)
                newStartDate.setHours(SH, SM)
                assisstThree(newStartDate, endDate, totalMinuteEnd, SH * 60 + SM, state)

                newEndDate = new Date(startDate)
                newEndDate.setHours(EH, EM)
                assisstForth(startDate, newEndDate, EH * 60 + EM, totalMinuteStart, state)
            }
        }
    }

    function timeClass(projIndex, staffName, limitation , projectClass, counter)
    {
        let tableRows = [];
        if (limitation === "Critical") {
            tableRows = dataTemp[projIndex].filter(row => row[2] === staffName && row[5] === "Critical")
        }else if (limitation === "No Critical"){
            tableRows = dataTemp[projIndex].filter(row => row[2] === staffName && row[5] !== "Critical")
        }else if (limitation === "None"){
            tableRows = dataTemp[projIndex].filter(row => row[2] === staffName)
        }
        for (let j = 0; j < tableRows.length; j++) {
            if ((tableRows[j][4] !== "") && (tableRows[j][11] !== "")) {
                let startDate = new Date(tableRows[j][11])
                let endDate = new Date(tableRows[j][4])
                if (projectClass === "projectDates0"){
                    projectDates0.push({"startDate": startDate, "endDate": endDate})
                }else{
                    projectDates.push({"counter": counter, "startDate": startDate, "endDate": endDate})
                    let index0 = dataTemp[projIndex].findIndex(item => item === tableRows[j]);
                    let key = [[projIndex, index0], counter]
                    projtaskID.push(key)
                    counter += 1
                }
            }
        }
        return counter
    }

    function staffTaskInformation(tableNumber, rowNumber, staffName, act)
    {
        projectDates = [];
        projectDates0 = [];
        let counter = 0
        if (staffName !== ""){
            if (tableNumber !== "" && rowNumber !== "" && dataTemp[tableNumber][rowNumber][5] === "Critical")
            {
                console.log("item 1: Critical")
                for (let i = 0; i < projList.length; i++)
                {
                    timeClass(i, staffName, "Critical" , "projectDates0" , "")
                    counter = timeClass(i, staffName, "No Critical" , "projectDates" , counter)
                }

            }

            // else if ((tableNumber !== "" && rowNumber !== "" && dedicatedRow[0] === tableNumber && dedicatedRow[1] === rowNumber) || (act === "dedicatedRow"))
            // {
            //     console.log("item 2: dedicatedRow")
            //     for (let i = 0; i < projList.length; i++)
            //     {
            //         if (tableNumber === i){
            //             timeClass(i, staffName, "Critical" , "projectDates0" , "")
            //             counter = timeClass(i, staffName, "No Critical" , "projectDates" , counter)
            //         }else{
            //             timeClass(i, staffName, "None" , "projectDates0" , "")
            //         }
            //     }
            // }else if (act === "omit")
            // {
            //     console.log("item 3: omit")
            //     for (let i = 0; i < projList.length; i++)
            //     {
            //         timeClass(i, staffName, "Critical" , "projectDates0" , "");
            //         counter = timeClass(i, staffName, "No Critical" , "projectDates" , counter)
            //     }
            // }


            else{
                console.log("item 4: others")
                for (let i = 0; i < projList.length; i++)
                {
                    counter = timeClass(i, staffName, "None" , "projectDates" , counter)
                }
            }
        }
        projectDates.sort((a, b) => (
            a.startDate > b.startDate ? 1 : b.startDate > a.startDate ? -1 : 0)
        );

        startDates = projectDates.map(i => i.startDate).flat();
        endDates = projectDates.map(i => i.endDate).flat();
        let Ids = projectDates.map(i => i.counter).flat();
        projtaskID.sort((a, b) => Ids.indexOf(a[1]) - Ids.indexOf(b[1]));
        projectDates0.sort((a, b) => (
            a.startDate > b.startDate ? 1 : b.startDate > a.startDate ? -1 : 0)
        );
        startDates0 = projectDates0.map(i => i.startDate).flat();
        endDates0 = projectDates0.map(i => i.endDate).flat();
    }

    function staffDatesInformation(tableNumber, rowNumber, staffName, act)
    {
        if (startDates0.length > 0 )
        {
            for (let i = 0; i < startDates0.length; i++)
            {
                calculateList(startDates0[i], endDates0[i], "Critical")
            }
        }

        if (startDates.length > 0 )
        {
            for (let i = 0; i < startDates.length; i++)
            {
                calculateList(startDates[i], endDates[i], "nonCritical")
            }
        }

        setExcludeIntervals(excludeDate);
        setExcludeCritical(excludeDate0);
        setPartialIntervals(nonFullDate);
        setPartialCritical(nonFullDate0);
        setPartialIntervalsList(nonFullList);
        setPartialCriticalList(nonFullList0)



        // if (staffName !== "") {
        //     if ((dataTemp[tableNumber][rowNumber][5] === "Critical") || (dedicatedRow === [tableNumber,rowNumber]) || (act === "dedicatedRow") )
        //     {
        //         taskHighlight = [];
        //         for (let i=0; i < excludeDate.length; i++)
        //         {
        //             let date0 = addDays(new Date(excludeDate[i].start) , 1);
        //             let endDate0 = format(new Date(excludeDate[i].end), 'yyyy-MM-dd')
        //             while (format(date0, 'yyyy-MM-dd') <= endDate0){
        //                 taskHighlight.push(date0)
        //                 date0 = addDays(date0 , 1);
        //             }
        //         }
        //         // taskHighlight = [
        //         //     {
        //         //         "react-datepicker__day--highlighted-custom-1": highlightedTasks,
        //         //     }
        //         // ];
        //
        //     }else{
        //         taskHighlight = [];
        //     }
        // }

    }

    function staffInformation(key1, staffName)
    {
        const month = new Date().toLocaleString('default', { month: 'long' });
        let Detail = Staff.filter(row => row[0] === staffName)
        let group = auth().user_record[1]
        if (Detail.length > 0 ){
            group = Detail[0][1]
        }
        const date = format(new Date, 'yyyy-MM-dd' )

        let data0 = offRecord.filter(row => row[3] === staffName );
        let hours = "";
        let minutes = "";
        let index = "";
        for (let i=0; i< data0.length; i++)
        {
            let item = data0[i]
            if (item[2] === "Yes")
            {
                if ((Math.abs(totalMinutes(item[4]) - WSH) < 60 ) && (Math.abs(totalMinutes(item[5]) - WEH) < 60 ))
                {
                    let newExcloudDate = new Date(item[1])
                    newExcloudDate.setHours(EH,EM)
                    let key = {start: subDays(newExcloudDate , 1) , end: newExcloudDate };
                    excludeDate.push(key);
                    excludeDate0.push(key);
                }else
                {
                    index = (item[4]).indexOf(":");
                    hours = parseInt((item[4]).substring(0, index));
                    minutes = parseInt((item[4]).substring(index + 1, (item[4]).length));
                    let startDate = new Date(item[1])
                    startDate.setHours(hours, minutes)

                    index = (item[5]).indexOf(":");
                    hours = parseInt((item[5]).substring(0, index));
                    minutes = parseInt((item[5]).substring(index + 1, (item[5]).length));
                    let endDate = new Date(item[1])
                    endDate.setHours(hours, minutes)

                    let temp = {"start": startDate, "end": endDate}
                    let totalMinute = WH - (totalMinutes(item[5]) - totalMinutes(item[4]))
                    let key0 = {"date": startDate, "lists": [temp], "remainMinute": totalMinute}

                    minutes = totalMinute % 60;
                    hours = Math.floor(totalMinute / 60);
                    let key = {
                        date: endDate,
                        holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
                    }
                    nonFullList.push(key0)
                    nonFullList0.push(key0)
                    nonFullDate.push(key)
                    nonFullDate0.push(key)
                }
            }
        }
    }

    const datePickerClick = (key1, index) =>
    {
        dataTemp = [...projData];
        setCalenderOpen(true)
        handleClickCell(key1, index, "", "");
        // if (key1 !== dedicatedRow[0] || index !== dedicatedRow[1])
        // {
        //     if (dataTemp[key1][index][0] !== "" && dataTemp[key1][index][11] === "")
        //     {
        //         let index0 = dataTemp[key1].findIndex(item => item[12] === dataTemp[key1][index][0])
        //         if (index0 !== -1)
        //         {
        //             handleClickCell(key1, index, "", "dedicatedRow");
        //         }else{
        //             handleClickCell(key1, index, "", "");
        //         }
        //     }else{
        //         handleClickCell(key1, index, "", "");
        //     }
        // }else{
        //     handleClickCell(key1, index, "", "");
        // }
    }

    const handleClickCell = (key1, index, staff, act) => {
        excludeDate.splice(0)
        startDates.splice(0)
        endDates.splice(0)
        nonFullDate.splice(0)
        projtaskID.splice(0)
        nonFullList.splice(0)

        excludeDate0.splice(0)
        nonFullDate0.splice(0);
        startDates0.splice(0);
        endDates0.splice(0);
        nonFullList0.splice(0)
        taskHighlight.splice(0);


        let staffName = staff
        if (staff === ""){
            staffName = dataTemp[key1][index][2];
        }
        staffInformation(key1, staffName );
        staffTaskInformation(key1, index, staffName, act);
        staffDatesInformation(key1, index, staffName, act);

    };

    function getTimeInterval (mainDate, taskTime, state)
    {
        let shalloNonFullDate = []
        let shalloNonFullList = []
        let shalloExclude = []
        let index0 = ""

        if (state === "Critical")
        {
            shalloNonFullDate = [...partialCritical]
            shalloNonFullList = [...partialCriticalList]
            shalloExclude = [...excludeCritical]
        }else
        {
            shalloNonFullDate = [...partialIntervals]
            shalloNonFullList =  [...partialIntervalsList]
            shalloExclude = [...excludeIntervals]
        }

        let index = taskTime.indexOf(".");
        let hours0 = "";
        let minutes0 = "";
        let startDetermined = -1
        let required = "";
        if (index != -1)
        {
            hours0 = parseInt(taskTime.substring(0, index));
            let num2 = Number(taskTime)
            //let num2 = parseFloat(taskTime).toFixed(2)
            minutes0 = (num2 - Math.floor(num2)) * 60;
            required = hours0 * 60 + minutes0;
        }else
        {
            required = parseInt(taskTime) * 60;
        }

        let Continue = true;
        let newDate = new Date(mainDate);
        let lastDate = "";

        while (Continue)
        {
            let nonFullItems = shalloNonFullDate.filter((t) => format(t.date, 'yyyy-MM-dd') === format(newDate, 'yyyy-MM-dd'));
            if (nonFullItems.length > 0)
            {
                let indexNonFull = shalloNonFullDate.findIndex(item => format(item.date, 'yyyy-MM-dd') === format(nonFullItems[0].date, 'yyyy-MM-dd'));
                let nonFullItem = shalloNonFullList[indexNonFull];

                (nonFullItem.lists).sort((a, b) => (
                    a.start > b.start ? 1 : b.start > a.start ? -1 : 0)
                );

                let temp = new Date(newDate)
                let nonFull = true
                while (Continue && nonFull)
                {
                    let index0 = (nonFullItem.lists).findIndex(item => item.start <= temp && temp < item.end);
                    if (index0 !== -1)
                    {
                        temp = (nonFullItem.lists)[index0].end;
                    }else
                    {
                        let index1 = nonFullItem.lists.findIndex(item => temp < item.start);
                        let c_hour = temp.getHours();
                        let c_minute = temp.getMinutes();
                        let c_totalMinute = c_hour * 60 + c_minute;
                        if (index1 !== -1)
                        {
                            if (startDetermined === -1)
                            {
                                if (mainDate !== temp)
                                {
                                    mainDate = new Date(temp);
                                }
                                mainDate.setHours(temp.getHours(),temp.getMinutes())
                                startDetermined = 1
                            }

                            let Hour0 = (nonFullItem.lists[index1].start).getHours();
                            let Minute = (nonFullItem.lists[index1].start).getMinutes();
                            let availableMinute = Hour0 * 60 + Minute - c_totalMinute
                            if (availableMinute > 0)
                            {
                                if (availableMinute < required)
                                {
                                    required -= availableMinute;
                                    shalloNonFullList[indexNonFull].remainMinute -= availableMinute;
                                    let minutes = (shalloNonFullList[indexNonFull].remainMinute) % 60;
                                    let hours = Math.floor((shalloNonFullList[indexNonFull].remainMinute) / 60);
                                    shalloNonFullDate[indexNonFull].holidayName = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
                                    let key = {"start":temp , "end": nonFullItem.lists[index1].start }
                                    shalloNonFullList[indexNonFull].lists.splice(index1, 0, key);
                                    temp = nonFullItem.lists[index1].end;
                                }
                                else
                                {
                                    let Hour0 = (temp).getHours();
                                    let Minute = (temp).getMinutes();
                                    let dateMinute = Hour0 * 60 + Minute + required;
                                    let minutes1 = (dateMinute) % 60;
                                    let hours1 = Math.floor((dateMinute ) / 60);
                                    newDate.setHours( hours1 , minutes1 );
                                    lastDate = new Date(newDate);
                                    shalloNonFullList[indexNonFull].remainMinute -= required;
                                    let minutes = (shalloNonFullList[indexNonFull].remainMinute) % 60;
                                    let hours = Math.floor((shalloNonFullList[indexNonFull].remainMinute) / 60);
                                    shalloNonFullDate[indexNonFull].holidayName = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
                                    let key = {"start":temp , "end": newDate }
                                    shalloNonFullList[indexNonFull].lists.splice(index1, 0, key);
                                    required = 0
                                    Continue = false;
                                }

                                if (shalloNonFullList[indexNonFull].remainMinute === 0){
                                    nonFull = false
                                    let newEndDate = new Date(newDate)
                                    newEndDate.setHours(EH,EM)
                                    shalloExclude.push({start: subDays(newEndDate , 1) , end: newEndDate});
                                    shalloNonFullDate.splice(indexNonFull, 1);
                                    shalloNonFullList.splice(indexNonFull, 1);
                                    newDate = addDays(newDate, 1);
                                    newDate.setHours(SH,SM)
                                }
                            }
                        }
                        else
                        {
                            let availableMinute = EH * 60 + EM - c_totalMinute;
                            if (availableMinute > 0)
                            {
                                if (startDetermined === -1)
                                {
                                    if (mainDate !== temp)
                                    {
                                        mainDate = new Date(temp);
                                        mainDate.setHours(temp.getHours(),temp.getMinutes())
                                    }

                                    startDetermined = 1
                                }

                                if (availableMinute < required)
                                {
                                    required -= availableMinute;
                                    shalloNonFullList[indexNonFull].remainMinute -= availableMinute;
                                    let minutes = (shalloNonFullList[indexNonFull].remainMinute) % 60;
                                    let hours = Math.floor((shalloNonFullList[indexNonFull].remainMinute) / 60);
                                    shalloNonFullDate[indexNonFull].holidayName = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
                                    let tempEnd = new Date(temp)
                                    tempEnd.setHours(EH,EM)
                                    let key = {"start":temp , "end": tempEnd }
                                    shalloNonFullList[indexNonFull].lists.splice(index1, 0, key);
                                    nonFull = false
                                }
                                else
                                {
                                    let Hour0 = (temp).getHours();
                                    let Minute = (temp).getMinutes();
                                    let dateMinute = Hour0 * 60 + Minute + required;
                                    let minutes1 = (dateMinute) % 60;
                                    let hours1 = Math.floor((dateMinute ) / 60);
                                    newDate.setHours( hours1 , minutes1 );
                                    lastDate = newDate;

                                    shalloNonFullList[indexNonFull].remainMinute -= required;
                                    let minutes = (shalloNonFullList[indexNonFull].remainMinute) % 60;
                                    let hours = Math.floor((shalloNonFullList[indexNonFull].remainMinute) / 60);
                                    shalloNonFullDate[indexNonFull].holidayName = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
                                    let key = {"start":temp , "end": newDate }
                                    shalloNonFullList[indexNonFull].lists.splice(index1, 0, key);
                                    required = 0
                                    Continue = false;
                                }
                                if (shalloNonFullList[indexNonFull].remainMinute === 0){
                                    let newEndDate = new Date(newDate)
                                    newEndDate.setHours(EH,EM)
                                    shalloExclude.push({start: subDays(newEndDate , 1) , end: newEndDate});
                                    shalloNonFullDate.splice(indexNonFull, 1);
                                    shalloNonFullList.splice(indexNonFull, 1);
                                }
                                newDate = addDays(newDate, 1);
                                newDate.setHours(SH,SM);

                            }else
                            {
                                newDate = addDays(newDate, 1);
                                newDate.setHours(SH,SM)
                                nonFull = false;
                            }
                        }
                    }
                }
            }
            else
            {
                let inExclude = []
                if (shalloExclude.length > 0){
                    inExclude = shalloExclude.filter((date) => format(date.start, 'yyyy-MM-dd') < format(newDate, 'yyyy-MM-dd') && format(newDate, 'yyyy-MM-dd') <= format(date.end, 'yyyy-MM-dd'));
                }
                if (inExclude.length > 0 || newDate.getDay() === 4 || newDate.getDay() === 5)
                {
                    newDate = addDays(newDate, 1);
                    newDate.setHours(SH,SM)

                }
                else
                {
                    const day = newDate.getDay();
                    // if (day === 1 || day === 3){
                    //     if (startDetermined === -1)
                    //     {
                    //         mainDate.setHours(12,0)
                    //         startDetermined = 1
                    //     }
                    //     if (required <= 5 * 60)
                    //     {
                    //         let minutes1 = ( 12 * 60 + required ) % 60;
                    //         let hours1 = Math.floor((12 * 60 + required) / 60);
                    //         newDate.setHours( hours1 , minutes1);
                    //         Continue = false;
                    //         lastDate = newDate;
                    //         required = 0;
                    //         break
                    //     }else{
                    //         requi"red -= 5 * 60;
                    //         newDate = addDays(newDate, 1);
                    //     }
                    // }
                    // else{
                    if (startDetermined === -1) {
                        if (format(mainDate, 'yyyy-MM-dd')  !== format(newDate, 'yyyy-MM-dd') )
                        {
                            mainDate = new Date(newDate);
                            mainDate.setHours(SH,SM)
                        }
                        startDetermined = 1
                    }
                    let WSH0 = WSH
                    if (format(mainDate, 'yyyy-MM-dd')  === format(newDate, 'yyyy-MM-dd'))
                    {
                        WSH0= mainDate.getHours() * 60 + mainDate.getMinutes()
                    }

                    if (required <= WEH - WSH0)
                    {
                        let minutes1 = (WSH0 + required ) % 60;
                        let hours1 = Math.floor((WSH0 + required ) / 60);
                        newDate.setHours( hours1 , minutes1);
                        Continue = false;
                        lastDate = newDate;
                        required = 0;
                        break
                    }else
                    {
                        required -= WEH - WSH0;
                        newDate = addDays(newDate, 1);
                        newDate.setHours(SH,SM)
                    }
                }
            }
        }
        return [mainDate, lastDate];
    }

    function  dateAllocation(mainDate, projNum,tableRow, act, state)
    {

        let finalDate = "";
        if (state === "allocation")
        {
            mainDate.setHours(SH,SM)
            if (dataTemp[projNum][tableRow][10] !== "")
            {
                let preIDs = dataTemp[projNum][tableRow][10].item
                for (let k = 0; k < preIDs.length; k++)
                {
                    let index0 = dataTemp[projNum].findIndex(item => item[0].toString() === preIDs[k] );
                    if (index0 !== -1)
                    {
                        if (dataTemp[projNum][index0][4] !== "")
                        {
                            if (mainDate < new Date(dataTemp[projNum][index0][4]))
                            {
                                mainDate = new Date(dataTemp[projNum][index0][4]);
                                let startHour0 = mainDate.getHours();
                                let startMinute = mainDate.getMinutes();
                                if ((WEH - (startHour0 * 60 + startMinute)) < 30)
                                {
                                    mainDate = addDays(mainDate, 1)
                                    mainDate.setHours(SH,SM)
                                }
                            }
                        }
                    }
                }
            }

            let startDate = ""
            let endDate = ""
            let sameEndDate = endDates.filter((date) => format(date, 'yyyy-MM-dd') === format(mainDate, 'yyyy-MM-dd'));
            if (sameEndDate.length > 0) {
                let indexSameEnd = endDates.findIndex(date => format(date, 'yyyy-MM-dd') === format(mainDate, 'yyyy-MM-dd'));
                startDate = startDates[indexSameEnd]
                endDate = endDates[indexSameEnd]
                startDates0.push(startDate)
                endDates0.push(endDate)
                startDates.splice(indexSameEnd, 1);
                endDates.splice(indexSameEnd, 1);
                projtaskID.splice(indexSameEnd, 1);

                calculateList(startDate, endDate, "Critical")
            }


            const output = getTimeInterval(mainDate, dataTemp[projNum][tableRow][6],"Critical");
            dataTemp[projNum][tableRow][11] = format(output[0], 'yyyy-MM-dd kk:mm');
            dataTemp[projNum][tableRow][4] = format(output[1], 'yyyy-MM-dd kk:mm');

            startDate = new Date(dataTemp[projNum][tableRow][11])
            endDate = new Date(dataTemp[projNum][tableRow][4])
            startDates0.push(startDate)
            endDates0.push(endDate)

            calculateList(startDate,endDate, "Critical")
            changesId.push([projNum,tableRow]);
            if (act === 1)
            {
                newchangedArr.push([projNum, tableRow]);
            }
            finalDate = output[0];
        }else
        {
            finalDate = mainDate;
        }

        for ( let i=0; i < startDates.length; i++ )
        {
            let startDate = startDates[i]
            let endDate = endDates[i]
            let projNum0 = projtaskID[i][0][0]
            let tableRow0 = projtaskID[i][0][1]
            if (endDate <= finalDate)
            {
                startDates0.push(startDate)
                endDates0.push(endDate)
                calculateList(startDate,endDate, "Critical")
            }
            else
            {
                let newDate = new Date()
                newDate.setHours(SH,SM)
                if (state === "allocation"){
                    if (new Date(dataTemp[projNum0][tableRow0][11]) <= finalDate){
                        newDate = new Date(dataTemp[projNum0][tableRow0][11])
                    }else{
                        newDate = finalDate
                    }
                }else if (state === "omit"){
                    newDate = finalDate
                }

                if (dataTemp[projNum0][tableRow0][10] !== "")
                {
                    let preIDs = dataTemp[projNum0][tableRow0][10].item
                    for (let k = 0; k < preIDs.length; k++)
                    {
                        let index0 = dataTemp[projNum0].findIndex(item => item[0].toString() === preIDs[k] );
                        if (index0 !== -1)
                        {
                            if (dataTemp[projNum0][index0][4] !== "")
                            {
                                if (mainDate < new Date(dataTemp[projNum0][index0][4]))
                                {
                                    mainDate = new Date(dataTemp[projNum0][index0][4]);
                                    let startHour0 = mainDate.getHours();
                                    let startMinute = mainDate.getMinutes();
                                    if ((WEH - (startHour0 * 60 + startMinute)) < 30)
                                    {
                                        mainDate = addDays(mainDate, 1)
                                        mainDate.setHours(SH,SM)
                                    }
                                }
                            }
                        }
                    }
                }
                const output = getTimeInterval(newDate, dataTemp[projNum0][tableRow0][6] , "Critical");
                dataTemp[projNum0][tableRow0][11] = format(output[0], 'yyyy-MM-dd kk:mm');
                dataTemp[projNum0][tableRow0][4] = format(output[1], 'yyyy-MM-dd kk:mm');
                startDates0.push(output[0]);
                endDates0.push(output[1]);
                calculateList(output[0] ,output[1], "Critical");
                changesId.push([projNum0 , tableRow0]);
                if (act === 1)
                {
                    newchangedArr.push([projNum0,tableRow0]);
                }

            }
        }
    }

    function affectedDate(act)
    {
        newchangedArr = [...changesId]
        let projNum01 = "";
        let tableNum01 = "";
        while (newchangedArr.length > 0)
        {
            projNum01 = newchangedArr[0][0];
            tableNum01 = newchangedArr[0][1];
            if (dataTemp[projNum01][tableNum01][0] <= 1000 && dataTemp[projNum01][tableNum01][4] !== "")
            {
                let indexes = [];
                let kk = 0
                for (kk= 0; kk < dataTemp[projNum01].length; kk++)
                {
                    if (dataTemp[projNum01][kk][10] !== ""){
                        if ((dataTemp[projNum01][kk][10].item).includes(dataTemp[projNum01][tableNum01][0].toString())) {
                            indexes.push(kk);
                        }
                    }
                }

                for (kk = 0; kk < indexes.length; kk++ )
                {
                    let index0 = indexes[kk]
                    if (act === "omit")
                    {
                        if (dataTemp[projNum01][index0][11] !== ""){
                            if (dataTemp[projNum01][index0][11] < dataTemp[projNum01][tableNum01][4]){
                                handleClickCell(projNum01, index0, dataTemp[projNum01][index0][2], "omit")
                                let newDate = new Date(dataTemp[projNum01][tableNum01][4])
                                dateAllocation(newDate, projNum01, index0, 1, "omit")
                            }
                        }
                    }else if (act === "forward")
                    {
                        if (dataTemp[projNum01][index0][11] !== ""){
                            if (dataTemp[projNum01][index0][11] < dataTemp[projNum01][tableNum01][4])
                            {
                                handleClickCell(projNum01, index0, dataTemp[projNum01][index0][2], "dedicatedRow")
                                let newDate = new Date(dataTemp[projNum01][tableNum01][4])
                                dateAllocation(newDate, projNum01, index0, 1, "allocation")
                            }
                        }
                    }
                }
            }
            newchangedArr.splice(0, 1);
        }
    }

    const handleDateChange = (mainDate, projNum,tableRow) =>
    {
        dataTemp = [...projData];
        let act = ""
        // if (projNum !== dedicatedRow[0] || tableRow !== dedicatedRow[1]) {
        //     if (dataTemp[projNum][tableRow][0] !== "" && dataTemp[projNum][tableRow][11] === "") {
        //         let index0 = dataTemp[projNum].findIndex(item => item[12] === dataTemp[projNum][tableRow][0])
        //         if (index0 !== -1) {
        //             act = "dedicatedRow"
        //         }
        //     }
        // }

        if (mainDate === null)
        {
            changesId.push([projNum,tableRow]);
            if (dataTemp[projNum][tableRow][11] !== "")
            {
                let oldDate = new Date(dataTemp[projNum][tableRow][11]);
                dataTemp[projNum][tableRow][11] = "";
                dataTemp[projNum][tableRow][4] = "";
                handleClickCell(projNum,tableRow, "", "omit");
            }
        }else
        {
            if ((dataTemp[projNum][tableRow][5] === "Critical") || (dedicatedRow[0] === projNum && dedicatedRow[1] === tableRow) || (act === "dedicatedRow") )
            {
                dateAllocation(mainDate, projNum,tableRow, 0, "allocation");
                affectedDate("forward");
            }
            else
            {
                mainDate.setHours(SH,SM)
                if (dataTemp[projNum][tableRow][10] !== "")
                {
                    let preIDs = dataTemp[projNum][tableRow][10].item;
                    for (let k = 0; k < preIDs.length; k++)
                    {
                        let index0 = dataTemp[projNum].findIndex(item => item[0].toString() === preIDs[k].toString() );
                        if (index0 !== -1)
                        {
                            if (dataTemp[projNum][index0][4] !== "")
                            {
                                if (mainDate < new Date(dataTemp[projNum][index0][4]))
                                {
                                    mainDate = new Date(dataTemp[projNum][index0][4]);
                                    let startHour0 = mainDate.getHours();
                                    let startMinute = mainDate.getMinutes();
                                    if ((WEH - (startHour0 * 60 + startMinute)) < 30)
                                    {
                                        mainDate = addDays(mainDate, 1)
                                        mainDate.setHours(SH,SM)
                                    }
                                }
                            }
                        }
                    }
                }

                const output = getTimeInterval(mainDate, dataTemp[projNum][tableRow][6], "nonCritical");
                dataTemp[projNum][tableRow][11] = format(output[0], 'yyyy-MM-dd kk:mm');
                dataTemp[projNum][tableRow][4] = format(output[1], 'yyyy-MM-dd kk:mm');
                changesId.push([projNum,tableRow]);
            }
        }

        if (dataTemp[projNum][tableRow][13] !== ""){
            let output = [["",""], ["",""]]
            if (dataTemp[projNum][tableRow][11] === ""){
                output =  hostIdDate(projNum, dataTemp[projNum][tableRow][13], dataTemp[projNum][tableRow][0], 0, 0, "omit")
            }else{
                output = hostIdDate(projNum, dataTemp[projNum][tableRow][13], dataTemp[projNum][tableRow][0], dataTemp[projNum][tableRow][11], dataTemp[projNum][tableRow][4], "insert")
            }

            if (output[0][1])
            {
                dataTemp[projNum][output[1][1]][11] = output[0][0];
                dataTemp[projNum][output[1][1]][4] = output[0][1];
            }else
            {
                dataTemp[projNum][output[1][1]][11] = "";
                dataTemp[projNum][output[1][1]][4]  = "";
            }
            changesId.push([output[1][0],output[1][1]]);
        }

        setProjData(dataTemp);
        let data2 = [...editedTasks]
        for (let j=0; j < changesId.length; j++)
        {
            if (!(data2[changesId[j][0]].includes(changesId[j][1])))
            {
                data2[changesId[j][0]].push(changesId[j][1])
            }
        }
        setEditedTasks(data2)
        dataTemp = [...[]];
        changesId = [...[]];
    };

    function hostIdDate(projNum, hostId, currentId, currentStartDate, currentEndDate, act)
    {
        let hostIndex = findId(projNum,hostId);
        let output = [["",""], ["",""]]
        if (hostId in subTasks[projNum])
        {
            let record = subTasks[projNum][hostId]
            let startDatesSub = [];
            let endDatesSub = [];

            if (act === "insert")
            {
                if(currentStartDate !== "")
                {
                    startDatesSub.push(currentStartDate);
                }
                if(currentEndDate !== ""){
                    endDatesSub.push(currentEndDate)
                }
            }
            for (let j = 0; j < record.length; j++)
            {
                let subtaskId = record[j];
                if (subtaskId !== currentId)
                {
                    let subtaskIndex = findId(projNum, subtaskId);
                    if (projData[projNum][subtaskIndex][11] !== "")
                    {
                        startDatesSub.push(projData[projNum][subtaskIndex][11]);
                        endDatesSub.push(projData[projNum][subtaskIndex][4]);
                    }
                }
            }

            if (startDatesSub !== [])
            {
                startDatesSub.sort();
                endDatesSub.sort();
                output = [[startDatesSub[0], endDatesSub[endDatesSub.length - 1]], [projNum, hostIndex]]
            }
            else{
                output = [["", ""], [projNum, hostIndex]]
            }
        }
        return output;
    }

    function hostIdDatesssss(projNum, hostId)
    {
        let  hostIndex = findId(projNum,hostId);
        if (hostId in subTasks[projNum])
        {
            let record = subTasks[projNum][hostId]
            let startDatesSub= [];
            let endDatesSub = [];
            for (let j = 0; j < record.length; j++)
            {
                let subtaskId = record[j]
                let subtaskIndex = findId(projNum, subtaskId)
                if (projData[projNum][subtaskIndex][11] !== "")
                {
                    startDatesSub.push(projData[projNum][subtaskIndex][11])
                    endDatesSub.push(projData[projNum][subtaskIndex][4])
                }
            }
            startDatesSub.sort();
            endDatesSub.sort();

            dataTemp[projNum][hostIndex][11] = startDatesSub[0];
            dataTemp[projNum][hostIndex][4] = endDatesSub[endDatesSub.length - 1];
            changesId.push([projNum,hostIndex]);
        }
    }

    function preTaskDelete()
    {
        let projNum = deleteInfo.projectId;
        let tableRow = deleteInfo.taskId;
        dataTemp = [...projData];
        changesId = [...[]];

        if(dataTemp[projNum][tableRow][11] !== "")
        {
            let oldDate = new Date(dataTemp[projNum][tableRow][11]);
            dataTemp[projNum][tableRow][11] = "";
            dataTemp[projNum][tableRow][4] = "";
            handleClickCell(projNum,tableRow, "", "omit");
        }
    }

    function calculateProjProg(projectNum, tablerow){
        let projProg = 0;
        let projHours = 0;

        for ( let j= 0; j < projData[projectNum].length; j++ )
        {
            if ((projData[projectNum][j][13] === "") && (j !== tablerow)) {
                projProg += Number(projData[projectNum][j][6]) * Number(projData[projectNum][j][8]);
                projHours += Number(projData[projectNum][j][6]);
            }
        }
        let data = [...projProgress];
        data[projectNum] = Math.round((projProg / projHours) * 100) / 100;
        setProjProgress(data);
    }

    const deleteTask = async(tablekey, tablerow) => {
        setError("");
        setOpenElem(null);
        setOpenElemRow(null)
        setAnchorEl(null);
        if(dataTemp[tablekey][tablerow][13] !== "")
        {
            let key = (dataTemp[tablekey][tablerow][13]).toString();
            let data2 = [...subTasks];
            if (key in data2[tablekey])
            {
                let index0 = data2[tablekey][key].findIndex(item => item === dataTemp[tablekey][tablerow][0]);
                if (index0 !== -1)
                {
                    data2[tablekey][key].splice(index0, 1);
                    setSubTasks(data2)
                }
            }
            // let progResult = calculateProgress(tablekey, dataTemp[tablekey][tablerow][13], dataTemp[tablekey][tablerow][0], 0, "omit");
            //
            // dataTemp[tablekey][progResult[0]][8] = progResult[1];
            // if (parseInt(dataTemp[tablekey][progResult[0]][8])  === 100)
            // {
            //     dataTemp[tablekey][progResult[0]][3] = "Done";
            // }
            // changesId.push([tablekey,progResult[0]])

            let progResult = calculateProgress(tablekey,dataTemp[tablekey][tablerow][13], dataTemp[tablekey][tablerow][0], 0, "omit");
            if (progResult[0] !== "")
            {
                if (95.5 < progResult[1] && progResult[1] < 100) {
                    progResult[1] = 100
                } else {
                    progResult[1] = Math.floor(progResult[1]);
                }

                dataTemp[tablekey][progResult[0]][8] = progResult[1];
                if (parseInt( dataTemp[tablekey][progResult[0]][8]) === 100) {
                    dataTemp[tablekey][progResult[0]][3] = "Done";
                }

                let dateResult = hostIdDate(tablekey,dataTemp[tablekey][tablerow][13], dataTemp[tablekey][tablerow][0], "", "", "omit")
                if (dateResult[0][1]) {
                    dataTemp[tablekey][progResult[0]][11] = dateResult[0][0];
                    dataTemp[tablekey][progResult[0]][4] = dateResult[0][1];
                } else {
                    dataTemp[tablekey][progResult[0]][11] = "";
                    dataTemp[tablekey][progResult[0]][4] = "";
                }
                if (projData[tablekey][tablerow][0] <= 1000 && dataTemp[tablekey][progResult[0]][0] <= 1000){
                    changesId.push([tablekey,progResult[0]])
                }
            }
        }
        else
        {
            calculateProjProg(tablekey,tablerow )
        }


        if (projData[tablekey][tablerow][0] <= 1000)
        {
            let thisProjectId = [];
            for (let j=0; j < changesId.length; j++)
            {
                if (changesId[j][0] === tablekey)
                {
                    if (!(thisProjectId.includes(changesId[j][1])))
                    {
                        thisProjectId.push(changesId[j][1])
                    }
                }
            }
            let tasksArray = []
            for (let j=0; j < thisProjectId.length; j++ )
            {
                tasksArray.push(dataTemp[tablekey][thisProjectId[j]])
            }
            let updatedData = [projList[tablekey][1], projData[tablekey][tablerow][0], tasksArray]
            try {
                const response = await axios.post(auth().website + "/DeleteUpdate",
                    updatedData
                );

                dataTemp[tablekey].splice(tablerow, 1);
                setProjData(dataTemp)
                setSnackContent("Confirmation: The project task has been successfully deleted!")
                setSnackOpen(true);
                dataTemp = [...[]];
                changesId = [...[]];

                let data3 = [...projList]
                data3[tablekey][6] = (response.data)[0];
                data3[tablekey][7] = (response.data)[1];
                setProjList(data3);
            }
            catch (err)
            {
                setSnackContent("Warning: The project task deletion failed!")
                setSnackOpen(true);
                setError("Warning: The project task deletion failed!")
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        else
        {
            dataTemp[tablekey].splice(tablerow, 1);
            setProjData(dataTemp)
            setSnackContent("Confirmation: The project task has been successfully deleted!")
            setSnackOpen(true);
            dataTemp = [...[]];
            changesId = [...[]];
        }
    }

    function setStatedArrays(tableIndex, act){
        if (act === "delete"){
            let reducedArr = []
            reducedArr = [...projList];
            reducedArr.splice(tableIndex, 1);
            setProjList(reducedArr)

            reducedArr = [...projData];
            reducedArr.splice(tableIndex, 1);
            setProjData(reducedArr)

            reducedArr = [...subTasks];
            reducedArr.splice(tableIndex, 1);
            setSubTasks(reducedArr)

            reducedArr = [...maxOrder];
            reducedArr.splice(tableIndex, 1);
            setMaxOrder(reducedArr)

            reducedArr = [...tempId];
            reducedArr.splice(tableIndex, 1);
            setTempId(reducedArr)

            reducedArr = [...projProgress];
            reducedArr.splice(tableIndex, 1);
            setProjProgress(reducedArr)

            reducedArr = [...editedTasks];
            reducedArr.splice(tableIndex, 1);
            setEditedTasks(reducedArr)

        }
    }

    const deleteProject = async (tableIndex) => {
        //e.preventDefault()
        setError("");
        setProjectAnchorEl(null);
        if (projList[tableIndex][0] !== "")
        {
            try {
                const response = await axios.post(auth().website + "/DeleteProject",
                    [projList[tableIndex][1]]
                );
                if (response.data === "Group Delete"){
                    setStatedArrays(tableIndex, "delete")
                    setSnackContent("Confirmation: The project has been successfully deleted!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Warning: The project deletion failed!")
                    setSnackOpen(true);
                    setError("Warning: The project deletion failed!")
                }
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        else
        {
            setStatedArrays(tableIndex, "delete")
        }
    }

    const handleDisagree = () => {
        setDialogOpen(false);
    };

    const handleAgree = () =>
    {
        if (deleteInfo.taskId === -1)
        {
            deleteProject(deleteInfo.projectId)
        }
        else
        {
            preTaskDelete(deleteInfo.projectId, deleteInfo.taskId)
            deleteTask(deleteInfo.projectId, deleteInfo.taskId)
        }
        setDialogOpen(false);
    };





    function preOffRequest(staff, date)
    {
        let newDate = new Date(date);
        handleClickCell("","", staff, "");
        let offDates = nonFullDate0.filter( (t) => (format(newDate, 'yyyy-MM-dd') === format(t.date, 'yyyy-MM-dd')) );
        if (offDates.length > 0)
        {
            let index0 = "";
            for (let i= offDates.length -1; 0 <= i; i--)
            {
                index0 = nonFullDate0.findIndex(item => item === offDates[i]);
                nonFullDate0.splice(index0, 1);
            }
        }

        let newOffDate = new Date(newDate);
        newOffDate.setHours(EH,EM);
        excludeDate0.push({start: subDays(newOffDate, 1), end: newOffDate});
        dateAllocation(newDate,"","", 0, "offRequest");
        affectedDate("forward");
        setProjData(dataTemp);
    }

    const handleOffRequest = async (event, ind, staff, group) =>
    {
        let data = [...waitingOffDate];
        let index = data.findIndex(row => row.index === ind )
        let updatedData = [ind, group, event.target.value, "", ""]

        if (event.target.value === "Yes")
        {
            dataTemp = [...projData];
            changesId = [...[]];

            preOffRequest(staff, data[index].date);
            if (changesId.length > 0)
            {
                let projectArr = [];
                let taskArr = [];
                for (let j = 0; j < changesId.length; j++)
                {
                    let tablekey0 = changesId[j][0];
                    let tablerow0 = changesId[j][1];
                    projectArr.push(projList[tablekey0][1]);
                    taskArr.push([dataTemp[tablekey0][tablerow0][0], dataTemp[tablekey0][tablerow0][11], dataTemp[tablekey0][tablerow0][4]]);
                }

                updatedData = [ind, group, event.target.value, projectArr, taskArr];
            }
        }
        try {
            const response = await axios.post(auth().website + "/OffRequestUpdate", updatedData);
            if (event.target.value === "Yes"){
                let data0 = [...confirmedOffDate]
                data0.push({name: data[index].name, date: data[index].date, start:data[index].start , end:data[index].end })
                setConfirmedOffDate(data0)
                let key0 = [data[index].index, data[index].date, "Yes", data[index].name,data[index].start,data[index].end   ]
                setOffRecord([...offRecord, key0])

            }else{
                let data0 = [...declinedOffDate]
                data0.push({name: data[index].name, date: data[index].date, start:data[index].start , end:data[index].end })
                setDeclinedOffDate(data0)
            }
            data.splice(index, 1);
            setWaitingOffDate(data)
            data = [...selectedWait];
            index = data.findIndex(row => row.index === ind )
            data.splice(index, 1);
            setSelectedWait(data)
            dataTemp = [...[]];
            changesId = [...[]];

        } catch (err) {
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);
        }
    };







    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"

                hidden={value !== index + 1}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index + 1 && (
                    <Box sx={{ p: 3 }}>
                        <Box>{children}</Box>
                    </Box>
                )}
            </div>
        );
    }

    const [openElem, setOpenElem] = React.useState(null);
    const [openElemRow, setOpenElemRow] = React.useState(null);
    const WSH = 8 * 60 + 30
    const WEH = 17 * 60
    const WH = 8 * 60 + 30
    const SH = 8
    const SM = 30
    const EH = 17
    const EM = 0
    const [selectedWait, setSelectedWait] = useState([])
    const [selectedApproved, setSelectedApproved] =  useState([]);

    function daysInMonth(month, year) {
        switch (month) {
            case "January": // January
            case "March": // March
            case "May": // May
            case "July": // July
            case "August": // August
            case "October": // October
            case "December": // December
                return 31;
            case "April": // April
            case "June6": // June
            case "September": // September
            case "November": // November
                return 30;
            case "February": // February
                return (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) ? 29 : 28;
            default:
                return -1; // Invalid month
        }
    }

    const holiDays = tabsData()[2];
    const isWeekday = (date) => {
        const day = date.getDay();
        let doesExist  =  holiDays.includes(format(date, 'yyyy-MM-dd'))
        //let previousDate = date < new Date() ? true: false
        return day !== 4 && day !== 5 && !doesExist;
    };

    const [projectInvolved, setProjectInvolved] = useState([]);
    const [taskHolder, setTaskHolder] = useState(0);
    const [overdue, setOverdue] = useState(0)
    const [progress, setProgress] = useState(0);
    const [OffSiteDays, setOffSiteDays] = useState(0);
    const [OffDays, setOffDays] = useState(0);
    const [absenceDays, setAbsenceDays] = useState(0);
    const [tripDates,setTripDays] = useState(0);
    const [checkin, setCheckin] = useState([]);
    const [staffOffDate, setStaffOffDate] = useState([]);

    function staffPerformance (staffName, staffGroup)
    {
        let offDate = [];
        let offHours = [];

        const holiDays = tabsData()[2];
        let currentMonth = new Date().getMonth();
        const monthNumber = new Date().getMonth() + 1
        const year =  new Date().getFullYear();
        const month = new Date().toLocaleString('default', { month: 'long' });
        let daysNumber = daysInMonth(month, year )
        if (projList.length > 0){
            let temp = 0
            let today = new Date()
            let tasks = 0
            let taskHour = 0;
            let overdueTasks0 = 0
            let offsiteMinutes = 0
            let tripMinutes = 0
            let minute0 = 0
            let projectInvolved0 = []

            for (let j=0; j < projList.length; j++)
            {
                let tableRows = []
                tableRows = projData[j].filter(row => row[2] === staffName)
                if (tableRows.length > 0){
                    projectInvolved0.push("#" + projList[j][3] + "-" + projList[j][2] + "-" + projList[j][8])
                    for (let k = 0; k < tableRows.length; k++)
                    {
                        let date0 =  new Date(tableRows[k][11]);
                        let date1 = new Date(tableRows[k][4]);
                        let month0 = date0.getMonth();
                        let month1 = date1.getMonth();
                        let day0 =  date0.getDate();
                        let day1=  date1.getDate();

                        if (currentMonth === month0 || currentMonth === month1)
                        {
                            tasks += 1
                            if ( tableRows[k][1] === "Physical survey" || tableRows[k][1] === "Business Trip" )
                            {
                                if (month0 !== month1)
                                {
                                    if (currentMonth === month0)
                                    {
                                        minute0 = ((30 - day0 + 1) * parseInt(tableRows[k][6]))/ (day1 + (30 - day0) + 1)
                                        if (tableRows[k][1] === "Physical survey"){
                                            offsiteMinutes += minute0
                                        }else{
                                            tripMinutes += minute0
                                        }
                                    }else if (currentMonth === month1)
                                    {
                                        minute0 = ((day1) * parseInt(tableRows[k][6]))/ (day1 + (30 - day0) + 1)
                                        if (tableRows[k][1] === "Physical survey"){
                                            offsiteMinutes += minute0
                                        }else{
                                            tripMinutes += minute0
                                        }
                                    }
                                }else
                                {
                                    if (currentMonth === month0)
                                    {
                                        minute0 = parseInt(tableRows[k][6]);
                                        if (tableRows[k][1] === "Physical survey"){
                                            offsiteMinutes += minute0
                                        }else{
                                            tripMinutes += minute0
                                        }
                                    }
                                }
                            }

                            if(new Date(tableRows[k][4]) <= today)
                            {
                                taskHour += parseInt(tableRows[k][6])
                                if(tableRows[k][8] < 100){
                                    temp += 1
                                    overdueTasks0 += parseInt(tableRows[k][6])
                                }
                            }
                        }
                    }
                }
            }
            setProjectInvolved(projectInvolved0)
            setTaskHolder(tasks)
            setOverdue(temp)
            setProgress((overdueTasks0 / taskHour) * 100)
            setOffSiteDays((Math.floor((offsiteMinutes ) / (60 * 24))).toString()  + "." +   (((offsiteMinutes) % (60 * 24)).toString()).substring(0, 2));
            setTripDays((Math.floor((tripMinutes ) / (60 *24))).toString()  + "." +  (((tripMinutes) % (60 * 24)).toString()).substring(0, 2));
        }


        async function fetchData() {

            try {
                const response = await axios.post(auth().website + "/GetStaffOff",
                    [staffName, staffGroup, month, "false", ""]
                );
                let temp0 = (response.data)[0]
                let confirmedMinutes = 0
                for (let i=0; i< temp0.length; i++)
                {
                    let item = temp0[i]
                    let date0 = new Date(item[1])
                    if (item[2] === "Yes")
                    {
                        offDate.push(format(date0, 'yyyy-MM-dd'))
                        offHours.push({start:item[4] , end: item[5]})
                        confirmedMinutes += totalMinutes(item[5]) - totalMinutes(item[4]);
                    }
                }

                if (confirmedMinutes > 0){
                    setOffDays((Math.floor((confirmedMinutes ) / (60 * 8.5))).toString() + "." + (((confirmedMinutes) % (60 * 8.5)).toString()).substring(0, 2) );
                }else{
                    setOffDays(0)
                }

                let temp2 = (response.data)[1]
                temp2.sort((a, b) => (
                    a[1] > b[1] ? 1 : b[1] > a[1] ? -1 : 0)
                );
                setCheckin(temp2)

                let checkinDate = temp2.map(i => i[1]).flat();
                let absenceMinutes = 0
                let count = 1
                let dd = ""
                let workingDay = true;
                let doesExist = true;
                let index = ""
                let item = "";
                let workingMinute = ""
                let offMinute = ""
                while(count <= daysNumber)
                {
                    if (count < 10){
                        dd = "0" + count.toString()
                    }else{
                        dd = count.toString()
                    }
                    if (monthNumber < 10){
                        item = (year).toString() + "-0" + (monthNumber).toString()+ "-" + dd;
                    }else{
                        item = (year).toString() + "-" + (monthNumber).toString()+ "-" + dd;
                    }

                    workingDay = isWeekday(new Date(item))
                    if (workingDay && new Date(item) <= new Date())
                    {
                        doesExist = checkinDate.includes(item)
                        if (!doesExist){
                            if (!(offDate.includes(item))){
                                absenceMinutes += WH
                            }
                        }else
                        {
                            index = temp2.findIndex(temp => temp[1] === item);
                            if (index !== -1){
                                workingMinute = totalMinutes(temp2[index][3]) - totalMinutes(temp2[index][2])
                                if (Math.abs(workingMinute - WH) < 60){
                                    if (!(offDate.includes(item))){
                                        absenceMinutes += WH - workingMinute
                                    }else{
                                        index = offDate.findIndex(temp => temp[1] === item);
                                        if (index !== -1){
                                            offMinute = (totalMinutes(offHours[index].end) - totalMinutes(offHours[index].start))
                                        }
                                        if (workingMinute + offMinute < WH){
                                            absenceMinutes += WH - workingMinute - offMinute;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    count += 1
                }

                if (absenceMinutes > 0){
                    setAbsenceDays((Math.floor((absenceMinutes ) / (60 * 8.5))).toString() + "." +  (((absenceMinutes) % (60 * 8.5)).toString()).substring(0, 2) );
                }else{
                    setAbsenceDays(0)
                }
                setStaffOffDate(offDate);


            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        fetchData()
    }
    const [value, setValue] = React.useState({
        items: []
    });

    const [typeProject, setTypeProject] = useState([])
    const [projectIndexes, setProjectIndexes] = useState([])

    return (
        <MainContext.Provider
            value={{
                setError, projectInfoUpdate, projName, setProjName, projData, setProjData, projList, setProjList,
                onValUpdate, currentDate, mentionOption, setMentionOption, makeMention,mentionOpen, setMentionOpen,
                selected, setSelected, postContent, setPostContent,sendMention, statusOptions, temp, setTemp, setProjectData,
                handlePageNumber, pageNumber, drawerOpen, setDrawerOpen, type, setType, setProjectInfo
                , headCells, Category,open, setOpen,tabClick, Staff, setStaff, alertOpen, setAlertOpen, anchorEl, setAnchorEl,
                projectAnchorEl, setProjectAnchorEl, handleSnackClose, snackOpen, setSnackOpen,snackContent, setSnackContent,  editTask2,
                deleteInfo, setDeleteInfo, projectInfoUpdate2, elRefs, setElRefs, targetRefIndex, setTargetRefIndex, userInfo, setUserfInfo
                , openModal, setOpenModal, TemporaryProject, confirmedOffDate, setConfirmedOffDate, declinedOffDate, setDeclinedOffDate, waitingOffDate, setWaitingOffDate, offSiteDates, setOffSiteDates,
                timeSheet, setTimeSheet, totalHours, setTotalHour, overTime, setOvertime,checkInOutTable, checksInput, checkinBtn, checkoutBtn, checkinDate, setCheckinDate,changeID, setChangeID, setChecksInput, setCheckoutBtn, clickedRow, setClickedRow,
                dialogOpen, setDialogOpen, dialogContent, setDialogContent, criticalItem, setCriticalItem, highItem, setHighItem, lowItem, setLowItem, dialogOpenTime, setDialogOpenTime, dedicatedRow, setDedicatedRow,
                excludeDate, nonFullDate, excludeDate0, nonFullDate0, handleDateChange, handleDisagree, handleAgree, datePickerClick, handleOffRequest,
                openElem, setOpenElem,openElemRow, setOpenElemRow, WSH, WEH, WH, subTabs, selectedWait, setSelectedWait, selectedApproved, setSelectedApproved, taskHighlight, daysInMonth, isWeekday, offRecord, TabPanel,
                ProjectTitle, setProjectTitle, customerName, setCustomerName, taskTitle, setTaskTitle, SH, SM, EH, EM,
                staffPerformance, projectInvolved, taskHolder,overdue, progress,OffSiteDays, OffDays,absenceDays,tripDates, checkin, staffOffDate,
                excludeIntervals, excludeCritical, partialIntervals, partialCritical, value, setValue, tempId, setTempId, maxOrder, setMaxOrder, projProgress, saveProject, archiveProject, openH, setOpenH, openF, setOpenF,
                onholdProjData, onholdProjProgress, onholdProject, finishProjData, finishProjProgress, finishedProject,
                typeProject, setTypeProject, projectIndexes, setProjectIndexes, editedTasks, setEditedTasks, subTasks,
            }}
        >
            <MainLayout>
                <SidebarContainer>
                    <Sidebar/>
                </SidebarContainer>
                <PagesContainer>
                    <MainPage />
                </PagesContainer>
                <MentionDrawer />
            </MainLayout>
        </MainContext.Provider>
    );
}

export default TaskManagement;

