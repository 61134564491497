import React, {useContext, useEffect, useState} from "react";
import { ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";

import { CategoryScale } from "chart.js";
import {Box, Divider, Typography} from "@mui/material";
import Chart from "chart.js/auto";
import {Bar } from 'react-chartjs-2';
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import axios, {AxiosError} from "axios";
import MainContext from "../../context";
import {useAuthUser} from "react-auth-kit";
import {tabsData} from "../../components/data/tabsData.sidebar";
Chart.register(CategoryScale);
Chart.register([annotationPlugin], [ChartDataLabels]);

const StaffGuanttChart = () => {
    const [view, setView] = React.useState(ViewMode.Day);
    //const [state,setState] = React.useState(InitTasks()[0]);
    const [isChecked, setIsChecked] = React.useState(true);
    const auth = useAuthUser();
    let columnWidth = 60;
    if (view === ViewMode.Month) {
        columnWidth = 300;
    } else if (view === ViewMode.Week) {
        columnWidth = 250;
    }

    const { Staff, projData, projList, setError , daysInMonth, isWeekday, WH} = useContext(MainContext);
    //let tasks = [];
    //let temp = 0;
    let staffHours_current = [];
    let staffHours_next = [];
    let staffHours_work = [];
    const currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    // let currentYear = currentDate.getFullYear();
    const [state, setState] = useState({
        labels: [],
        datasets: [],
    });

    const [monthWorkingH, setMonthWorkingH] = useState(0)

    function detrmineTime(){
        staffHours_current = [];
        for (let i=0; i < Staff.length; i++)
        {
            let totalHours_current = 0
            let totalHours_next = 0
            for (let j=0; j < projList.length; j++)
            {
                let table = []
                table = projData[j].filter(row => row[2] === Staff[i][0])
                if (table.length > 0)
                {
                    for (let k=0; k < table.length; k++)
                    {
                        let date0 =  new Date(table[k][11]);
                        let date1 = new Date(table[k][4]);
                        let month0 = date0.getMonth();
                        let month1 = date1.getMonth();
                        let day0 =  date0.getDate();
                        let day1=  date1.getDate();
                        let year =  date0.getFullYear();
                        let monthName0 = date0.toLocaleString('default', { month: 'long' });
                        let daysNumber0 = daysInMonth(monthName0, year )
                        let monthName1 = date1.toLocaleString('default', { month: 'long' });
                        let daysNumber1 = daysInMonth(monthName1, year )
                        if (month0 !== month1)
                        {
                            if (currentMonth === month0)
                            {
                                totalHours_current += ((30 - day0 + 1) * parseInt(table[k][6]))/ (day1 + (30 - day0) + 1)
                                if (month1 === month0 + 1){
                                    totalHours_next += ((day1) * parseInt(table[k][6]))/ (day1 + (30 - day0) + 1)
                                }else if (month0 + 1 < month1) {
                                    totalHours_next += ((30) * parseInt(table[k][6]))/ (30 + (30 - day0) + 1)
                                }
                            }else if (currentMonth === month1)
                            {
                                totalHours_current += ((day1) * parseInt(table[k][6]))/ (day1 + (30 - day0) + 1)
                            }
                        }else
                        {
                            if (currentMonth === month0)
                            {
                                totalHours_current += parseInt(table[k][6]);
                            }else{
                                if(month0 === currentMonth + 1){
                                    totalHours_next += parseInt(table[k][6]);
                                }
                            }
                        }
                    }
                }
            }
            staffHours_current.push(Math.ceil(totalHours_current))
            staffHours_next.push(Math.ceil(totalHours_next))
        }

    }


    const holiDays = tabsData()[2];

    useEffect( () => {
        async function fetchData() {
            const month = new Date().toLocaleString('default', { month: 'long' });
            try {
                const response = await axios.post(auth().website + "/GetStafftimes",
                    [month]
                );
                staffHours_work = [];
                for (let i=0; i < Staff.length; i++)
                {
                    let index = (response.data).findIndex(row => row.staff === Staff[i][0] )
                    staffHours_work.push((response.data)[index].time)
                }
                detrmineTime()

                let state0 =
                    {
                        labels: Staff.map(i => i[0].substring(0, (i[0]).indexOf(" ")) ).flat(),
                        datasets: [
                            {
                                id: 1,
                                label: "Current month task",
                                backgroundColor: "#e7a25a",
                                borderColor: "#e7a25a",
                                borderWidth: 1,
                                data: staffHours_current,
                                barPercentage: 20,
                                barThickness: 20,
                            },
                            {
                                id: 2,
                                label: "Next month task",
                                backgroundColor: "#2a647b",
                                borderColor: "#2a647b",
                                borderWidth: 1,
                                data: staffHours_next,
                                barPercentage: 20,
                                barThickness: 20,
                            },
                            {
                                id: 3,
                                label: "Working hours",
                                backgroundColor: "#7a9dbb",
                                borderColor: "#7a9dbb",
                                borderWidth: 1,
                                data: staffHours_work,
                                barPercentage: 20,
                                barThickness: 20,
                            },
                        ],
                    }

                setState(state0)

            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        fetchData()

        const month = new Date().toLocaleString('default', { month: 'long' });
        const year =  new Date().getFullYear();
        const monthNumber = (new Date().getMonth()) + 1;
        let daysNumber = daysInMonth(month, year )

        let count = 1
        let dd = "";
        let item = "";
        let workingDay = true;
        let workHours = 0
        while(count <= daysNumber)
        {
            if (count < 10)
            {
                dd = "0" + count.toString()
            }else
            {
                dd = count.toString()
            }
            if (monthNumber < 10)
            {
                item = (year).toString() + "-0" + (monthNumber).toString()+ "-" + dd;
            }
            else
            {
                item = (year).toString() + "-" + (monthNumber).toString()+ "-" + dd;
            }

            count += 1
            workingDay = isWeekday(new Date(item))
            if (workingDay) {
                workHours += WH;
            }
        }
        setMonthWorkingH(Math.floor(workHours / 60))
    }, []);



    return (
        <>
            <Box sx = {{width: "100%",textAlign: "center"}}>
                <Divider variant="middle"  sx = {{border: "2px solid gray"}} />
            </Box>
           <Box sx = {{ mt: 7}}>
                    <Bar id="barchart"
                         datasetIdKey='id'
                         data={state}
                        // width={600}
                         height={130}
                         options={{
                             responsive: true,
                             maintainAspectRatio: true,
                             scales: {
                                 x: {
                                     display: true,
                                     title: {
                                         display: false,
                                         text: 'Staff Hours'
                                     },
                                 },
                             },

                             plugins: {
                                 datalabels: {
                                     display: false
                                 },
                                 legend: {
                                     display: true
                                 },
                                 title: {
                                     display: true,
                                     text: "Man hours",
                                     padding: {
                                         bottom: 30
                                     },
                                     weight: "bold",
                                     color: "#4e4d4ddb",
                                     font: {
                                         size: 13
                                     },
                                     align: "start"
                                 },
                             },

                             annotations: {
                                 currentMonthLine: {
                                     type: "line",
                                     scaleID: "y",
                                     borderWidth: 3,
                                     borderColor: "#e7a25a",
                                     value: monthWorkingH,
                                     borderDash: [8, 8],
                                     label: {
                                         font: {
                                             weight: "normal"
                                         },
                                         rotation: "auto",
                                         enabled: true
                                     }
                                 },

                                 // nextMonthLine: {
                                 //     type: "line",
                                 //     scaleID: "y",
                                 //     borderWidth: 1,
                                 //     borderColor: "#2a647b",
                                 //     value: 160,
                                 //     borderDash: [8, 8],
                                 //     label: {
                                 //         font: {
                                 //             weight: "normal"
                                 //         },
                                 //         rotation: "auto",
                                 //         enabled: true
                                 //     }
                                 // },
                             }
                         }}
                    />
                </Box>
        </>
    );
};
export default StaffGuanttChart;

