import React from "react";
import { useState, useContext, useEffect, useRef} from "react";
import {Box, Button, Divider, Paper, Typography} from "@mui/material";
import MainContext from "../../context";
import {TableRows} from "./index";
import {grey} from "@mui/material/colors";
import { Overlay } from 'react-portal-overlay';
import Grid from "@mui/material/Unstable_Grid2";
import {useAuthUser} from "react-auth-kit";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {addDays, format} from 'date-fns';
function createArray(N) {
    return [...Array(N).keys()].map(i => i );
}

const TaskPage = ({pageTitle}) => {
    const {typeProject, setTypeProject, projectIndexes,setProjectIndexes,   open, WEH, SH, SM, EH, EM, WH, customerName, ProjectTitle, deleteProject, temp, setTemp, setProjectData, Staff, openModal, setOpenModal, projectInfoUpdate, targetRefIndex, elRefs, type, projData, projList, isWeekday} = useContext(MainContext);
    const auth = useAuthUser();
    const originalRef = useRef(null);


    const [filteredStaff, setFilteredStaff] =useState("")
    const [filterDateS, setFilterDateS ] = useState("")
    const [filterDateE, setFilterDateE ] = useState("")
    const [staffTime, setStaffTime ] = useState("")
    const [allProjectInd, setAllProjectInd ] = useState("")


    useEffect(() => {
        let  targetRef = originalRef;
        if (targetRefIndex !== ""){
            targetRef = elRefs[targetRefIndex];
        }
         targetRef.current.scrollIntoView({
             behavior: 'smooth',
             block: 'start',
         });

        let projType = []
        let projIndexes = []
        if (type === "In-progress")
        {
            projType = [...projList]
            projIndexes = Array(projList.length).fill(0).map((_, index)=> index);
        }
        else
        {
            let indices = []
            for(let i=0; i < type.length;i++) {
                if (type[i] === "-"){
                    indices.push(i);
                    break
                }
            }
            for(let i = type.length -1; i > -1;i--) {
                if (type[i] === "-"){
                    indices.push(i);
                    break
                }
            }
            let str1 = type.substring(1, indices[0]);
            let str2 = type.substring(indices[0] + 1, indices[1])
            let str3 = type.substring(indices[1] + 1, type.length)
            projType = projList.filter(row => row[2] === str2 && row[3] === str1 && row[8] === str3 )
            for (let i=0; i < projType.length; i++ )
            {
                let ind = projList.findIndex(row => row[1] === projType[i][1] )
                projIndexes.push(ind)
            }
        }

        setProjectIndexes(projIndexes)
        setTypeProject(projType)
    }, []);

    const closeModal = () => {
        setOpenModal(false);
        setTemp({["index"]:"", ["number"]: "", ["title"]: "",["customer"]:"", ["manager"]: [], ["code"]:"", ["deadline"]: ""});
    }

    const TempProject = () =>{
        setOpenModal(true);
        setTemp({["index"]:"",["number"]: "",["title"]: "",["customer"]:"", ["manager"]: [], ["code"]:"", ["deadline"]: ""});
    }

    let isDisabled = true;
    if (auth().user_record[3] === "No" && auth().user_record[5] === "No"){
        isDisabled = false;
    }

    const setFiltered = (event, filter) => {
        let projType = []
        let projIndexes = []
        if (type === "In-progress")
        {
            let index2 = -1
            for(let i = 0; i < projList.length; i++ )
            {
                if (filter === "filterStaff")
                {
                    let firstDate = format((filterDateS !== ""? filterDateS : new Date("2020-12-30")) , 'yyyy-MM-dd')
                    let secondDate = format((filterDateE !== ""? filterDateE : new Date("2026-12-30")) , 'yyyy-MM-dd')
                    index2 = projData[i].findIndex(row => ( (event.target.value !== "" ? row[2] === event.target.value : true)  && ( (row[11] !== "" ? firstDate <= format(new Date(row[11]), 'yyyy-MM-dd') &&  format(new Date(row[11]), 'yyyy-MM-dd') < secondDate: true)   ) ))
                }
               else if(filter === "firstDate")
               {
                   let firstDate = format(new Date("2020-12-30") , 'yyyy-MM-dd')
                   let secondDate = format((filterDateE !== "" ? filterDateE : new Date("2026-12-30")) , 'yyyy-MM-dd')
                   if (event !== null){
                       firstDate = format(event , 'yyyy-MM-dd')
                   }
                   index2 = projData[i].findIndex(row => ( (filteredStaff !== "" ? row[2] === filteredStaff : true) && ( (row[11] !== "" ? firstDate <= format(new Date(row[11]), 'yyyy-MM-dd') && format(new Date(row[11]), 'yyyy-MM-dd') < secondDate: true) || (row[4] !== "" ? firstDate < format(new Date(row[4]), 'yyyy-MM-dd') && format(new Date(row[4]), 'yyyy-MM-dd') <= secondDate: true ) || ((row[11] !== "" && row[4] !== "") ? format(new Date(row[11]), 'yyyy-MM-dd') <= firstDate && secondDate <= format(new Date(row[4]), 'yyyy-MM-dd'): true ) )))
               }else{
                   let secondDate = format(new Date("2026-12-30") , 'yyyy-MM-dd')
                   let firstDate = format((filterDateS !== ""? filterDateS : new Date("2020-12-30")) , 'yyyy-MM-dd')
                   if (event !== null)
                   {
                       secondDate = format(event , 'yyyy-MM-dd')
                   }
                   index2 = projData[i].findIndex(row => ( (filteredStaff !== "" ? row[2] === filteredStaff : true) && ( (row[11] !== "" ? firstDate <= format(new Date(row[11]), 'yyyy-MM-dd') && format(new Date(row[11]), 'yyyy-MM-dd') < secondDate: true) || (row[4] !== "" ? firstDate < format(new Date(row[4]), 'yyyy-MM-dd') && format(new Date(row[4]), 'yyyy-MM-dd') <= secondDate: true ) || ((row[11] !== "" && row[4] !== "") ? format(new Date(row[11]), 'yyyy-MM-dd') <= firstDate && secondDate <= format(new Date(row[4]), 'yyyy-MM-dd'): true ) )))
               }
                if (index2 !== -1)
                {
                    projType.push(projList[i])
                    projIndexes.push(i)
                }
            }
            setProjectIndexes(projIndexes)
            setTypeProject(projType)
        }
        if (filter === "filterStaff")
        {
            setFilteredStaff(event.target.value)

            if (event.target.value !== "" || filterDateS !== "" || filterDateE !== "")
            {
                let ProjectInd = []
                for (let i=0; i < projIndexes.length; i++)
                {
                    let indexes = filteredTasksIndex(projIndexes[i],event.target.value, filterDateS, filterDateE )
                    ProjectInd.push(indexes)
                }
                setAllProjectInd(ProjectInd)
                if (event.target.value !== "" && filterDateS !== "" && filterDateE !== ""){
                    staffOccupiedTime(ProjectInd, projIndexes, filterDateS, filterDateE)
                }else{
                    setStaffTime("")
                }
            }


        }else if (filter === "firstDate")
        {
            if (event !== null){
                setFilterDateS(event)
                if (filteredStaff !== "" || event !== "" || filterDateE !== "")
                {
                    let ProjectInd = []
                    for (let i=0; i < projIndexes.length; i++)
                    {
                        let indexes = filteredTasksIndex(projIndexes[i],filteredStaff, event, filterDateE )
                        ProjectInd.push(indexes)
                    }

                    setAllProjectInd(ProjectInd)
                    if (filteredStaff !== "" && event !== "" && filterDateE !== ""){
                        staffOccupiedTime(ProjectInd, projIndexes, event, filterDateE)
                    }
                }
            }else{
                setFilterDateS("")
                setStaffTime("")
            }
        }else
        {
            if (event !== null){
                setFilterDateE(event)
                if (filteredStaff !== "" || filterDateS !== "" || event !== "")
                {
                    let ProjectInd = []
                    for (let i=0; i < projIndexes.length; i++)
                    {
                        let indexes = filteredTasksIndex(projIndexes[i],filteredStaff, filterDateS, event )
                        ProjectInd.push(indexes)
                    }

                    setAllProjectInd(ProjectInd)
                    if (filteredStaff !== "" && filterDateS !== "" && event !== ""){
                        staffOccupiedTime(ProjectInd, projIndexes, filterDateS, event)
                    }

                }
            }else{
                setFilterDateE("")
                setStaffTime("")
            }
        }
    }

    function filteredTasksIndex(index, staff, date1, date2)
    {
        let indexes = []

        let firstDate = format((date1 !== ""? date1 : new Date("2020-12-30")) , 'yyyy-MM-dd')
        let secondDate = format((date2 !== ""? date2 : new Date("2026-12-30")) , 'yyyy-MM-dd')

        let row = ""
        let startDate = ""
        let endDate = ""
        for (let i = 0; i < projData[index].length ; i++)
        {
            row =  projData[index][i]
            if (row[11] !== "")
            {
                startDate = format(new Date(row[11]), 'yyyy-MM-dd')
            }
            if (row[4] !== "")
            {
                endDate = format(new Date(row[4]), 'yyyy-MM-dd')
            }
            if ( (staff !== "" ? row[2] === staff : true) && ( (row[11] !== "" ? firstDate <= startDate && startDate < secondDate: true) || (row[4] !== ""? firstDate < endDate && endDate <= secondDate: true) || ((row[4] !== "" && row[11] !== "") ? (startDate <= firstDate && secondDate <= endDate) : true)) )
            {
                indexes.push(i)
            }
        }

        return indexes;
    }

    function staffOccupiedTime(allIndex, projInds, date1, date2)
    {

        let projectDates = []
        for (let i=0; i < allIndex.length; i++)
        {
            let indProj = projInds[i]
            for(let j=0; j < allIndex[i].length; j++)
            {
                let taskInd = allIndex[i][j]
                if (projData[indProj][taskInd][11] !== ""){
                    projectDates.push({"startDate": projData[indProj][taskInd][11], "endDate": projData[indProj][taskInd][4], "owner": projData[indProj][taskInd][2], "task": projData[indProj][taskInd][1], "proj": projList[indProj][2] })
                }
            }
        }
        projectDates.sort((a, b) => (
            a.startDate > b.startDate ? 1 : b.startDate > a.startDate ? -1 : 0)
        );

        let filterdIndex = []
        let intTime = 0;
        for (let i = 0; i < projectDates.length; i++)
        {
            let ind = (projectDates.slice(0, i)).findIndex(row => row.startDate <= projectDates[i].startDate && projectDates[i].endDate <= row.endDate )
            if (ind === -1)
            {
                filterdIndex.push(i)
                let firstDate = date1 !== ""? date1 : new Date("2020-12-30");
                let secondDate = date2 !== ""? date2 : new Date("2026-12-30");

                firstDate.setHours(SH,SM)
                secondDate.setHours(EH,EM)
                let max0 = projectDates[i].startDate;
                let secondItem = format(firstDate, 'yyyy-MM-dd kk:mm')
                if (max0 < secondItem ) max0 = secondItem;
                max0 = new Date(max0)

                let min0 = projectDates[i].endDate;
                secondItem = format(secondDate, 'yyyy-MM-dd kk:mm')
                if (secondItem < min0 ) min0 = secondItem;
                min0 = new Date(min0)

                let date = addDays(max0 , 1)
                let totlalMinS = max0.getHours() * 60 + max0.getMinutes()
                let totlalMinE = min0.getHours() * 60 + min0.getMinutes()

                if (format(max0 , 'yyyy-MM-dd') <= format(min0, 'yyyy-MM-dd'))
                {
                    if (format(min0, 'yyyy-MM-dd') !== format(max0, 'yyyy-MM-dd'))
                    {
                        while( format(date, 'yyyy-MM-dd')  !== format(min0, 'yyyy-MM-dd')  )
                        {
                            if (isWeekday(date)){
                                intTime += WH
                            }
                            date = addDays(date , 1)
                        }
                        intTime += (WEH - totlalMinS) + (totlalMinE - WH)
                    }else{
                        intTime += (totlalMinE - totlalMinS)
                    }

                }
            }
        }


        let totalTime = 0
        if (format(date1 , 'yyyy-MM-dd') <= format(date2, 'yyyy-MM-dd')) {
            let date = date1
            while (format(date, 'yyyy-MM-dd') <= format(date2, 'yyyy-MM-dd')) {
                if (isWeekday(date)) {
                    totalTime += WH
                }
                date = addDays(date, 1)
            }
        }
        let remainTime = totalTime - intTime
        setStaffTime((Math.floor((remainTime ) / 60)).toString() + ":" +  ((remainTime) % 60).toString() + " hr(s) from " + (Math.floor((totalTime ) / 60)).toString() + ":" +  ((totalTime) % 60).toString() + " hr(s)")

        //setSnackContent("Total occupied time for staff " + filteredStaff + " in selected date interval is : " + hours.toString() + ":" +  minutes.toString() + " ; and there is just " + remainTime + " for new task allocation")
        //setSnackOpen(true);

    }

    return(
        <>
            <Box
                sx={{
                    height: "100vh",
                    overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: "#F0F2F3",
                }}
            >
                <Box sx = {{mb: 2, ml:5, mr:5,mt: 2}} ref={originalRef}>
                    <h3 className= "headerClass"  > {pageTitle} </h3>
                    <Box sx = {{textAlign: "right", display: "inline-block", float: "right" }} id = "search">
                        <Paper
                            component="form"
                            sx={{  display: 'flex', alignItems: 'left', width:250, textAlign: "left", direction: "ltr", }}
                        >
                            <Button
                                onClick={TempProject}
                                sx={{ width:170, border: "unset"}}
                                disabled={!isDisabled}
                            >
                                + Add new project
                            </Button>
                        </Paper>
                    </Box>
                </Box>
                <Divider variant="middle"  sx={{ height: 2,  mt: 1,  backgroundColor:grey[400]}} />
                <Box
                   sx = {{
                   height: "100%",
                   overflowY: "scroll",
                   display: 'flex',
                   flexDirection: 'column',
                   fontSize: "1.2rem",
                   mb: 2, ml:4, mr:4,
                    }}
                   id = "taskPage"
                >
                    {type === "In-progress" ?
                        <Box sx={{mt:1, backgroundColor: "#fbfbfb", borderRadius:"10px", padding: 1}}>
                            <Grid container  sx = {{fontSize: "0.85rem", textAlign: "left", mt: 1,}} color = {grey[600] }>
                                <Grid xs= {3}>
                                    <Typography variant = "caption"  sx ={{fontWeight : 700, display: "inline-block", pl: 2, }}> Filter by Staff:</Typography>
                                </Grid>
                                <Grid xs= {3}>
                                    <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}>  Start Date:</Typography>
                                </Grid>
                                <Grid xs= {3}>
                                    <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}>  End Date:</Typography>
                                </Grid>
                                <Grid xs= {3}>
                                    <Typography variant = "caption" sx ={{fontWeight : 700 , pl: 2,}}> Available time: </Typography>
                                </Grid>
                            </Grid>

                            <Grid container  sx = {{fontSize: "0.85rem", textAlign: "left", mb: 1,}} color = {grey[600] }>
                                <Grid xs= {3}>
                                    <select
                                        value={filteredStaff}
                                        name = "filterStaff"
                                        onChange=  {(event) => setFiltered(event, "filterStaff")}
                                        className="selectFilter"
                                    >
                                        <option value="">  </option>
                                        {Staff.map((member, ind0) => (
                                            <option key={ind0.toString()} value={member[0]}>
                                                {member[0].substring(0, (member[0]).indexOf(" "))}
                                            </option>
                                        ))}
                                    </select>
                                </Grid>
                                <Grid xs= {3}>
                                    <DatePicker
                                        selected= {filterDateS !== "" ? filterDateS : ""}
                                        name="firstDate"
                                        className = "selectFilter"
                                        onChange=  {(date) => setFiltered(date, "firstDate")}
                                        dateFormat="MM/dd/yyyy"
                                        isClearable
                                    />
                                </Grid>
                                <Grid xs= {3}>
                                    <DatePicker
                                        selected= {filterDateE !== "" ? filterDateE : ""}
                                        name="secondDate"
                                        className = "selectFilter"
                                        onChange=  {(date) => setFiltered(date, "secondDate")}
                                        dateFormat="MM/dd/yyyy"
                                        isClearable
                                    />
                                </Grid>
                                <Grid xs= {3}>
                                    <Typography variant = "caption" sx ={{fontWeight : 700 , pl: 2,}}> {staffTime} </Typography>
                                </Grid>
                            </Grid>

                        </Box>
                        :
                        null
                    }

                    <Box sx={{mt: 4}}>
                        {typeProject.length > 0 ?
                            typeProject.map((item, key) =>
                            {
                                let index = projectIndexes[key]
                                let indexes = [];
                                if (open[index]){
                                    if (allProjectInd !== "")
                                    {
                                        indexes = allProjectInd[key]
                                    }else{
                                        if (projData[index]){
                                            indexes = createArray(projData[index].length);
                                        }

                                    }
                                }

                                return(
                                    <Box
                                        key = {key}
                                        id="projectContainer"
                                        sx = {{ backgroundColor: "white", borderRadius: "15px", mb: 3 }} >
                                        <Box key={key} sx={{ mt:2, ml:2, mr: 2}}  ref={elRefs[key]}>
                                            <TableRows
                                                index = {index}
                                                indexes = {indexes}
                                                item = {item}
                                            />
                                        </Box>
                                    </Box>
                                );
                            })
                            :
                            <Box sx = {{ margin: "15% auto", boxShadow: "0 0 10px 5px #0000002e",maxWidth: "600px", textAligh: "center", borderRadius: "3px"}} >
                                <Box sx = {{textAlign: "center", p : 3}}>
                                    <Typography  > There is no in-progress project! </Typography>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>

            <Overlay open={openModal} onClose={() => setOpenModal(false)} >
                <Box className = "modal" id = "projectDefinition" aria-hidden="false" aria-modal="true" sx={{minWidth: 500, minHeight: 320, }}>
                    <Box sx={{mt: 3}}>
                        <Box sx={{ml: 3, mb: 2, }}>  <h5 style={{ fontWeight: 700, textAlign: "left", color: "#031830"}} > Project Information</h5>
                        </Box>
                        <Box sx={ {m:3}}>
                            <Grid container sx={{mb:1}}>
                                <Grid xs={4}>
                                    <Typography variant="caption" sx={{fontWeight: 700}}>Title:</Typography>
                                </Grid>
                                <Grid xs={8}>
                                    <select
                                        name="title"
                                        value={temp.title}
                                        onChange=  {(event) => setProjectData(event, "title")}
                                        className="selectOption"
                                        style={{textAlign:"left", width: "100%", padding: 6}}
                                    >
                                        <option value="">  </option>
                                        {ProjectTitle.map((title, index) => (
                                            <option key={title} value={title}>
                                                {title}
                                            </option>
                                        ))}
                                    </select>
                                </Grid>
                            </Grid>
                            <Grid container sx={{mb:1}}>
                                <Grid xs={4}>
                                    <Typography variant="caption" sx={{fontWeight: 700}}> Customer: </Typography>
                                </Grid>
                                <Grid xs={8}>
                                    <select
                                        name="customer"
                                        value={temp.customer}
                                        onChange=  {(event) => setProjectData(event, "customer")}
                                        className="selectOption"
                                        style={{textAlign:"left", width: "100%", padding: 6}}
                                    >
                                        <option value="">  </option>
                                        {customerName.map((customer, index) => (
                                            <option key={customer} value={customer}>
                                                {customer}
                                            </option>
                                        ))}

                                    </select>

                                </Grid>
                            </Grid>
                            <Grid container sx={{mb:1}}>
                                <Grid xs={4}>
                                    <Typography variant="caption" sx={{fontWeight: 700}}> Manager:</Typography>
                                </Grid>
                                <Grid xs={8}>
                                    <Autocomplete
                                        multiple
                                        id="fixed-tags-demo"
                                        value= {temp.manager}
                                        onChange=  {(event, newValue) => setProjectData(newValue, "manager")}
                                        className="multiSelect"
                                        options={Staff.map(i => i[0])}
                                        //getOptionLabel={option => option}
                                        getOptionLabel={option => option.substring(0, (option).indexOf(" "))}
                                        // style = {{width : "100%"}}
                                        ListboxProps={{
                                            sx: { fontSize: 10, padding: 0, backgroundColor: "#ffffff", border: "1px solid #8080807a" },
                                        }}
                                        sx={{
                                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                fontSize: 10,
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />

                                </Grid>
                            </Grid>
                            <Grid container sx={{mb:1}}>
                                <Grid xs={4}>
                                    <Typography variant="caption" sx={{fontWeight: 700}}> Code: </Typography>
                                </Grid>
                                <Grid xs={8}>
                                    <input
                                        placeholder="Requested from Airworthiness staff"
                                        //defaultValue=" Reqiested from Airworthiness staff "
                                        type="text"
                                        value={temp.code}
                                        onChange=  {(event)  => setProjectData(event, "code")}
                                        name="code"
                                        className="selectOption projectClass"
                                        style={{textAlign:"left", width: "100%", paddingLeft: "10px"}}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container sx={{mb:1}}>
                                <Grid xs={4} >
                                    <Typography variant="caption" sx={{fontWeight: 700}}> Deadline: </Typography>
                                </Grid>
                                <Grid xs={8}>
                                    <DatePicker
                                        selected={temp.deadline}
                                        name="deadline"
                                        onChange=  {(date) => setProjectData(date, "deadline")}
                                        dateFormat="MM/dd/yyyy"
                                        className = "selectOption myClass projectClass"
                                        sx = {{width : "100%", fontWeight: 400, paddingLeft: "10px"}}
                                    />

                                </Grid>
                            </Grid>
                        </Box>
                        <Box id="confirm" sx={{backgroundColor: "#f0f3fc", mt: 5, textAlign: "right"}}>
                            <Button
                                onClick={(event) => projectInfoUpdate(event)}
                            >
                                Save
                            </Button>
                            <Button
                                sx={{ml:3}}
                                onClick={(event) => deleteProject(event)}
                            >
                                Delete
                            </Button>
                            <Button
                                sx={{ml:3, mr: 2}}
                                onClick={(event) => closeModal()}
                            >
                                Cancel
                            </Button>

                        </Box>
                    </Box>
                </Box>
            </Overlay>

        </>
    );
}
export default TaskPage;
