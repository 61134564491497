import {Box, Button, Divider} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, {useContext, useState, useRef} from "react";
import axios, {AxiosError} from "axios";
import MainContext from "../../context";
import {useAuthUser} from "react-auth-kit";
import {grey} from "@mui/material/colors";
//import Select, { components } from "react-select";

const ProjNewConf = ({pageTitle}) =>{
    const auth = useAuthUser()
    const {setError, setSnackContent, setSnackOpen, ProjectTitle, setProjectTitle, customerName, setCustomerName, taskTitle, setTaskTitle } = useContext(MainContext);

    const [newItems, setNewItems] = useState({
        task: "",
        category: "",
        customer: "",
    })


    const setNewConf = (event) => {
        setNewItems({...newItems, [event.target.name]: event.target.value});
    }
    const [preItem, setPreItem] = useState({
        task: "",
        cat: "",
        customer: "",
    })
    const selectedPreItem = (event) => {
        setPreItem({...preItem, [event.target.name]: event.target.value});
    }

    const editCustomer = async(event) =>{
        event.preventDefault()
        setError("");
        let newCustomer = (newItems.customer).charAt(0).toUpperCase() + (newItems.customer).slice(1);
        let preCutomer = preItem.customer;
        let index0 = customerName.findIndex(item => item === preCutomer);
        if (newCustomer !== "" && preCutomer !== "")
        {
            try {
                const response = await axios.post(auth().website + "/EditPreCustomer",
                    [preCutomer, newCustomer]
                );
                if (response.data === "customer updated"){
                    if (index0 !== -1){
                        let data = [...customerName];
                        data[index0] = newCustomer
                        setCustomerName(data.sort())
                    }
                    setSnackContent("Confirmation: customer updated successfully!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Warning: customer update was not successful!")
                    setSnackOpen(true);
                }
                setNewItems({...newItems, ["customer"]: ""});
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setSnackContent("Warning: selected customer or new customer information is not valid!")
            setSnackOpen(true);
        }

    }

    const deleteCustomer = async(event) =>{
        event.preventDefault()
        setError("");
        let preCutomer = preItem.customer;
        let index0 = customerName.findIndex(item => item === preCutomer)
        if(preCutomer !== "")
        {
            try {
                const response = await axios.post(auth().website + "/RemovePreCustomer",
                    [preCutomer]
                );
                if (response.data === "customer deleted"){
                    if (index0 !== -1){
                        let data = [...customerName];
                        data.splice(index0, 1);
                        setCustomerName(data.sort())
                    }
                    setSnackContent("Confirmation: customer deleted successfully!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Warning: customer deletion was not successful!")
                    setSnackOpen(true);
                }
                setNewItems({...newItems, ["customer"]: ""});
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setSnackContent("Warning: customer deletion was not successful!")
            setSnackOpen(true);
        }

    }

    const InsertCustomer = async(event) =>{
        event.preventDefault()
        setError("");
        let customer = (newItems.customer).charAt(0).toUpperCase() + (newItems.customer).slice(1);
        if(customer !== ""){
            try {
                const response = await axios.post(auth().website + "/RegisterNewCustomer",
                    [customer]
                );
                if (response.data === "customer registered"){
                    let data = [...customerName];
                    data.push(customer)
                    setCustomerName(data.sort())

                    setSnackContent("Confirmation: New customer registered successfully!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Warning: customer registration was not successful!")
                    setSnackOpen(true);
                }
                setNewItems({...newItems, ["customer"]: ""});
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setSnackContent("Warning: customer information is not valid!")
            setSnackOpen(true);
        }
    }

    const editProject = async(event) =>{
        event.preventDefault()
        setError("");
        let newCat = (newItems.category).charAt(0).toUpperCase() + (newItems.category).slice(1);
        let preCat = preItem.cat;
        let index0 = ProjectTitle.findIndex(item => item === preCat);
        if (newCat !== "" && preCat !== "")
        {
            try {
                const response = await axios.post(auth().website + "/EditPreCategory",
                    [preCat,newCat ]
                );
                if (response.data === "category updated"){
                    if (index0 !== -1){
                        let data = [...ProjectTitle];
                        data[index0] = newCat
                        setProjectTitle(data.sort())
                    }
                    setSnackContent("Confirmation:  category updated successfully!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Warning: category update was not successful!")
                    setSnackOpen(true);
                }
                setNewItems({...newItems, ["category"]: ""});
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setSnackContent("Warning: selected category or new category information is not valid!")
            setSnackOpen(true);
        }


    }

    const deleteProject = async(event) =>{
        event.preventDefault()
        setError("");
        let preCat = preItem.cat;
        let index0 = ProjectTitle.findIndex(item => item === preCat);
        if (preCat !== "")
        {
            try {
                const response = await axios.post(auth().website + "/RemovePreCategory",
                    [preCat]
                );
                if (response.data === "category deleted"){

                    if (index0 !== -1){
                        let data = [...ProjectTitle];
                        data.splice(index0, 1);
                        setProjectTitle(data.sort())
                    }
                    setSnackContent("Confirmation:  category deleted successfully!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Warning: category deletion was not successful!")
                    setSnackOpen(true);
                }
                setNewItems({...newItems, ["category"]: ""});
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setSnackContent("Warning: category deletion was not successful!")
            setSnackOpen(true);
        }

    }

    const InsertProject = async(event) =>{
        event.preventDefault()
        setError("");
        let category = (newItems.category).charAt(0).toUpperCase() + (newItems.category).slice(1);
        if(category !== ""){
            try {
                const response = await axios.post(auth().website + "/RegisterCategory",
                    [category]
                );
                if (response.data === "category registered"){
                    let data = [...ProjectTitle];
                    data.push(category)
                    setProjectTitle(data.sort())

                    setSnackContent("Confirmation: New category registered successfully!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Warning: category registration was not successful!")
                    setSnackOpen(true);
                }
                setNewItems({...newItems, ["category"]: ""});
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setSnackContent("Warning: project information is not valid!")
            setSnackOpen(true);
        }
    }

    const editTask = async(event) =>{
        event.preventDefault()
        setError("");
        let newTask = (newItems.task).charAt(0).toUpperCase() + (newItems.task).slice(1);
        let preTask = preItem.task;
        let index0 = taskTitle.findIndex(item => item === preTask);
        if(newTask !== "" && preTask !== "")
        {
            try {
                const response = await axios.post(auth().website + "/EditPreTask",
                    [preTask, newTask]
                );
                if (response.data === "task updated"){
                    if (index0 !== -1){
                        let data = [...taskTitle];
                        data[index0] = newTask
                        setTaskTitle(data.sort())
                    }
                    setSnackContent("Confirmation: task updated successfully!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Warning: task update was not successful!")
                    setSnackOpen(true);
                }
                setNewItems({...newItems, ["task"]: ""});
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setSnackContent("Warning: selected task or new task information is not valid!")
            setSnackOpen(true);
        }
    }

    const deleteTask = async(event) =>{
        event.preventDefault()
        setError("");
        let preTask = preItem.task;
        let index0 = taskTitle.findIndex(item => item === preTask)
        console.log("preTask")
        console.log(preTask)
        console.log(index0)
        if (preTask !== ""){
            try {
                const response = await axios.post(auth().website + "/RemovePreTask",
                    [preTask]
                );
                if (response.data === "task deleted"){
                    if (index0 !== -1){
                        let data = [...taskTitle];
                        data.splice(index0, 1);
                        setTaskTitle(data)
                    }
                    setSnackContent("Confirmation:  task deleted successfully!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Warning: task deletion was not successful!")
                    setSnackOpen(true);
                }
                setNewItems({...newItems, ["task"]: ""});
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setSnackContent("Warning: task deletion was not successful!")
            setSnackOpen(true);
        }
    }

    const InsertTask = async(event) =>{
        event.preventDefault()
        setError("");
        let task = (newItems.task).charAt(0).toUpperCase() + (newItems.task).slice(1);
        if (task !== "")
        {
            try {
                const response = await axios.post(auth().website + "/RegisterNewTask",
                    [task]
                );
                if (response.data === "task registered"){
                    let data = [...taskTitle];
                    data.push(task)
                    setTaskTitle(data.sort())

                    setSnackContent("Confirmation: New task registered successfully!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Warning: task registration was not successful!")
                    setSnackOpen(true);
                }
                setNewItems({...newItems, ["task"]: ""});
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setSnackContent("Warning: task information is not valid!")
            setSnackOpen(true);
        }
    }

    return(
            <Box
                sx={{
                    height: "100vh",
                    overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: "#F0F2F3",
                }}
                id = "mReport"
            >
                <Box sx = {{mb: 2, ml:5, mr:5,mt: 2}}>
                    {/*<h3 className= "headerClass"  > {pageTitle} </h3>*/}
                    <h3 className= "headerClass" > Insert New Inputs </h3>
                </Box>
                <Divider variant="middle"  sx={{ height: 2,  mt: 1,  backgroundColor:grey[400]}} />
                <Box
                    sx = {{
                        height: "100%",
                        overflowY: "scroll",
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: "1.2rem",
                        mb: 2, ml:4, mr:4,
                        scrollbarWidth: "none",
                    }}
                >
                    <Box sx={{bgcolor:'background.paper', backgroundColor: "unset", height: "100%",}}  className = "adminPage">
                        <Grid container >
                            <Grid xs = {1}> </Grid>
                            <Grid xs = {10}
                                  id = "calender"
                            >
                                <Box sx={{mt: 5}}>
                                    {/*<Box sx={ {m:5}}>*/}
                                    {/*    <Grid container sx={{mb:1}}>*/}
                                    {/*        <Grid xs={12}>*/}
                                    {/*            <h5 className= "headerClass"  > Insert new project task, category, and customer. </h5>*/}
                                    {/*        </Grid>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Box sx={ {mt:5}} id = "calender">*/}
                                    {/*        <Grid container sx={{mb:1}}>*/}
                                    {/*            <Grid xs={3}>*/}
                                    {/*                <Typography variant="subtitle2" sx={{fontWeight: 700,pt: "5px"}}>Project task:</Typography>*/}
                                    {/*            </Grid>*/}
                                    {/*            <Grid xs={6}>*/}
                                    {/*                <input*/}
                                    {/*                    type="text"*/}
                                    {/*                    value={newItems.task}*/}
                                    {/*                    onChange= {(event) => setNewConf(event)}*/}
                                    {/*                    name="task"*/}
                                    {/*                    className="selectOption"*/}
                                    {/*                    style={{textAlign:"left", width: "100%", backgroundColor: "whitesmoke", padding: "10px"}}*/}
                                    {/*                />*/}
                                    {/*            </Grid>*/}
                                    {/*            <Grid xs={3}>*/}
                                    {/*                <Button*/}
                                    {/*                    className="editBtn"*/}
                                    {/*                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}*/}
                                    {/*                    onClick={InsertTask}*/}
                                    {/*                >*/}
                                    {/*                    Insert*/}
                                    {/*                </Button>*/}
                                    {/*            </Grid>*/}
                                    {/*        </Grid>*/}
                                    {/*        <Grid container sx={{mb:1}}>*/}
                                    {/*            <Grid xs={3}>*/}
                                    {/*                <Typography variant="subtitle2" sx={{fontWeight: 700,pt: "5px"}}>Project category:</Typography>*/}
                                    {/*            </Grid>*/}
                                    {/*            <Grid xs={6}>*/}
                                    {/*                <input*/}
                                    {/*                    type="text"*/}
                                    {/*                    value={newItems.category}*/}
                                    {/*                    onChange=  {(event) => setNewConf(event)}*/}
                                    {/*                    name="category"*/}
                                    {/*                    className="selectOption"*/}
                                    {/*                    style={{textAlign:"left", width: "100%",  backgroundColor: "whitesmoke", padding: "10px"}}*/}
                                    {/*                />*/}
                                    {/*            </Grid>*/}
                                    {/*            <Grid xs={3}>*/}
                                    {/*                <Button*/}
                                    {/*                    className="editBtn"*/}
                                    {/*                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}*/}
                                    {/*                    onClick={InsertProject}*/}
                                    {/*                >*/}
                                    {/*                    Insert*/}
                                    {/*                </Button>*/}
                                    {/*            </Grid>*/}
                                    {/*        </Grid>*/}
                                    {/*        <Grid container sx={{mb:1}}>*/}
                                    {/*            <Grid xs={3}>*/}
                                    {/*                <Typography variant="subtitle2" sx={{fontWeight: 700,pt: "5px"}}> Customer: </Typography>*/}
                                    {/*            </Grid>*/}
                                    {/*            <Grid xs={6}>*/}
                                    {/*                <input*/}
                                    {/*                    type="text"*/}
                                    {/*                    value={newItems.customer}*/}
                                    {/*                    onChange = {(event) => setNewConf(event)}*/}
                                    {/*                    name="customer"*/}
                                    {/*                    className="selectOption"*/}
                                    {/*                    style={{textAlign:"left", width: "100%",  backgroundColor: "whitesmoke", padding: "10px"}}*/}
                                    {/*                />*/}
                                    {/*            </Grid>*/}
                                    {/*            <Grid xs={3}>*/}
                                    {/*                <Button*/}
                                    {/*                    className="editBtn"*/}
                                    {/*                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}*/}
                                    {/*                    onClick={InsertCustomer}*/}
                                    {/*                >*/}
                                    {/*                    Insert*/}
                                    {/*                </Button>*/}
                                    {/*            </Grid>*/}
                                    {/*        </Grid>*/}
                                    {/*    </Box>*/}

                                    {/*</Box>*/}

                                    <Box sx={ {m:5}}>

                                        <Box sx={ {mt:5}} id = "calender">

                                            <Divider textAlign="left">Task Input</Divider>
                                            <Grid container sx={{mb:3, mt: 3}}>
                                                <Grid xs={6}>
                                                    <select
                                                        name="task"
                                                        value={preItem.task}
                                                        onChange=  {(event) => selectedPreItem(event)}
                                                        className="selectFilter"
                                                    >
                                                        <option value="">  </option>
                                                        {taskTitle.map((task, ind0) => (
                                                            <option key={ind0.toString()} value={task}>
                                                                {task}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </Grid>
                                                <Grid xs={6}>
                                                    <input
                                                        type="text"
                                                        value={newItems.task}
                                                        onChange= {(event) => setNewConf(event)}
                                                        name="task"
                                                        className="selectFilter"
                                                        style={{textAlign:"left", width: "100%", backgroundColor: "whitesmoke", padding: "10px"}}
                                                        placeholder="Type new task or edited task for selected item"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box sx={{mb:10, mt:2}}>
                                                <Button
                                                    className="editBtn"
                                                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}
                                                    onClick={InsertTask}
                                                >
                                                    Insert
                                                </Button>
                                                <Button
                                                    className="editBtn"
                                                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}
                                                    onClick={editTask}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    className="editBtn"
                                                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}
                                                    onClick={deleteTask}
                                                >
                                                    Delete
                                                </Button>

                                            </Box>

                                            <Divider textAlign="left">Category Input</Divider>
                                            <Grid container sx={{mb:3, mt: 3}}>
                                                <Grid xs={6}>

                                                    <select
                                                        name="cat"
                                                        value={preItem.cat}
                                                        onChange=  {(event) => selectedPreItem(event)}
                                                        className="selectFilter"
                                                    >
                                                        <option value="">  </option>
                                                        {ProjectTitle.map((title, index) => (
                                                            <option key={title} value={title}>
                                                                {title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </Grid>
                                                <Grid xs={6}>
                                                    <input
                                                        type="text"
                                                        value={newItems.category}
                                                        onChange=  {(event) => setNewConf(event)}
                                                        name="category"
                                                        className="selectFilter"
                                                        style={{textAlign:"left", width: "100%",  backgroundColor: "whitesmoke", padding: "10px"}}
                                                        placeholder="Type new project category or edited category for selected item"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box sx={{mb:10, mt:2}}>
                                                <Button
                                                    className="editBtn"
                                                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}
                                                    onClick={InsertProject}
                                                >
                                                    Insert
                                                </Button>
                                                <Button
                                                    className="editBtn"
                                                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}
                                                    onClick={editProject}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    className="editBtn"
                                                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}
                                                    onClick={deleteProject}
                                                >
                                                    Delete
                                                </Button>
                                            </Box>

                                            <Divider textAlign="left">Customer Input</Divider>
                                            <Grid container sx={{mb:3, mt: 3}}>
                                                <Grid xs={6}>
                                                    <select
                                                        name="customer"
                                                        value={preItem.customer}
                                                        onChange=  {(event) => selectedPreItem(event)}
                                                        className="selectFilter"
                                                    >
                                                        <option value="">  </option>
                                                        {customerName.map((customer, index) => (
                                                            <option key={customer} value={customer}>
                                                                {customer}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </Grid>
                                                <Grid xs={6}>
                                                    <input
                                                        type="text"
                                                        value={newItems.customer}
                                                        onChange = {(event) => setNewConf(event)}
                                                        name="customer"
                                                        className="selectFilter"
                                                        style={{textAlign:"left", width: "100%",  backgroundColor: "whitesmoke", padding: "10px"}}
                                                        placeholder="Type new customer or edited customer for selected item"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box sx={{mb:10, mt:2}}>
                                                <Button
                                                    className="editBtn"
                                                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}
                                                    onClick={InsertCustomer}
                                                >
                                                    Insert
                                                </Button>
                                                <Button
                                                    className="editBtn"
                                                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}
                                                    onClick={editCustomer}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    className="editBtn"
                                                    sx={{p: "12px", backgroundColor: "#153250", float: "right"}}
                                                    onClick={deleteCustomer}
                                                >
                                                    Delete
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs = {1}> </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
    );
}

export default ProjNewConf;