import React, {useState, useContext, useRef, useEffect} from "react";
import {
    Box, Button,Divider, Paper,Typography, Table, TableBody,TableCell,TableContainer,TableRow, CircularProgress} from "@mui/material";
import {grey} from "@mui/material/colors";
import MainContext from "../../context";
import Axios, {AxiosError} from 'axios';
import {useAuthUser} from "react-auth-kit";
import Grid from "@mui/material/Unstable_Grid2";
import { InitTasks } from "./StaffChart";
import { tabsData } from "../../components/data/tabsData.sidebar";
import axios from 'axios';
import { PDFDownloadLink } from "@react-pdf/renderer";
import {format} from "date-fns";
import {Bar} from "react-chartjs-2";
import SwipeableViews from "react-swipeable-views";
import {StaffGuanttChart, StaffActivity} from "./index"
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"

            hidden={value !== index + 1}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index + 1 && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}



const WorkPaper = ({pageTitle}) =>
{
    const {Staff, userInfo, staffPerformance} = useContext(MainContext);

    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    const [staffDetail, setStaffDetail] =useState({
        name: userInfo.name,
        position: userInfo.position,
        group: userInfo.group,
    });

    const [searchContent, setSearchContent] = useState(userInfo.name);
    const SearchStaff= (e) => {
        setSearchContent(e.target.value);
        let Detail = Staff.filter(row => row[0] === e.target.value)
        if (Detail.length >= 1){
            setStaffDetail({["name"]: Detail[0][0] ,["position"]: Detail[0][2],["group"]: Detail[0][1]  })
        }
        staffPerformance(Detail[0][0],Detail[0][1])
    }


    return (
        <>
            <Box
                sx={{
                    height: "100vh",
                    overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: "#F0F2F3",
                }}
                id = "mReport"
            >

                <Box sx = {{mb: 2, ml:5, mr:5,mt: 2}}>
                    <h3 className= "headerClass"  > Activity Record </h3>
                    {/*<Typography  variant="caption" sx={{color: "#031830"}} > Here is the overview of your performance in this month. </Typography>*/}
                    <Box sx = {{textAlign: "right", display: "inline-block", float: "right" }} id = "search">
                        <Paper
                            component="form"
                            sx={{  display: 'flex', alignItems: 'left', width:250, textAlign: "left", direction: "ltr", overflow: "hidden"}}
                        >
                            <select
                                value = {searchContent}
                                onChange={(e) => SearchStaff(e)}
                                className="selectOption"
                                placeholder= {searchContent.substring(0, (searchContent).indexOf(" "))}
                                style ={{ ml: 1, pl:2, flex: 1, fontSize: "0.85rem", textAlign: "left" }}
                            >
                                <option key= "" value= "">  </option>
                                {Staff.map((member, ind) => (
                                    <option key={ind} value={member[0]}>
                                        {member[0].substring(0, (member[0]).indexOf(" "))}
                                    </option>
                                ))}
                            </select>

                        </Paper>
                    </Box>
                </Box>

                <Box
                    sx = {{
                        height: "100%",
                        overflowY: "scroll",
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: "1.2rem",
                        mb: 2, ml:4, mr:4,
                        scrollbarWidth: "none",
                    }}
                >
                    <SwipeableViews
                        index={page - 1}
                        onChangeIndex={handleChange}
                    >
                        <TabPanel value={page} index={0} >
                            <StaffActivity staffDetail={staffDetail}/>
                        </TabPanel>

                        <TabPanel value={page} index={1}>
                            <Box >
                                <StaffGuanttChart  />
                            </Box>
                        </TabPanel>
                    </SwipeableViews>
                </Box>


                <Box sx = {{alignContent: "center", textAlign:"left", justifyContent:"center", mt: 'auto',mb: 3}} >
                    <Stack spacing={3}
                           sx={{alignItems: "center"}}
                    >
                        <Pagination sx= {{color: "black", textAlign: "center"}} count={2} page={page} onChange={handleChange} />
                    </Stack>
                </Box>



            </Box>
        </>
    );
};
export default WorkPaper;