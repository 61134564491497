import React, {useContext, useState,} from "react";
import {Box, Divider, Typography} from "@mui/material";
import {GuanttChart, StaffGuanttChart, StaffGuanttCharts} from "./index"
import {grey} from "@mui/material/colors";
import { ProjHours } from "./projectHours";
import { BarChart } from '@mui/x-charts/BarChart';
import MainContext from "../../context";
import {Bar} from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(CategoryScale);
Chart.register([annotationPlugin], [ChartDataLabels] );

const Performance = ({pageTitle}) => {
    const projectDifferences = useState(ProjHours());
    const { Category } = useContext(MainContext);

    let  actualData = []
    let estimatedData = []
    let projectCode = []
    const [Cat, setCat] = useState("DOA - Projects");
    let catIndex = Category.findIndex(item => item === Cat);
    let catGroup = projectDifferences[0][catIndex];
    for (let i=0; i < catGroup.length; i++ )
    {
        estimatedData.push(catGroup[i].estimHours)
        actualData.push(catGroup[i].DiffTime + catGroup[i].estimHours)
        projectCode.push(catGroup[i].project)
    }


    const onCatChange = (event) =>{
        actualData = []
        estimatedData = []
        projectCode = []
        setCat(event.target.value);
        catIndex = Category.findIndex(item => item === event.target.value);
        catGroup = projectDifferences[0][catIndex];
        for (let i=0; i < catGroup.length; i++ )
        {
            estimatedData.push(catGroup[i].estimHours)
            actualData.push(catGroup[i].DiffTime + catGroup[i].estimHours)
            projectCode.push(catGroup[i].project)
        }
    }
    const options = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            x: {
                stacked: true,
                display: true,
                title: {
                    display: false,
                    text: 'Project Hours'
                },
            },
        },

        plugins: {
            datalabels: {
                display: false
            },
            legend: {
                display: true
            },
            title: {
                display: true,
                text: "Project hours",
                padding: {
                    bottom: 30
                },
                weight: "bold",
                //color: "#4e4d4ddb",
                font: {
                    size: 13
                },
                align: "start"
            },
        }

    }

    let data0 ={
        datasets:[{
            label: 'Project estimated hours',
            data : estimatedData
        },
        {
            label: 'Project actual hours',
            data:  actualData
        }],
        labels: projectCode
    }



    return (
        <div>
            <Box sx = {{mt: 7}}>
                <Typography  variant="subtitle1" sx={{color: "#15465b"}}> {pageTitle.toUpperCase()} </Typography>
                <Divider variant="middle"  sx = {{borderColor:grey[400]}} />
            </Box>
            <Box sx={{ height: "100vh", overflow: "hidden" }}>
                <StaffGuanttChart  />
            </Box>
            {/*<Box>*/}
            {/*    <BarChart*/}
            {/*        series={[*/}
            {/*            { data: estimatedData , stack: 'A', label: 'Project Estimated Hours' },*/}
            {/*            { data: actualData, stack: 'A', label: 'Project Actual Hours' },*/}
            {/*        ]}*/}
            {/*        xAxis={[{ scaleType: 'band', data: projectCode }]}*/}
            {/*        width={600}*/}
            {/*        height={350}*/}
            {/*    />*/}
            {/*</Box>*/}

            <Box sx = {{m: 4}}>
                <select
                    value={Cat}
                    onChange=  {(event) => onCatChange(event)}
                    className="selectOption"
                >
                    <option value="">  </option>
                    {Category.map((cat) => (
                        <option key={cat} value={cat}>
                            {cat}
                        </option>
                    ))}

                </select>

                <Bar  data={data0} options={options} />

            </Box>

            <Box>




            </Box>
        </div>
    );
};

export default Performance;