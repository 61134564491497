import React from "react";
import {Button, Box, Divider, Typography, TableCell} from "@mui/material";
import {grey} from "@mui/material/colors";
import {
    TaskPage,
    ArchivedPage,
    OnHoldPage,
    ManagementReport,
    WorkPaper,
    Administrators,
    Dashboard,
    ProjNewConf,
} from "./index";
import MainContext from "../../context";
import {useContext} from "react";
import Page from "./Page";
import SwipeableViews from "react-swipeable-views";
import Snackbar from "@mui/material/Snackbar";
import Fade from '@mui/material/Fade';

const MainPage = () => {
    const {snackContent, handleSnackClose, snackOpen, handlePageNumber, pageNumber , subTabs} = useContext(MainContext);
    const vertical= "top"
    const horizontal = 'center'
    const Transition= Fade
    return(
        <>
            <SwipeableViews
                index={pageNumber}
                onChangeIndex={handlePageNumber}
                id = "nononn"
            >
                <Page pageNumber={pageNumber} index={0} key = {0}>
                    <Dashboard pageTitle = {"Dashboard"} />
                </Page>

                {subTabs.map((subTab, key) => {
                    return(
                        (key === 0 || key === 1)  ?
                            (key === 0) ?
                                <Page pageNumber={pageNumber} index={ key + 1} key = {key}>
                                    <ArchivedPage pageTitle = {"Finished Projects"} />
                                </Page>
                                :
                                <Page pageNumber={pageNumber} index={ key + 1} key = {key}>
                                    <OnHoldPage pageTitle = {"On hold Projects"} />
                                </Page>
                            :
                            <Page pageNumber={pageNumber} index={ key + 1} key = {key}>
                                <TaskPage pageTitle = {"In progress Projects"} />
                            </Page>
                    )
                })}
                <Page pageNumber={pageNumber} index={subTabs.length + 1} key = {subTabs.length + 1}>
                    <ManagementReport pageTitle = {"Progress Chart"} />
                </Page>
                <Page pageNumber={pageNumber} index={subTabs.length + 2} key = {subTabs.length + 2}>
                    <ProjNewConf pageTitle = {"Insert Input"} />
                </Page>
                <Page pageNumber={pageNumber} index={subTabs.length + 3} key = {subTabs.length + 3}>
                    <WorkPaper pageTitle = {"Activity Record"} />
                </Page>
                <Page pageNumber={pageNumber} index={subTabs.length + 4} key = {subTabs.length + 4}>
                    <Administrators pageTitle = {"Administrator Panel"} />
                </Page>
            </SwipeableViews>
            <Box>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={snackOpen}
                    onClose={handleSnackClose}
                    message={snackContent}
                    TransitionComponent= {Transition}
                    autoHideDuration={2000}
                    className = "snakeBar"
                />

            </Box>
        </>
    );
}

export default MainPage;

