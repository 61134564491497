import React from 'react'
import { useState, useContext} from "react";
import MainContext from "../../context";
import "rsuite/dist/rsuite.min.css";
import {
    Progress,
    ButtonGroup,
    Button
} from "rsuite";
import {
    Box,
} from "@mui/material";
import {useAuthUser} from 'react-auth-kit';

const Progressbar = (props) => {
    const {tableNumber, tableRow} = props
    const {userInfo, projList, projData, onValUpdate} = useContext(MainContext);
    const auth = useAuthUser()
    //const [percent, setPercent] = useState(0);

    // const decrease = () => {
    //     const value = Math.max(percent - 5, 0);
    //     setPercent(value);
    // };
    //
    // const increase = () => {
    //     const value =
    //         Math.min(percent + 5, 100);
    //     setPercent(value);
    // };

    const status = 100 <= parseInt(projData[tableNumber][tableRow][8]) ? "success" : null;
    const color =  100 <= parseInt(projData[tableNumber][tableRow][8]) ? "#03D613" : "#D65003";

    return (
        <div style={{ width:150}} id = "progress" >
            <div id='parent_div_1'>
                <Box sx={{width: 100}} >
                    <Progress.Line
                        percent= {parseInt(projData[tableNumber][tableRow][8])}
                        strokeColor={color}
                        status={status}
                    />
                </Box>
            </div>

            <div id='parent_div_2'>
                <input
                    style={{display:"inline-block", width: "40px", float:"left"}}
                    type="number"
                    name = "progressbar"
                    value= {projData[tableNumber][tableRow][8]}
                    onChange=  {(event) => onValUpdate(tableNumber, tableRow, 8, event)}
                    onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                    min={0}
                    max={100}
                    disabled={!((!(Array.isArray(projList[tableNumber][4])) && (userInfo.name === projList[tableNumber][4])) || ((Array.isArray(projList[tableNumber][4])) && (projList[tableNumber][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") || (userInfo.name === projData[tableNumber][tableRow][2]) )}

                    //disabled={ (!((userInfo.name === projList[tableNumber][4])  || (userInfo.admin === "Yes") || (userInfo.name === projData[tableNumber][tableRow][2])))}
                />
            </div>

        </div>
    );
}

export default Progressbar;