import React from "react";
import {useContext, useState} from "react";
import {Box,Typography, Divider} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import {grey} from "@mui/material/colors";
import { NewUser, ProjNewConf} from "./index";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import MainContext from "../../context";


function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const Administrators = ({pageTitle}) =>
{
    const [value, setValue] = useState(0);
    const { TabPanel } = useContext(MainContext);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [page, setPage] = React.useState(1);
    const handleAdminPage= (event, value) => {
        setPage(value);
    };


    return(
        <>
            <Box
                sx={{
                    height: "100vh",
                    overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: "#F0F2F3",
                }}
            >
                <Box sx = {{mb: 2, ml:5, mr:5,mt: 2}}>
                    <h3 className= "headerClass"  > {pageTitle} </h3>
                    {/*<Typography  variant="caption" sx={{color: "#031830"}} > Create user, manager & admin account, here! </Typography>*/}
                </Box>
                <Divider variant="middle"  sx={{ height: 2,  mt: 1,  backgroundColor:grey[400]}} />

                <Box
                    sx = {{
                        height: "100%",
                        overflowY: "scroll",
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: "1.2rem",
                        mb: 2, ml:4, mr:4,
                        scrollbarWidth: "none",
                    }}
                >

                    {/*<Box*/}
                    {/*    sx = {{*/}
                    {/*        height: "95%",*/}
                    {/*        overflowY: "scroll",*/}
                    {/*        display: 'flex',*/}
                    {/*        flexDirection: 'column',*/}
                    {/*        fontSize: "1.2rem",*/}
                    {/*        borderRadius: 5,*/}
                    {/*        mb: 2, ml:4, mr:4,mt: 2*/}
                    {/*    }}*/}
                    {/*>*/}
                    <Box sx={{bgcolor:'background.paper', backgroundColor: "unset", height: "100%",}}  className = "adminpage">
                            <Grid container >
                                <Grid xs = {1}> </Grid>
                                <Grid xs = {10}
                                      id = "calender"
                                >
                                    <Box sx={{mt: 5}}>
                                        <NewUser />
                                    </Box>
                                </Grid>
                                <Grid xs = {1}> </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    {/*<SwipeableViews*/}
                    {/*    index={page - 1}*/}
                    {/*    onChangeIndex={handleAdminPage}*/}
                    {/*>*/}
                    {/*    <TabPanel value={page} index={0} sx={{backgroundColor: "red"}}>*/}

                    {/*    </TabPanel>*/}

                    {/*    <TabPanel value={page} index={1} sx={{backgroundColor: "red"}}>*/}
                    {/*        <Box*/}
                    {/*            sx = {{*/}
                    {/*                height: "95%",*/}
                    {/*                overflowY: "scroll",*/}
                    {/*                display: 'flex',*/}
                    {/*                flexDirection: 'column',*/}
                    {/*                fontSize: "1.2rem",*/}
                    {/*                borderRadius: 5,*/}
                    {/*                mb: 2, ml:4, mr:4,mt: 2*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <Box sx={{bgcolor:'background.paper', backgroundColor: "unset", height: "100%",}}  className = "adminpage">*/}
                    {/*                <Grid container >*/}
                    {/*                    <Grid xs = {1}> </Grid>*/}
                    {/*                    <Grid xs = {10}*/}
                    {/*                          id = "calender"*/}
                    {/*                    >*/}
                    {/*                        <Box sx={{mt: 5}}>*/}
                    {/*                            <ProjNewConf />*/}
                    {/*                        </Box>*/}
                    {/*                    </Grid>*/}
                    {/*                    <Grid xs = {1}> </Grid>*/}
                    {/*                </Grid>*/}
                    {/*            </Box>*/}
                    {/*        </Box>*/}
                    {/*    </TabPanel>*/}
                    {/*</SwipeableViews>*/}
                {/*</Box>*/}

                {/*<Box sx = {{alignContent: "center", textAlign:"left", justifyContent:"center", mt: 'auto',mb: 3}} >*/}
                {/*    <Stack spacing={3}*/}
                {/*           sx={{alignItems: "center"}}*/}
                {/*    >*/}
                {/*        <Pagination sx= {{color: "black", textAlign: "center"}} count={2} page={page} onChange={handleAdminPage} />*/}
                {/*    </Stack>*/}
                {/*</Box>*/}




            </Box>
        </>
    );
}

export default Administrators;