import React from "react";
import { useState, useContext} from "react";
import MainContext from "../../context";
import {addDays, subDays} from "date-fns";

function createArray(N) {
    return [...Array(N).keys()].map(i => i );
}

export function InitTasks({Cat}) {
    const {projData, projList, subTasks, projProgress} = useContext(MainContext);
    let tasks = []
    let temp = 0
    let tasksId = [];
    let key = ""
    let projectIndexes = []
    let typeProject = [];
    let dependancy = []
    let preTaskId = "" ;
    let index0 = "";
    let lastTask = "";

    if (Cat === "In-progress"){
        typeProject = projList;
        projectIndexes = createArray(projList.length);
    }else{

        let indices = []
        for(let i=0; i < Cat.length;i++) {
            if (Cat[i] === "-"){
                indices.push(i);
                break
            }
        }
        for(let i = Cat.length -1; i > -1;i--) {
            if (Cat[i] === "-"){
                indices.push(i);
                break
            }
        }
        let str1 = Cat.substring(1, indices[0]);
        let str2 = Cat.substring(indices[0] + 1, indices[1])
        let str3 = Cat.substring(indices[1] + 1, Cat.length)
        let project_index = projList.findIndex(row => row[2] === str2 && row[3] === str1 && row[8] === str3 )


        //let project_index2 = projList.findIndex(row => (row[3] === Cat.substr(1, indices[0] - 1)) && (row[2] === Cat.substr(indices[0] + 1, indices[1] - (indices[0] + 1)))  && (row[8] === Cat.substr(indices[1] + 1, Cat.length - indices[1])) )
        if (projData[project_index].length > 0 )
        {
            typeProject = [projList[project_index]]
            projectIndexes.push(project_index)
        }
    }

    if (typeProject.length > 0)
    {
        let dateArr = []
        let endDateArr = []
        for ( let j=0; j < typeProject.length; j++ )
        {
            if (typeProject[j][6] !== "")
            {
                dateArr.push(new Date(typeProject[j][6]).getTime());
            }
            if (typeProject[j][7] !== "")
            {
                endDateArr.push(new Date(typeProject[j][7]).getTime());
            }
        }

        let startDate= Math.min(...dateArr);
        let ind = dateArr.indexOf(startDate);
        let endDate= Math.max(...endDateArr);
        let ind2 = endDateArr.indexOf(endDate);

        // if (Cat === "All Project")
        // {
        //     key = {
        //         start: new Date(typeProject[ind][6]),
        //         end:  new Date(typeProject[ind2][7]),
        //         name: "All Projects",
        //         id: "All",
        //         progress: 0,
        //         type: "milestone",
        //         hideChildren: false,
        //         styles : { progressColor: '#adbb9c', progressSelectedColor: '#ff9e0d', backgroundColor : "#a85858" }
        //     }
        //     tasks.push(key)
        // }

        for ( let j=0; j < typeProject.length; j++ )
        {
            let progressIndex = 0
            let primaryProgress = 0
            let projectHours = 0
            let index = projectIndexes[j];
            let progressValue = 0
            //projProgress
            if (projData[index].length > 0)
            {
                for (let k=0; k < projData[index].length; k++ ){
                    progressValue += projData[index][k][8] * projData[index][k][12]
                }
            }

            if (projList[index][6] !== "" && projList[index][7] !== "")
            {
                key =
                    {
                    start:new Date(projList[index][6]),
                    end: new Date(projList[index][7]),
                    name: "#" + projList[index][3] + "-" + projList[index][2] + "-" + projList[index][8],
                    id: "Project " + j.toString(),
                    //progress: progressValue,
                    progress: 0,
                    type: "project",
                    hideChildren: false,
                    //project: "All",
                    styles : { progressColor: '#2a647b', progressSelectedColor: "#2a647b", backgroundColor : "#2e8fa5", backgroundSelectedColor : "#2e8fa5" },
                }
                tasks.push(key);
                progressIndex = tasks.length - 1;

                tasks[progressIndex]["progress"] = Math.floor(projProgress[index][1]);
                tasks[progressIndex]["name"] += " - " + (Math.floor(projProgress[index][1])).toString() + " %";

                //tasks[progressIndex]["progress"] = Math.floor(primaryProgress / projectHours)
                //tasks[progressIndex]["name"] += " - " + (tasks[progressIndex]["progress"]).toString() + " %"

                for (let k=0; k < projData[index].length; k++)
                {
                    if (projData[index][k][1] !== "" && projData[index][k][4] !== "" && projData[index][k][11] !== "" && projData[index][k][13] === "")
                    {
                        let progressColor = "#fcbeaa"
                        let progressSelectedColor = '#ffcbba'
                        let backgroundColor = '#feece3'
                        if ( (projData[index][k][8] !== 100) && ( new Date(projData[index][k][4])  < new Date()) ){

                            backgroundColor = '#e85c52'
                        }
                        primaryProgress += parseInt(projData[index][k][8]) * parseInt(projData[index][k][6])
                        projectHours += parseInt(projData[index][k][6]);

                        dependancy = []
                        if (projData[index][k][10] !== "")
                        {
                            let preIDs = projData[index][k][10].item
                            for (let kk = 0; kk < preIDs.length; kk++)
                            {
                                if (preIDs[kk] !== projData[index][k][0])
                                {
                                    index0 = tasksId.findIndex(item => item.taskId == parseInt(preIDs[kk]) && item.projectId === index);
                                    if (index0 !== -1){
                                        dependancy.push(tasksId[index0].ganttId)
                                    }
                                }
                            }
                        }

                        key = {
                            start: new Date(projData[index][k][11]),
                            end: new Date(projData[index][k][4]),
                            name: projData[index][k][1] + " - " + (projData[index][k][8]).toString() + " %",
                            id: temp ,
                            progress: projData[index][k][8],
                            dependencies: dependancy,
                            type: "task",
                            project: "Project " + j.toString(),
                            styles : { progressColor: progressColor, progressSelectedColor: progressSelectedColor, backgroundColor : backgroundColor, backgroundSelectedColor: backgroundColor },
                        }
                        tasksId.push({ganttId: temp, taskId: parseInt(projData[index][k][0]), projectId: index})
                        temp = temp + 1;
                        tasks.push(key)
                    }
                }

                if (projData[index].length > 0)
                {
                    // if (projData[index][projData[index].length - 1][4] !== "")
                    // {
                    //     lastTask = addDays(new Date(projData[index][projData[index].length - 1][4]) , 2)
                    //
                    //     preTaskId = projData[index][(projData[index]).length - 1][0]
                    //     index0 = tasksId.findIndex(item => item.taskId == parseInt(preTaskId) && item.projectId === index)
                    //     dependancy = []
                    //     if (index0 !== -1){
                    //         dependancy.push(tasksId[index0].ganttId)
                    //     }
                    //     key = {
                    //         start: lastTask,
                    //         end: lastTask,
                    //         name: "Release",
                    //         id: "Task " + j.toString(),
                    //         progress: "",
                    //         type: "milestone",
                    //         dependencies: dependancy,
                    //         project: "Project " + j.toString(),
                    //     }
                    //     tasks.push(key)
                    // }



                    // if(projectHours !== 0){
                    //     tasks[progressIndex]["progress"] = Math.floor(primaryProgress / projectHours)
                    //     tasks[progressIndex]["name"] += " - " + (tasks[progressIndex]["progress"]).toString() + " %"
                    // }else{
                    //     tasks[progressIndex]["progress"] = 0
                    //     tasks[progressIndex]["name"] += " - " + (0).toString() + " %"
                    // }
                }

            }

        }
    }


    return tasks;
}





export function GetStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;
    for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }

    // const currentDate = new Date();
    // const tasks= [
    //     {
    //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    //         name: "Some Project",
    //         id: "ProjectSample",
    //         progress: 25,
    //         type: "project",
    //
    //         hideChildren: false
    //     },
    //     {
    //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    //         end: new Date(
    //             currentDate.getFullYear(),
    //             currentDate.getMonth(),
    //             2,
    //             12,
    //             28
    //         ),
    //         name: "Idea",
    //         id: "Task 0",
    //         progress: 45,
    //         type: "task",
    //         project: "ProjectSample"
    //     },
    //     {
    //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
    //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
    //         name: "Research",
    //         id: "Task 1",
    //         progress: 25,
    //         dependencies: ["Task 0"],
    //         type: "task",
    //         project: "ProjectSample"
    //     },
    //     {
    //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
    //         name: "Discussion with team",
    //         id: "Task 2",
    //         progress: 10,
    //         dependencies: ["Task 1"],
    //         type: "task",
    //         project: "ProjectSample"
    //     },
    //     {
    //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
    //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
    //         name: "Developing",
    //         id: "Task 3",
    //         progress: 2,
    //         dependencies: ["Task 2"],
    //         type: "task",
    //         project: "ProjectSample"
    //     },
    //     {
    //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
    //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
    //         name: "Review",
    //         id: "Task 4",
    //         type: "task",
    //         progress: 70,
    //         dependencies: ["Task 2"],
    //         project: "ProjectSample"
    //     },
    //     {
    //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    //         name: "Release",
    //         id: "Task 6",
    //         progress: currentDate.getMonth(),
    //         type: "milestone",
    //         dependencies: ["Task 4"],
    //         project: "ProjectSample"
    //     },
    //     {
    //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
    //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
    //         name: "Party Time",
    //         id: "Task 9",
    //         progress: 0,
    //         isDisabled: true,
    //         type: "task"
    //     }
    // ];
    // return tasks;



    return [start, end];
}
