import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Button,
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {ArrowDropDownRounded,ArrowRightRounded} from '@mui/icons-material';

import {grey} from "@mui/material/colors";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { useContext, useState, useEffect, forwardRef} from "react";
import MainContext from "../../context";

import {subDays, format, addDays} from "date-fns";
import { tabsData } from "../../components/data/tabsData.sidebar";
import {useAuthUser} from "react-auth-kit";

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';



function TableRows({ projectDates, key1}) {
    const {setOpenElem, setDialogContent , setDeleteInfo, setAnchorEl, headCells } = useContext(MainContext);

    const [selectedID, setSelectedID] = useState(null);

    if (projectDates.length > 0)
    {
        return projectDates.map((projectDate, ind) =>
        {

            let index = ind;
            let date1 = new Date();
            let temp1 = date1.getTime();

            let date3 = new Date(projectDate[index][4]);
            let temp3 =  date3.getTime();
            let timeDiff2 = temp3- temp1;

            let taskBackColor = "1px solid #8080801c";
            if ((parseInt(projectDate[index][8]) < 100) && (date3 <= date1)) {
                taskBackColor = "3px solid #da0f27";
            }else if ((parseInt(projectDate[index][8]) < 50) && (subDays(date3, 1) <= date1)){
                taskBackColor = "3px solid #e9963b";
            }else if (parseInt(projectDate[index][8]) === 100){
                taskBackColor = "3px solid #ADD3D0";
            }

            let taskStyle = {
                width: '30%',
                borderLeft: taskBackColor,
                borderBottom: "1px solid #8080801c",
            }

            let color = "transparent";
            let fontcolor = "#ffffff";
            if (projectDates[index][3] === "Done"){
                //color = "#5aab89";
                color =  "#00c875";
            }else if (projectDates[index][3] ==="In progress"){
                color = "#D1913E";
            }else if (projectDates[index][3] ==="Stuck"){
                color = "#A92420"
            }else if (projectDates[index][3] ==="Not started"){
                color = "#b5b4b4"
            }
            let color_critical = "transparent";
            if (projectDates[index][5] === "Critical"){
                color_critical = "#401694"
            }else if (projectDates[index][5] ==="High"){
                color_critical = "#d1913e";
            }else if (projectDates[index][5] ==="Medium"){
                //color_critical = "#ecd3b1";
                color_critical = "#579bfc";
                //color_critical = "#5559df";
            }else if (projectDates[index][5] ==="Low"){
                color_critical = "#faf4eb";
            }

            let limitClass = false;
            if (projectDates[index][5] === "Critical"){
                limitClass = true
            }

            // const handleClick = (elem, elem2) => (event) => {
            //     setAnchorEl(event.currentTarget);
            //     setOpenElem(elem);
            //     setOpenElemRow(elem2);
            //     setDeleteInfo({["projectId"]: elem , ["taskId"]: elem2});
            //     setDialogContent("Are you sure to delete the project task!");
            // };
            //
            // const handleClose = () => {
            //     setAnchorEl(null);
            //     setOpenElem(null);
            //     setOpenElemRow(null)
            // };

            let keyIndex = key1.toString() + ind.toString()
            return(
                <TableRow
                    key={"TR" + keyIndex}
                    //onClick={() => {setSelectedID("TR" + keyIndex);}}
                    className={(selectedID === "TR" + keyIndex ) ? "selectedRow": null}
                >
                    <TableCell  className ="taskInput" >
                        <div key={keyIndex}>
                            <IconButton
                                aria-label="more"
                                aria-controls={"long-menu" + key1 + ind}
                                aria-haspopup="true"
                                sx = {{padding: 0 }}
                                //onClick={handleClick(key1, index)}
                                id="long-button"
                            >
                                <MoreVertIcon sx={{color: "#6e6b6b80", height: "1.4rem"}}/>
                            </IconButton>
                        </div>
                    </TableCell>
                    <TableCell className ="taskInput" width = {headCells[0].width} >
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[0].width}}
                        >
                            {projectDates[index][14]}
                        </Typography>
                    </TableCell>

                    <TableCell className ="taskInput" width = {headCells[1].width} >
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[1].width, float: "left"}}
                        >
                            {projectDates[index][1]}
                        </Typography>
                    </TableCell>
                    <TableCell className ="taskInput" width = {headCells[2].width}>
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[2].width}}
                        >
                            {projectDates[index][2].substring(0, (projectDates[index][2]).indexOf(" "))}
                        </Typography>
                    </TableCell>
                    <TableCell className ="taskInput" width = {headCells[3].width}
                               sx={{backgroundColor: color_critical}}
                    >
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[3].width, display: "block"}}
                        >
                            {projectDates[index][5]}
                        </Typography>
                    </TableCell>

                    <TableCell className ="taskInput" width = {headCells[4].width}>
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[4].width, display: "block"}}
                        >
                            {projectDates[index][6]}
                        </Typography>
                    </TableCell>

                    <TableCell className ="taskInput" width = {headCells[5].width} key = {key1.toString() + ind.toString() + 5} >
                        <Autocomplete
                            multiple
                            id="fixed-tags-demo"
                            key = {keyIndex + 5}
                            //value= {projectDate[index][10]  !== "" ?projectDate[index][10].item : []}
                            //onChange=  {(event, newValue) => setSelect(key1, index, 10, event, newValue)}

                            options={projectDate.map(i => i[0])}
                            getOptionLabel={option => option.toString()}

                            ListboxProps={{
                                sx: { fontSize: 10, padding: 0, backgroundColor: "#ffffff"},
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 10,
                                },
                            }}
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                            disabled={ true}
                        />
                    </TableCell>

                    <TableCell className ="taskInput" sx={{width: headCells[6].width, fontSize: "0.75rem"}}
                               key = {keyIndex + 6}
                               //onClick={(event) => datePickerClick (key1, index) }
                    >
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[6].width, display: "block"}}
                        >
                            {projectDates[index][11]}
                        </Typography>
                    </TableCell>
                    <TableCell className ="taskInput" sx={{width: headCells[7].width, fontSize: "0.75rem"}}
                               key = {keyIndex + 7}
                    >
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[7].width, display: "block"}}
                        >
                            {projectDates[index][4]}
                        </Typography>
                    </TableCell>
                    <TableCell className ="taskInput"  width = {headCells[8].width}
                               sx = {{backgroundColor : color }}
                    >
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[8].width, display: "block"}}
                        >
                            {projectDates[index][3]}
                        </Typography>
                    </TableCell>

                    <TableCell className ="taskInput" width = {headCells[9].width}>
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[9].width, display: "block"}}
                        >
                            {projectDates[index][7]}
                        </Typography>
                    </TableCell>
                    <TableCell className ="taskInput" width = {headCells[10].width}
                               key = {keyIndex + 10}
                               disabled = {true}
                    >
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[10].width, display: "block"}}
                        >
                            {projectDates[index][8]}
                        </Typography>
                    </TableCell>

                    <TableCell className ="taskInput" width = {headCells[11].width}
                               key = {keyIndex + 11}
                    >
                            <textarea
                                value= {projectDates[index][9]}
                                className= "Title"
                                disabled = {true}
                            />
                    </TableCell>
                    <TableCell className ="taskInput" width = {headCells[12].width} >
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[12].width, display: "block"}}
                        >
                            {projectDates[index][13]}
                        </Typography>
                    </TableCell>
                </TableRow>
            );
        });
    }
}


const ArchivedTableRows = (props) => {

    const { index, typeP } = props;
    let item = [];
    let projProg = 0;
    let projectDates = [];

    const {archiveProject, openH, openF, setOpenF, setOpenH, onholdProject, onholdProjData, onholdProjProgress, finishProjData, finishProjProgress, finishedProject, headCells } = useContext(MainContext);
    if (typeP === "On hold") {
        item = onholdProject[index];
        projProg = onholdProjProgress[index][1];
        projectDates = onholdProjData[index];
    }else{
        item = finishedProject[index];
        projProg = finishProjProgress[index][1];
        projectDates = finishProjData[index];
    }

    const handleClick = (id) => {
       if (typeP === "On hold") {
            setOpenH((prevstate) => ({ ...prevstate, [id]: !prevstate[id]}));
        }else{
            setOpenF((prevstate) => ({ ...prevstate, [id]: !prevstate[id]}));
        }
    };

    return(
        <>
            <Paper sx={{width: '100%', }}>
                <Box sx = {{pt:1, pb:1}} >
                    <IconButton
                        onClick={() => handleClick(index)}
                        aria-label="expand"
                        size="small"
                        sx={{color: "#153250"}}
                    >
                        { ((typeP === "On hold")? openH[index] : openF[index]) ? <ArrowDropDownRounded/>
                            :<ArrowRightRounded/> }
                    </IconButton>
                    <Typography variant="subtitle1" sx={{
                        display: "inline-block",
                        fontWeight: "bold",
                        color: "#153250"
                    }}>
                        #  {item[3]} - {item[2] }  - {item[8]}
                    </Typography>
                </Box>
                <Box>
                    <Collapse in={ ((typeP === "On hold")? openH[index] : openF[index])} timeout="auto" unmountOnExit>
                        { ((typeP === "On hold")? openH[index] : openF[index])?
                            <Box>
                                <Box
                                    sx={{mb: 3, ml: 1}}
                                >
                                    <Grid container sx = {{fontSize: "0.85rem", textAlign: "left", mt: 1, mb: 1,}} color = {grey[600] }>
                                        <Grid xs= {4}>
                                            <Typography  variant = "caption"  sx ={{fontWeight : 700, display: "inline-block", pl: 2, }}> Manager:</Typography>
                                            {Array.isArray(item[4]) ?
                                                item[4].map((temp, ind1) => {
                                                    return (
                                                        <Typography key={ind1} variant = "caption" sx ={{display: "inline-block" ,fontWeight : 400, pl: 1,}}> {temp.substring(0, (temp).indexOf(" "))} {"-"} </Typography>
                                                    );
                                                })
                                                :
                                                <Typography variant = "caption" sx ={{display: "inline-block" ,fontWeight : 400, pl: 1,}}> {item[4].substring(0, (item[4]).indexOf(" "))} </Typography>
                                            }
                                        </Grid>

                                        <Grid xs= {4}>
                                            <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}>  Start Date:</Typography>
                                            <Typography variant = "caption" sx ={{fontWeight : 400, display: "inline-block", pl: 1,}}> { item[6] !=="" ? format(new Date(item[6]), 'yyyy-MM-dd'):null } </Typography>
                                        </Grid>

                                        <Grid xs= {4}>
                                            <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}>  Project Progress:</Typography>
                                            <Typography variant = "caption" sx ={{fontWeight : 400, display: "inline-block", pl: 1,}}> {Math.round(projProg * 100) / 100} {" %" }</Typography>
                                        </Grid>

                                    </Grid>
                                    <Grid container  sx = {{fontSize: "0.85rem", textAlign: "left", mt: 1, mb: 1,}} color = {grey[600] }>
                                        <Grid xs= {4}>
                                            <Typography variant = "caption"  sx ={{fontWeight : 700, display: "inline-block", pl: 2, }}> Deadline:</Typography>
                                            <Typography variant = "caption" sx ={{display: "inline-block" ,fontWeight : 400, pl: 1,}}> {item[5]} </Typography>
                                        </Grid>
                                        <Grid xs= {4}>
                                            <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}>  Finish Date:</Typography>
                                            <Typography variant = "caption" sx ={{fontWeight : 400, display: "inline-block", pl: 1,}}> { item[7] !== "" ? format(new Date(item[7]), 'yyyy-MM-dd') : null }</Typography>
                                        </Grid>

                                        <Grid xs= {4}>
                                            <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}> Status:</Typography>
                                            <Typography variant = "caption" sx ={{fontWeight : 400, display: "inline-block", pl: 1,}}> { item[10] }</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <TableContainer sx={{textAlign: "center", mb: 2, backgroundColor: "#F0F2F3",  maxHeight: "500px"}}>
                                    <Table sx={{width: "100%" ,  maxHeight: "500px"}}>

                                        <TableHead id = "headCell" >
                                            <TableRow className ="myTasksRow">
                                                <TableCell className="taskInput" sx={{width: "20px", backgroundColor:"#ffffff",borderLeft: "unset"}}>
                                                    <Typography variant = "caption" sx={{width: "20px", display: "block"}} >  </Typography>
                                                </TableCell>
                                                {headCells.map((headCell) => (
                                                    <TableCell
                                                        className="taskInput"
                                                        key={headCell.id}
                                                        sx={{border: "unset", backgroundColor:"#ffffff", }}
                                                        width={headCell.width}
                                                    >
                                                        <Typography variant = "caption" width={headCell.width} sx={{fontWeight: 700, display: "block"}}>  {headCell.label}  </Typography>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRows
                                                projectDates = {projectDates}
                                                key1 = {"TR1" + index.toString()}
                                            />
                                            <TableRow key = {"TR2" + index.toString()} sx={{backgroundColor: "#ffffff"}}>
                                                <TableCell colSpan={14} className="operationBtn" sx={{m:"5px"}}>
                                                    {(typeP === "On hold")?
                                                        <select
                                                            //value={projData[key1][index][5]}
                                                            onChange=  {(event) => archiveProject(event, item[1], index, typeP)}
                                                            className="editSelect"
                                                        >
                                                            <option value="Select status"> Select status </option>
                                                            <option value = "In progress">  In progress  </option>
                                                            <option value = "Finished"> Finished </option>
                                                            <option value = "On hold"> On hold </option>
                                                       </select>
                                                  :null}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                              </Box>
                            :null
                            }
                    </Collapse>
                </Box>
            </Paper>
       </>
    );
};

export default ArchivedTableRows;