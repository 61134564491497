import {Box, Button, Divider, Typography, label, textarea} from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import React, {useContext, useState} from "react";
import MainContext from "../../context";
import {grey} from "@mui/material/colors";

const MentionContent = () => {
    const {Staff, mentionOption, projData, projList, selected, setSelected, postContent, setPostContent,sendMention} =  useContext(MainContext);

    let taskName = projData[mentionOption.table][mentionOption.row][1];
    let projectName = "#" +  projList[mentionOption.table][3] + "-" +  projList[mentionOption.table][2] + "-" + projList[mentionOption.table][8] + "'"

    let options = []
    for (let j = 0; j < Staff.length; j++) {
        let key = { label: "@" + Staff[j][0].substring(0, (Staff[j][0]).indexOf(" ")), value: Staff[j][0] }
        options.push(key);
    }

    return(
        <>
            <Box
                sx={{ height: "100vh", overflow: "hidden",  justifyContent: "center", backgroundColor: "#ffffff" , pl : 3}}
            >
                {/*<SidebarTabs />*/}
                {mentionOption.table + " / " + mentionOption.row}
                <Box sx = {{mt: "20%"}}>
                   <Typography> Note Information </Typography>
                   <Divider  sx = {{mr: 2, borderColor:grey[400]}} />
                    <Box  sx={ {mt: 2, color: grey[800], fontSize: "0.70rem"}}>
                        <Typography variant="caption" sx = {{color: "#626060", fontWeight: 700}} > Enter your note: </Typography><br />
                        <textarea
                            name="postSubject"
                            defaultValue={"Task '" + taskName.toString() + "' of project '" + projectName.toString()}
                            rows={10}
                            cols={61}
                            sx = {{pt:"15px" }}
                        />
                        <textarea
                            name="postContent"
                            className = "postContent"
                            //defaultValue={"Task '" + taskName.toString() + "' of project '" + projectName.toString() + "' is updated!"}
                            rows={30}
                            cols={61}
                            // style = {{pt:"15px" , height: "50px"}}
                            value={postContent} // ...force the input's value to match the state variable...
                            onChange={e => setPostContent(e.target.value)} //
                        />
                    </Box>
                    <Box sx={{marginLeft: "10px"}}>
                        <Box  sx={ {color: grey[800], fontSize: "0.70rem", width: "93%"}}>
                            <MultiSelect
                                options={options}
                                value={selected}
                                onChange={setSelected}
                                labelledBy={"Select"}
                                isCreatable={true}
                            />
                        </Box>
                        <Box sx={ {mt: 2, }}>
                            <Button
                                className="editBtn"
                                onClick=  {(event) => sendMention(event)}
                            >
                                Send
                            </Button>
                        </Box>
                    </Box>

                </Box>
            </Box>

        </>
    );
};

export default MentionContent;