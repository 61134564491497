import {Box, Button, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, {useContext, useState} from "react";
import axios, {AxiosError} from "axios";
import MainContext from "../../context";
import {tabsData} from "../../components/data/tabsData.sidebar";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import {useAuthUser} from "react-auth-kit";

const NewUser = () =>{
    const auth = useAuthUser()
    const {setError, Staff, setStaff, setSnackContent, setSnackOpen} = useContext(MainContext);
    const [newUser, setNewUser] = useState({
        name: "",
        email: "",
        position: "",
        group: "",
        manager:"No",
        admin:"No",
        managerGroup:"",
        username:"",
        password:"",
    })

    const Groups = tabsData()[3];
    const roles = tabsData()[4];
    const setNewUserInfo = (event) => {
        setNewUser({...newUser, [event.target.name]: event.target.value});
    }

    const RegisterUser = async(event) =>{
        event.preventDefault()
        setError("");
        try {
            const response = await axios.post(auth().website + "/RegisterStaff",
                newUser
            );
            if (response.data === "new staff registered"){
                let data = [...Staff]
                data.push([newUser.name, newUser.group, newUser.position])

                setStaff(data)
                setSnackContent("Confirmation: New staff registered successfully!")
                setSnackOpen(true);
            }else{
                setSnackContent("Warning: staff registration was not successful!")
                setSnackOpen(true);
            }
            setNewUser({["name"]: "",["email"]: "",["position"]: "",["group"]: "",["manager"]: "No",["admin"]: "No",["managerGroup"]: "",["username"]: "", ["password"]:"" ,})
        } catch (err) {
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);
        }
    }

    return(
        <form onSubmit={RegisterUser}>
            <Box sx={ {m:5}}>
                <Grid container sx={{mb:1}}>
                    <Grid xs={12}>
                        <h5 className= "headerClass"  > Creat new user account </h5>
                    </Grid>
                </Grid>
                <Box sx={ {mt:5}}>
                    <Grid container sx={{mb:1}}>
                        <Grid xs={3}>
                            <Typography variant="subtitle2" sx={{fontWeight: 700,pt: "5px"}}>Name & Family:</Typography>
                        </Grid>
                        <Grid xs={8}>
                            <input
                                type="text"
                                value={newUser.name}
                                onChange= {(event) => setNewUserInfo(event)}
                                name="name"
                                className="selectOption"
                                style={{textAlign:"left", width: "100%", backgroundColor: "whitesmoke", padding: "10px"}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{mb:1}}>
                        <Grid xs={3}>
                            <Typography variant="subtitle2" sx={{fontWeight: 700,pt: "5px"}}>Email address:</Typography>
                        </Grid>
                        <Grid xs={8}>
                            <input
                                type="email"
                                value={newUser.email}
                                onChange=  {(event) => setNewUserInfo(event)}
                                name="email"
                                className="selectOption"
                                style={{textAlign:"left", width: "100%",  backgroundColor: "whitesmoke", padding: "10px"}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{mb:1}}>
                        <Grid xs={3}>
                            <Typography variant="subtitle2" sx={{fontWeight: 700,pt: "5px"}}> Role: </Typography>
                        </Grid>
                        <Grid xs={8}>
                            <select
                                name="position"
                                value={newUser.position}
                                onChange=  {(event) => setNewUserInfo(event)}
                                className="selectOption"
                                style={{width : "100%", fontWeight: 400, backgroundColor: "whitesmoke", padding: "12px"}}
                            >
                                <option value="">  </option>
                                {roles.map((role) => (
                                    <option key={role} value={role}>
                                        {role}
                                    </option>
                                ))}
                            </select>

                        </Grid>
                    </Grid>
                    <Grid container sx={{mb:1}}>
                        <Grid xs={3}>
                            <Typography variant="subtitle2" sx={{fontWeight: 700, pt: "5px"}}> Group: </Typography>
                        </Grid>
                        <Grid xs={8}>
                            <select
                                name="group"
                                value={newUser.group}
                                onChange=  {(event) => setNewUserInfo(event)}
                                className="selectOption"
                                style={{width : "100%", fontWeight: 400, backgroundColor: "whitesmoke", padding: "12px"}}
                            >
                                <option value="">  </option>
                                {Groups.map((group) => (
                                    <option key={group} value={group}>
                                        {group}
                                    </option>
                                ))}
                            </select>
                        </Grid>
                    </Grid>

                    <Grid container sx={{mb:1}}>
                        <Grid xs={3}>
                            <Typography variant="subtitle2" sx={{fontWeight: 700,pt: "10px"}}> Manager: </Typography>
                        </Grid>
                        <Grid xs={8}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="manager"
                                    disabled
                                    value={newUser.manager}
                                    onChange= {(event) => setNewUserInfo(event)}
                                    sx={{pl: 2, pt:1}}
                                    defaultValue="No"
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} size = "small"   label="Yes" />
                                    <FormControlLabel sx={{pl:3}} value="No" control={<Radio />} size = "small"   label="No" />
                                </RadioGroup>
                            </FormControl>
                            <Box>
                                <select
                                    name="managerGroup"
                                    value={newUser.managerGroup}
                                    onChange=  {(event) => setNewUserInfo(event)}
                                    className="selectOption"
                                    style={{width : "100%", fontWeight: 400, backgroundColor: "whitesmoke", padding: "10px",
                                        display: (newUser.manager === "Yes") ? "block" : "none"
                                    }}
                                >
                                    <option value="">  </option>
                                    {Groups.map((group) => (
                                        <option key={group} value={group}>
                                            {group}
                                        </option>
                                    ))}
                                </select>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container sx={{mb:1}}>
                        <Grid xs={3}>
                            <Typography variant="subtitle2" sx={{fontWeight: 700,pt: "15px"}}> Admin: </Typography>
                        </Grid>
                        <Grid xs={8}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="admin"
                                    disabled
                                    value={newUser.admin}
                                    onChange= {(event) => setNewUserInfo(event)}
                                    sx={{pl: 2, pt:2, pb:1}}
                                    defaultValue="No"
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} size = "small"   label="Yes" />
                                    <FormControlLabel sx={{pl:3}} value="No" control={<Radio />} size = "small" label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container sx={{mb:1}}>
                        <Grid xs={3}>
                            <Typography variant="subtitle2" sx={{fontWeight: 700,pt: "5px"}}> Username: </Typography>
                        </Grid>
                        <Grid xs={8}>
                            <input
                                type="text"
                                value={newUser.username}
                                onChange=  {(event) => setNewUserInfo(event)}
                                name="username"
                                className="selectOption"
                                style={{textAlign:"left", width: "100%",  backgroundColor: "whitesmoke", padding: "10px"}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{mb:1}}>
                        <Grid xs={3}>
                            <Typography variant="subtitle2" sx={{fontWeight: 700,pt: "5px"}}> Password: </Typography>
                        </Grid>
                        <Grid xs={8}>
                            <input
                                type="text"
                                value={newUser.password}
                                onChange=  {(event) => setNewUserInfo(event)}
                                name="password"
                                className="selectOption"
                                style={{textAlign:"left", width: "100%",  backgroundColor: "whitesmoke", padding: "10px"}}
                            />
                        </Grid>
                    </Grid>

                    <Box sx = {{textAlign: "right", pb: 1, mt: 5}} id = "calender">
                        <Button
                            className="editBtn"
                            sx={{p: "12px", backgroundColor: "#153250"}}
                            type="submit"
                        >
                            Create account
                        </Button>
                    </Box>


                </Box>
            </Box>


        </form>
    );
}

export default NewUser;