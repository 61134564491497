import * as React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function FixedTags() {
    const fixedOptions = [top100Films[6]];
    const [value, setValue] = React.useState([]);
    const setSelect = (event, newValue) => {
        setValue([
            ...newValue.filter((option) => option),
        ]);
        console.log("newValuenewValue")
        console.log(newValue)
    }

    return (
        <Autocomplete
            multiple
            id="fixed-tags-demo"
            value={value}
            // onChange={(event, newValue) => {
            //     setValue([
            //         ...newValue.filter((option) => option),
            //     ]);
            // }}

            onChange=  {(event, newValue) => setSelect(event, newValue)}

            options={top100Films}
            getOptionLabel={(option) => option}

            // renderTags={(tagValue, getTagProps) =>
            //     tagValue.map((option, index) => {
            //         const { key, ...tagProps } = getTagProps({ index });
            //         return (
            //             <Chip
            //                 key={key}
            //                 label={option.title}
            //                 {...tagProps}
            //             />
            //         );
            //     })
            // }

            ListboxProps={{
                sx: { fontSize: 10 },
            }}
            sx={{
                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                    fontSize: 12,
                },
            }}

            //style={{ width: 500 }}
            renderInput={(params) => (
                <TextField {...params}  variant="standard" size="small" />
            )}
        />
    );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [1,2, 3, 4, 5];