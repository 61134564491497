import {
    HomeRounded,
    FaceRounded,
    TextSnippetRounded,
    TerminalRounded,
    MessageRounded,
    ConnectWithoutContactRounded,
} from "@mui/icons-material";

import axios, {AxiosError} from "axios";
import {length} from "stylis";

const tabProps = (index) => {
    return {
        id: `sidebar-tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
};

function convertLocalToUTCDate(date) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return date
}

export const tabsData = () => {
    const tabs = [
        { label: "My area",index: 0},
        { label: "DOA - Airworthiness ", index: 1 },
        { label: "DOA - ISM", index: 2 },
        { label: "DOA - Projects", index: 3 },
        { label: "DOA - Proposals",index: 4 },
        { label: "POA - Quality System", index: 5 },
        { label: "POA - Projects", index: 6 },
        { label: "R & D",index: 7},
        { label: "Management Dashboard",index: 8},
        { label: "Management Report", index: 9},
        { label: "Performance",index: 10},
        { label: "Admin",index: 11},
    ];
    const group = ["HDO", "Airworthiness","Structure","Mechanics","Avionics","ISM","Production", "IT"];

    const roles = ["Head of Design Organization (HDO)", "Management Consultant HDO", "Head of Office of Airworthiness (HOA)", "Head of Independent System Monitoring (HISM)"
        ,"Compliance Verification Engineer (CVE)", "Structural Design Engineer (DE)", "Cabin Safety Design Engineer (DE)", "Avionics & Electronics Design Engineer (DE)", "Production Manager (PM)",
        "Mechanics Design Engineer (DE)", "Production Engineer (PE)", "Production Inspector (PI)", "Safety Manager (SM)", "IT Manager (IT)", "Independent System Monitoring staff (ISM)", "Office of Airworthiness Personnel (OAP)"]



    const staff = [
        { name: "Navid",family: "SeyedAli", position: "Head of Design Organization (HDO)", group: "structure"},
        { name: "Davoud",family: "Hassanzadeh",position: "Head of Office of Airworthiness (HOA)", group: "airworthiness"},
        { name: "Hossein",family: "Rashedi",position:"Head of Independent System Monitoring (HISM)" , group: "structure"},
        { name: "Mahyo",family: "Beazer",position: "Compliance Verification Engineer (CVE)", group: "structure"},
        { name: "Zahra",family: "Bayat",position: "Structural Design Engineer (DE)", group: "structure"},
        { name: "Sadegh",family: "Hassanzadeh",position: "Cabin Safety Design Engineer (DE)" , group: "mechanic"},
        { name: "Mitra",family: "Yadollahi",position: "Cabin Safety Design Engineer (DE)", group: "mechanic"},
        { name: "Hamed",family: "Aslanian",position: "Avionics & Electronics Design Engineer (DE)", group: "Avionics"},
        { name: "Mina",family: "Emami",position: "Avionics & Electronics Design Engineer (DE)", group: "Avionics"},
        { name: "Faranak",family: "Seyedghasem",position: "Independent System Monitoring staff (ISM)", group: "airworthiness"},
        { name: "Somayeh",family: "Soudmand",position: "Office of Airworthiness Personnel (OAP)", group: "structure"},
    ];

    const holidays = [
       "2024-01-25", "2024-02-11", "2024-02-25", "2024-03-19","2024-03-20", "2024-03-21","2024-03-22", "2024-03-23",
       "2024-03-31", "2024-04-01", "2024-04-10","2024-04-11", "2024-05-04","2024-06-03","2024-06-04", "2024-06-17",
       "2024-06-25", "2024-07-15", "2024-07-16", "2024-08-25", "2024-09-02", "2024-09-04", "2024-09-12", "2024-09-21",
    ]
    // let holis = []
    // for (let i=0; i< holidays.length; i++){
    //     holis.push((convertLocalToUTCDate(holidays[i])).toString());
    // }
    return [tabs, staff, holidays, group, roles];
};
