import React,{useState} from 'react';
import {Container, Box, Button, Typography} from "@mui/material";
import MainContext from "../context";
import {grey} from "@mui/material/colors";
import { ThemeProvider } from "@mui/material/styles";
import {theme} from "../layouts/theme";
import {useSignIn} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
import axios, {AxiosError} from "axios";
import { tabsData } from "../components/data/tabsData.sidebar";

const Login = () => {
    const [error, setError] = useState("");
    const signIn = useSignIn();
    const navigate = useNavigate();
    const [values,setValues] = useState({
        username: "",
        password:""
    });
    const data = tabsData()[1];
    const handleUser = (event) => {
        setValues({...values, [event.target.name]: event.target.value,});
    }


    const ValidateUser = async (e) =>
    {
        e.preventDefault()
        setError("");
        try {
            const response = await axios.post("https://app.sca-co.com/sca/Gettoken",
                values
            );
            signIn({
                token: response.data.token,
                expiresIn: 3600,
                tokenType: "Bearer",
                authState: { user: values.username , token: response.data.token, user_record: response.data.info, website: "https://app.sca-co.com/sca" },
            });
            navigate("/ProjectManagement");
        } catch (err) {
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);

            console.log("Error: ", err);
        }
    };

    return(
        <MainContext.Provider value={{values}}>
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        height: "100vh",
                        overflowY: "scroll",
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: grey[300],
                    }}
                >
                    <Container>
                        <Box sx = {{ margin: "15% auto", boxShadow: "0 0 10px 5px #0000002e",maxWidth: "550px",  backgroundColor: "#ffffff", borderRadius: "5px"}}>
                            <Box>
                                <Box className="form-wrap">
                                    <Box sx={ {textAlign: "center",pt: "10px", pb: "15px"}}>
                                        <img
                                            src={require("../assets/login.png")}
                                            alt="SCA"
                                            style={{width: "60%",  }}
                                        />
                                    </Box>
                                    <form onSubmit={ValidateUser}>
                                        <div className="row" style = {{textAlign: "left"}}>
                                            <Typography htmlFor="userName" sx = {{fontSize: "0.70rem"}}>User Name</Typography>
                                            <input type="text" name="username" autoComplete="off" style={{width: "95%"}}
                                                   placeholder="User Name" value={values.username} onChange={handleUser} />
                                        </div>
                                        <div className="row">
                                            <Typography htmlFor="password" sx = {{fontSize: "0.70rem"}}>Password</Typography>
                                            <input type="password" name="password" style={{width: "95%"}} placeholder= "***"  value={values.password} onChange={handleUser} />

                                        </div>
                                        <Box  sx = {{textAlign: "center", mt: 3,}}>
                                            <Button variant="contained" className="my-button"  type="submit"
                                                    sx = {{
                                                        width: "50%", fontWeight: 700,
                                                    }}> Login  </Button>
                                        </Box>
                                    </form>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </ThemeProvider>
        </MainContext.Provider>
    );
}
export default Login;