import {Box, Button, Paper,Table, TableBody,TableCell,TableContainer,TableHead,TableRow,Typography,Menu, MenuItem, IconButton,} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {AddLink, ArrowDropDownRounded,ArrowRightRounded, CreateOutlined, DeleteOutlineRounded , AlternateEmailRounded} from '@mui/icons-material';
import { MultiSelect } from "react-multi-select-component";

import {grey} from "@mui/material/colors";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { useContext, useState, useEffect, forwardRef} from "react";
import MainContext from "../../context";

import {Progressbar} from "./index"
import DatePicker from 'react-datepicker';

import {subDays, format, addDays} from "date-fns";
import { tabsData } from "../../components/data/tabsData.sidebar";
import {useAuthUser} from "react-auth-kit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';



function TableRows({ key1, indexes}) {
    const {subTasks, setEditedTasks, editedTasks, maxOrder, setMaxOrder, tempId, setTempId, excludeIntervals, excludeCritical, partialIntervals, partialCritical, taskTitle, openElemRow, setOpenElemRow,openElem, setOpenElem, handleDateChange, datePickerClick, setDialogOpen, setDialogContent , setProjectAnchorEl, deleteInfo, setClickedRow, Staff, userInfo, setProjData, setDeleteInfo,editTask2, anchorEl, setAnchorEl, headCells, projList, projData, onValUpdate, makeMention } = useContext(MainContext);
    const ITEM_HEIGHT = 48;
    const auth = useAuthUser();
    const holiDays = tabsData()[2];

    const [selectedID, setSelectedID] = useState(null);
    if (indexes.length > 0)
    {
        return indexes.map((rowsIndex, ind) =>
        {
            let index = indexes[ind];
            let date1 = new Date();
            let temp1 = date1.getTime();

            let date3 = new Date((projData[key1][index][4]));
            let temp3 =  date3.getTime();
            let timeDiff2 = temp3- temp1;

            let taskBackColor = "1px solid #8080801c";
            if ((parseInt(projData[key1][index][8]) < 100) && (date3 <= date1)) {
                taskBackColor = "3px solid #da0f27";
            }else if ((parseInt(projData[key1][index][8]) < 50) && (subDays(date3, 1) <= date1)){
                taskBackColor = "3px solid #e9963b";
            }else if (parseInt(projData[key1][index][8]) === 100){
                taskBackColor = "3px solid #ADD3D0";
            }

            let taskStyle = {
                width: '30%',
                borderLeft: taskBackColor,
                borderBottom: "1px solid #8080801c",
            }

            let color = "transparent";
            let fontcolor = "#ffffff";
            if (projData[key1][index][3] === "Done"){
                //color = "#5aab89";
                color =  "#00c875";
            }else if (projData[key1][index][3] ==="In progress"){
                color = "#D1913E";
            }else if (projData[key1][index][3] ==="Stuck"){
                color = "#A92420"
            }else if (projData[key1][index][3] ==="Not started"){
                //fontcolor = "#757575";
                //color = "#afabaa";
                color = "#b5b4b4"
            }
            let color_critical = "transparent";
            if (projData[key1][index][5] === "Critical"){
                //color_critical = "#deb277";
                color_critical = "#401694"
            }else if (projData[key1][index][5] ==="High"){
                color_critical = "#d1913e";
            }else if (projData[key1][index][5] ==="Medium"){
                //color_critical = "#ecd3b1";
                color_critical = "#579bfc";
                //color_critical = "#5559df";
            }else if (projData[key1][index][5] ==="Low"){
                color_critical = "#faf4eb";
            }

            let limitClass = false;
            if (projData[key1][index][5] === "Critical"){
                limitClass = true
            }

            const isWeekday = (date) => {
                const day = date.getDay();
                let doesExist  =  holiDays.includes(format(date, 'yyyy-MM-dd'))
                let previousDate = format(date, 'yyyy-MM-dd')  < format(new Date(), 'yyyy-MM-dd')  ? true: false
                return day !== 4 && day !== 5 && !doesExist && !previousDate;
            };

            const insertMiddleTask = () =>
            {
                let table = deleteInfo.projectId;
                let row= deleteInfo.taskId;
                let data2 = [...projData];
                //let preindex = data2[table][row][12];

                setClickedRow(projData[table][row][0]);

                let data3 = [...tempId]
                data3[table] += 1
                let data4 = [...maxOrder]
                data4[table] += 1

                data2[table].splice(row, 0, [data3[table], "", "","", "", "",0, 0, 0, "","" ,"", 0, "", (data4[table]).toString()]);
                setProjData(data2);
                setTempId(data3)
                setMaxOrder(data4)
                setAnchorEl(null);
                setOpenElem(null);
                setOpenElemRow(null);
            }

            const ExampleCustomInput = forwardRef(({value, onClick, onChange}, ref) => (
                <input
                    value={value}
                    className="example-custom-input"
                    onClick={onClick}
                    onChange={onChange}
                    ref={ref}
                />
            ));

            const handleDialogOpen = () => {
                if (deleteInfo.taskId === -1) {
                    setProjectAnchorEl(null);
                }
                setDialogOpen(true);
                setAnchorEl(null);
                setOpenElem(null);
                setOpenElemRow(null)
            };

            const handleClick = (elem, elem2) => (event) => {
                setAnchorEl(event.currentTarget);
                setOpenElem(elem);
                setOpenElemRow(elem2);
                setDeleteInfo({["projectId"]: elem , ["taskId"]: elem2});
                setDialogContent("Are you sure to delete the project task!");
            };

            const handleClose = () => {
                setAnchorEl(null);
                setOpenElem(null);
                setOpenElemRow(null)
            };

            function findAId(tableNum, id) {
                let index0 = projData[tableNum].findIndex(item => item[14].toString() === id.toString())
                return index0;
            }

            const setSelect = (projNum, tableRow, rowColumn, event, newValue) =>
            {
                const data = [...projData];
                const dataChanged = [...editedTasks];
                let changedId = dataChanged[projNum];

                let orderedDep = [...newValue.filter((option) => option),];
                let realDep = [];
                let ind = -1
                for (let i=0; i < orderedDep.length; i++){
                   ind = findAId(projNum, orderedDep[i]);
                   if (ind !== -1){
                       realDep.push(projData[projNum][ind][0])
                   }
                }
                if (!(changedId.includes(tableRow)))
                {
                    changedId.push(tableRow)
                }
                //data[projNum][tableRow][rowColumn] = {'item': [...newValue.filter((option) => option),]}
                data[projNum][tableRow][rowColumn] = {'item': realDep}
                setProjData(data)

                dataChanged[projNum] = changedId;
                setEditedTasks(dataChanged);
            }

            function findId(tableNum, id)
            {
                let orderedId = -1;
                let index0 = projData[tableNum].findIndex(item => item[0].toString() === id.toString())
                if (index0 !== -1)
                {
                    orderedId = projData[tableNum][index0][14]
                }
                return orderedId;
            }

            function getOptions(key1, index0)
            {
                let optionList = [];
                for (let i=0; i < projData[key1].length; i++)
                {
                    if ((!((projData[key1][i][14].toString()).includes("."))) &&  ((Math.floor(Number(projData[key1][index0][14]))).toString() !== projData[key1][i][14].toString())  && (projData[key1][i][14].toString() !== projData[key1][index0][14].toString()  )){
                        optionList.push(projData[key1][i][14])
                    }
                }
                return optionList;
            }
            function findOptions(key1, index0){
                let orderedId = [];
                let indRow = -1;
                let realId = -1;
                for (let i=0; i < (projData[key1][index0][10].item).length; i++)
                {
                    realId = (projData[key1][index0][10].item)[i];
                    indRow = projData[key1].findIndex(item => item[0].toString() === realId.toString())
                    if (indRow !== -1)
                    {
                        orderedId.push(projData[key1][indRow][14])
                    }
                }
                return orderedId;
            }

            let keyIndex = key1.toString() + ind.toString()
            return(
                    <TableRow

                        key={"TR" + keyIndex}
                        onClick={() => {setSelectedID("TR" + keyIndex);}}
                        className={(selectedID === "TR" + keyIndex ) ? "selectedRow": null}
                    >
                        <TableCell  sx = {taskStyle}>
                            <div key={keyIndex}>
                                <IconButton
                                    aria-label="more"
                                    aria-controls={"long-menu" + key1 + ind}
                                    aria-haspopup="true"
                                    sx = {{padding: 0 }}
                                    onClick={handleClick(key1, index)}
                                    id="long-button"
                                >
                                    <MoreVertIcon sx={{color: "#6e6b6b80", height: "1.4rem"}}/>
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    open={(openElem === key1) && (openElemRow === index)}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '20ch',
                                        },
                                    }}
                                >
                                    {/*<MenuItem*/}
                                    {/*    key = {keyIndex + "104"}*/}
                                    {/*    onClick={(event) => editTask2()}*/}
                                    {/*    disabled={!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") || (userInfo.name === projData[key1][index][2]) )}*/}
                                    {/*   // disabled={(!((userInfo.name === projList[key1][4]) || (userInfo.admin === "Yes")  || (userInfo.name === projData[key1][index][2])))}*/}
                                    {/*>*/}
                                    {/*    <CreateOutlined/>*/}
                                    {/*</MenuItem>*/}

                                    <MenuItem
                                        key = {keyIndex + "105"}
                                        onClick = {(event) => handleDialogOpen()}
                                        disabled={!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") )}

                                        //disabled={!(userInfo.name === projList[key1][4] || (userInfo.admin === "Yes"))}
                                    >
                                        <DeleteOutlineRounded   />
                                    </MenuItem>

                                    <MenuItem
                                        key = {keyIndex+ "106"}
                                        onClick=  {(event) => insertMiddleTask()}
                                        disabled={!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") )}

                                       // disabled={(!((userInfo.name === projList[key1][4]) || (userInfo.admin === "Yes") ))}
                                    >
                                        <AddLink   />
                                    </MenuItem>

                                    <MenuItem
                                        key = {keyIndex + "107"}
                                        onClick=  {(event) => makeMention()}
                                        disabled={!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") || (userInfo.name === projData[key1][index][2]) )}

                                        //disabled={(!((userInfo.name === projList[key1][4]) || (userInfo.admin === "Yes") || (userInfo.name === projData[key1][index][2])))}
                                    >
                                        <AlternateEmailRounded   />
                                    </MenuItem>
                                </Menu>
                            </div>
                        </TableCell>
                        <TableCell className ="taskInput" width = {headCells[0].width} >
                            <Typography
                                variant = "caption"
                                sx = {{ color: "#626060", width: headCells[0].width, display: "block"}}
                            >
                                {projData[key1][index][14]}
                            </Typography>
                        </TableCell>

                        <TableCell className ="taskInput" width = {headCells[1].width} >
                            <select
                                key = {keyIndex + 1}
                                name="task"
                                value={projData[key1][index][1]}
                                onChange=  {(event) => onValUpdate(key1, index, 1, event)}
                                className="selectOption"
                                style={{width: headCells[1].width}}
                                disabled={!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") )}
                               // disabled={ (!((userInfo.name === projList[key1][4]) || (userInfo.admin === "Yes")) )}
                            >
                                <option value="">  </option>
                                {taskTitle.map((task, ind0) => (
                                    <option key={ind0.toString() + keyIndex + 1} value={task}>
                                        {task}
                                    </option>
                                ))}
                            </select>
                        </TableCell>

                        <TableCell className ="taskInput" width = {headCells[2].width} >
                            <select
                                key = {keyIndex + 13}
                                name="hostTask"
                                value={projData[key1][index][13] ? findId(key1, projData[key1][index][13]) : ""}
                                onChange=  {(event) => onValUpdate(key1, index, 13, event)}
                                className="selectOption"
                                style={{width: headCells[2].width}}
                                disabled={!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes")) ||  ((projData[key1][index][0]).toString() in subTasks[key1])  }
                            >
                                <option value="">  </option>
                                projData[key1].map(i => i[0])

                                {projData[key1].map((id, ind0) => (
                                    <option key={ind0.toString() + keyIndex + 2} value= {projData[key1][ind0][14]}
                                            disabled={((projData[key1][ind0][14].toString()).includes(".")) || (projData[key1][ind0][14].toString() === projData[key1][index][14].toString())}
                                    >
                                        {projData[key1][ind0][14]}
                                    </option>
                                ))}
                            </select>
                        </TableCell>



                        <TableCell className ="taskInput" width = {headCells[3].width}>
                            <select
                                key = {keyIndex + 2}
                                name="owner"
                                value={projData[key1][index][2]}
                                onChange=  {(event) => onValUpdate(key1, index, 2, event)}
                                className="selectOption"
                                style={{width: headCells[3].width}}
                                disabled={!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") ) ||  ((projData[key1][index][0]).toString() in subTasks[key1])  }
                                //disabled={(projData[key1][index][0]).toString() in subTasks[key1]}
                            >
                                <option value="">  </option>
                                {Staff.map((member, ind0) => (
                                    <option key={ind0.toString() + keyIndex + 2} value={member[0]}>
                                        {member[0].substring(0, (member[0]).indexOf(" "))}
                                    </option>
                                ))}
                            </select>
                        </TableCell>

                        <TableCell className ="taskInput" width = {headCells[4].width} sx={{backgroundColor: color_critical}}>
                            <select
                                key = {keyIndex + 3}
                                name="priority"
                                value={projData[key1][index][5]}
                                onChange=  {(event) => onValUpdate(key1, index, 5, event)}
                                className="selectOption myClass"
                                style = {{color: fontcolor, width: headCells[4].width}}
                                disabled={!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") ) ||  ((projData[key1][index][0]).toString() in subTasks[key1]) }
                            >
                                <option value=""> </option>
                                <option value = "Critical">  Critical  </option>
                                {/*<option value = "High"> High </option>*/}
                                <option value = "Medium"> Medium </option>
                                {/*<option value = "Low"> Low </option>*/}
                            </select>
                        </TableCell>

                        <TableCell className ="taskInput" width = {headCells[5].width}>
                            <input
                                key = {keyIndex + 4}
                                type="number"
                                value={projData[key1][index][6]}
                                onChange=  {(event) => onValUpdate(key1, index, 6, event)}
                                name="estimatedHours"
                                className="selectOption"
                                style = {{width : headCells[5].width }}
                                onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                min={0}
                                disabled={!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") )}
                            />
                        </TableCell>

                        <TableCell className ="taskInput" width = {headCells[6].width} key = {key1.toString() + ind.toString() + 5} >
                             <Autocomplete
                                multiple
                                id="fixed-tags-demo"
                                key = {keyIndex + 5}
                                //value= {projData[key1][index][10] !==  "" ? projData[key1][index][10].item : []}
                                value= {projData[key1][index][10] !== "" ? findOptions(key1, index) : []}

                                onChange=  {(event, newValue) => setSelect(key1, index, 10, event, newValue)}
                                //onChange=  {(event) => onValUpdate(key1, index, 13, event)}
                                options={getOptions(key1, index)}
                                //options={projData[key1].map(i => i[0])}
                                getOptionLabel={option => option.toString()}
                                ListboxProps={{
                                    sx: { fontSize: 10, padding: 0, backgroundColor: "#ffffff"},
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 10,
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                                disabled={!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") )}
                            />

                        </TableCell>

                        <TableCell className ="taskInput" sx={{width: headCells[7].width, fontSize: "0.75rem"}}
                                   key = {keyIndex + 6}
                                   onClick={(event) => datePickerClick (key1, index) }
                        >
                            <DatePicker
                                excludeDateIntervals = {limitClass ? excludeCritical :  excludeIntervals}
                                selected= {projData[key1][index][11] !== "" ? new Date(projData[key1][index][11]) : ""}
                                name="Start date"
                                //highlightDates={limitClass ? taskHighlight :  }
                                className = "selectOption"
                                customInput={<ExampleCustomInput />}
                                key = {keyIndex + 6}
                                onChange={(date) => handleDateChange(date,key1, index)}

                                dateFormat="MM/dd/yyyy HH:mm"
                                filterDate = {isWeekday}
                                holidays = { limitClass ? partialCritical : partialIntervals}
                                isClearable
                                sx={{width: headCells[7].width, fontSize: "0.60rem"}}

                                disabled = {(!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes")))  ||  ((projData[key1][index][0]).toString() in subTasks[key1])  ||  ((projData[key1][index][2] === "" || projData[key1][index][5] === "" || projData[key1][index][6] === 0) ? true : false)}
                            />

                        </TableCell>
                        <TableCell className ="taskInput" sx={{width: headCells[8].width, fontSize: "0.75rem"}}
                                   key = {keyIndex + 7}
                        >
                            <DatePicker
                                selected= {projData[key1][index][4] !== "" ? new Date(projData[key1][index][4]) : ""}
                                className = "selectOption"
                                customInput={<ExampleCustomInput />}
                                name="Due date"
                                key = {keyIndex + 7}
                                disabled
                                dateFormat="MM/dd/yyyy HH:mm"
                                sx={{width: headCells[8].width}}
                            />
                        </TableCell>
                        <TableCell className ="taskInput"  width = {headCells[9].width} sx = {{backgroundColor : color }}>
                            <select
                                key = {keyIndex + 8}
                                name="status"
                                value={projData[key1][index][3]}
                                onChange=  {(event) => onValUpdate(key1, index, 3, event)}
                                //className="selectOption"
                                className="selectOption myClass"
                                style = {{color: fontcolor, width: headCells[9].width}}
                                disabled={(!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") || (userInfo.name === projData[key1][index][2]) )) ||  ((projData[key1][index][0]).toString() in subTasks[key1])   }
                            >
                                <option value=""> </option>
                                <option value = "Done" > Done </option>
                                <option value = "In progress"> In progress </option>
                                <option value = "Stuck"  > Stuck </option>
                                <option value = "Not started"> Not started </option>
                            </select>
                        </TableCell>

                        <TableCell className ="taskInput" width = {headCells[10].width}>
                            <input
                                key = {keyIndex + 9}
                                type="number"
                                value={projData[key1][index][7]}
                                onChange = {(event) => onValUpdate(key1, index, 7, event)}
                                name="actualHours"
                                className="selectOption"
                                style = {{width : headCells[10].width }}
                                onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                min={0}
                                disabled={(!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") || (userInfo.name === projData[key1][index][2]))) || ((projData[key1][index][0]).toString() in subTasks[key1])}
                            />
                        </TableCell>
                        <TableCell className ="taskInput" width = {headCells[11].width}
                                   key = {keyIndex + 10}
                                   disabled={!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") || (userInfo.name === projData[key1][index][2])) || ((projData[key1][index][0]).toString() in subTasks[key1]) }
                        >
                            <Progressbar className ="selectOption"  tableNumber={key1} tableRow = {index}
                            />
                        </TableCell>

                        <TableCell className ="taskInput" width = {headCells[12].width}
                                   key = {keyIndex + 11}
                        >
                            <textarea
                                value= {projData[key1][index][9]}
                                className= "Title"
                                disabled={(!((!(Array.isArray(projList[key1][4])) && (userInfo.name === projList[key1][4])) || ((Array.isArray(projList[key1][4])) && (projList[key1][4].includes(userInfo.name)))  || (userInfo.admin === "Yes") || (userInfo.name === projData[key1][index][2]) )) }
                                onChange = {(event) => onValUpdate(key1, index, 9, event)}
                            />
                        </TableCell>

                    </TableRow>
            );
        });
    }
}


const TableRows2 = (props) => {
    const { index, indexes, item } = props;
    const {archiveProject, saveProject, projProgress, maxOrder, setMaxOrder, tempId, setTempId, handleDisagree, handleAgree,dialogContent, dialogOpen, setTemp, userInfo, setOpen, open, headCells, projData, setProjData, projList, setOpenModal} = useContext(MainContext);
    const handleClick = (id) => {
        setOpen((prevstate) => ({ ...prevstate, [id]: !prevstate[id]}));
    };

    const addRowTable = (e, tableNum) => {
        let data2 =[...projData];
        //let preindex = 0
        // if(data2)
        // {
        //     if (data2[tableNum])
        //     {
        //         let lastindex = data2[tableNum].length
        //         if (lastindex > 0)
        //         {
        //             preindex = data2[tableNum][lastindex - 1][0]
        //         }
        //     }
        // }

        let data3 = [...tempId]
        data3[tableNum] += 1
        let data4 = [...maxOrder]
        data4[tableNum] += 1

        data2[tableNum].push([data3[tableNum], "", "", "", "", "",0, 0, 0, "", "" ,"",0, "", (data4[tableNum]).toString()]);
        setProjData(data2);
        setTempId(data3)
        setMaxOrder(data4)
    };

    const editProject = (e , index) =>
    {
        setOpenModal(true);
        setTemp({["index"]:index, ["number"]: projList[index][1], ["title"]: projList[index][2], ["customer"]: projList[index][3], ["manager"]: projList[index][4] , ["code"]: projList[index][8], ["deadline"]: new Date(projList[index][5])});
    }

    return(
        <>
            <Paper sx={{width: '100%', }}>
                <Box sx = {{pt:1, pb:1}} >
                    <IconButton
                        onClick={() => handleClick(index)}
                        aria-label="expand"
                        size="small"
                        //color="primary"
                        sx={{color: "#153250"}}
                    >
                        {open[index] ? <ArrowDropDownRounded/>
                            :<ArrowRightRounded/> }
                    </IconButton>
                    <Typography variant="subtitle1" sx={{
                        display: "inline-block",
                        fontWeight: "bold",
                        color: "#153250"
                    }}>
                       #  {item[3]} - {item[2] }  - {item[8]}
                    </Typography>
                </Box>
                <Box>
                    <Collapse in={open[index]} timeout="auto" unmountOnExit>
                        {(open[index])?
                            <Box>
                                <Box
                                    sx={{mb: 3, ml: 1}}
                                >
                                    <Grid container sx = {{fontSize: "0.85rem", textAlign: "left", mt: 1, mb: 1,}} color = {grey[600] }>
                                        <Grid xs= {4}>
                                            <Typography  variant = "caption"  sx ={{fontWeight : 700, display: "inline-block", pl: 2, }}> Manager:</Typography>
                                            {Array.isArray(item[4]) ?
                                                item[4].map((temp, ind1) => {
                                                    return (
                                                        <Typography key={ind1} variant = "caption" sx ={{display: "inline-block" ,fontWeight : 400, pl: 1,}}> {temp.substring(0, (temp).indexOf(" "))} {"-"} </Typography>
                                                    );
                                                })
                                                :
                                                <Typography variant = "caption" sx ={{display: "inline-block" ,fontWeight : 400, pl: 1,}}> {item[4].substring(0, (item[4]).indexOf(" "))} </Typography>
                                            }
                                        </Grid>

                                        <Grid xs= {4}>
                                            <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}>  Start Date:</Typography>
                                            <Typography variant = "caption" sx ={{fontWeight : 400, display: "inline-block", pl: 1,}}> { item[6] !=="" ? format(new Date(item[6]), 'yyyy-MM-dd'):null } </Typography>
                                        </Grid>

                                        <Grid xs= {4}>
                                            <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}>  Progress:</Typography>
                                            <Typography variant = "caption" sx ={{fontWeight : 400, display: "inline-block", pl: 1,}}> {Math.round(projProgress[index][1] * 100) / 100} {" %" }</Typography>
                                        </Grid>

                                    </Grid>
                                    <Grid container  sx = {{fontSize: "0.85rem", textAlign: "left", mt: 1, mb: 1,}} color = {grey[600] }>
                                        <Grid xs= {4}>
                                            <Typography variant = "caption"  sx ={{fontWeight : 700, display: "inline-block", pl: 2, }}> Deadline:</Typography>
                                            <Typography variant = "caption" sx ={{display: "inline-block" ,fontWeight : 400, pl: 1,}}> {item[5]} </Typography>
                                        </Grid>
                                        <Grid xs= {4}>
                                            <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}>  Finish Date:</Typography>
                                            <Typography variant = "caption" sx ={{fontWeight : 400, display: "inline-block", pl: 1,}}> { item[7] !== "" ? format(new Date(item[7]), 'yyyy-MM-dd') : null }</Typography>
                                        </Grid>

                                        <Grid xs= {4}>
                                            <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}> Status:</Typography>
                                            <Typography variant = "caption" sx ={{fontWeight : 400, display: "inline-block", pl: 1,}}> { item[10] }</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <TableContainer sx={{textAlign: "center", mb: 2, backgroundColor: "#F0F2F3",  maxHeight: "500px"}}>
                                    <Table sx={{width: "100%" ,  maxHeight: "500px"}}>

                                        <TableHead id = "headCell" >
                                            <TableRow className ="myTasksRow">
                                                <TableCell className="taskInput" sx={{width: "20px", backgroundColor:"#ffffff",borderLeft: "unset"}}>
                                                    <Typography variant = "caption" sx={{width: "20px", display: "block"}}>  </Typography>
                                                </TableCell>
                                                {headCells.map((headCell) => (
                                                    <TableCell
                                                        className="taskInput"
                                                        key={headCell.id}
                                                        sx={{border: "unset", backgroundColor:"#ffffff", }}
                                                        width={headCell.width}
                                                    >
                                                        <Typography variant = "caption" width={headCell.width} sx={{fontWeight: 700, display: "block"}}>  {headCell.label}  </Typography>

                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRows
                                                key1={index}
                                                indexes = {indexes}
                                                key = {"TR1" + index.toString()}
                                            />

                                            <TableRow key = {"TR2" + index.toString()} sx={{backgroundColor: "#ffffff"}}>
                                                <TableCell colSpan={14} className="operationBtn">
                                                    <Button className="editBtn" sx={{m: "5px"}}
                                                        //key={"btn0" + key.toString()}
                                                            onClick={(e) => addRowTable(e, index)}
                                                            disabled={!((!(Array.isArray(projList[index][4])) && (userInfo.name === projList[index][4])) || ((Array.isArray(projList[index][4])) && (projList[index][4].includes(userInfo.name)))  || (userInfo.admin === "Yes"))}
                                                    >
                                                        + Add task
                                                    </Button>

                                                    <Button className="editBtn" sx={{m: "5px"}}
                                                            onClick={(e) => editProject(e, index)}
                                                        // disabled={!((userInfo.name === projList[index][4]) || (userInfo.admin === "Yes"))}
                                                            disabled={!((!(Array.isArray(projList[index][4])) && (userInfo.name === projList[index][4])) || ((Array.isArray(projList[index][4])) && (projList[index][4].includes(userInfo.name)))  || (userInfo.admin === "Yes"))}
                                                    >
                                                        Edit / Delete
                                                    </Button>

                                                    <Button className="editBtn" sx={{m: "5px"}}
                                                            onClick={(e) => saveProject(e, index)}
                                                    >
                                                        Save
                                                    </Button>

                                                    {/*<Button className="editBtn" sx={{m: "5px"}}*/}
                                                    {/*        onClick={(e) => archiveProject(e, index)}*/}
                                                    {/*>*/}
                                                    {/*    Close*/}
                                                    {/*</Button>*/}

                                                    <select
                                                        //value={projData[key1][index][5]}
                                                        onChange=  {(event) => archiveProject(event, item[1], index, "In progress")}
                                                        className="editSelect"
                                                        disabled={!((!(Array.isArray(projList[index][4])) && (userInfo.name === projList[index][4])) || ((Array.isArray(projList[index][4])) && (projList[index][4].includes(userInfo.name)))  || (userInfo.admin === "Yes"))}
                                                    >
                                                        <option value="Select status"> Select status </option>
                                                        <option value = "In progress">  In progress  </option>
                                                        <option value = "Finished"> Finished </option>
                                                        <option value = "On hold"> On hold </option>
                                                    </select>

                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            :null}
                    </Collapse>
                </Box>
            </Paper>
            <Box>
                <React.Fragment >
                    <Dialog
                        open={dialogOpen}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                        id = "dialog"
                    >
                        <DialogTitle>{"Attention"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {dialogContent}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="editBtn" sx={{p: "12px", backgroundColor: "#153250"}} onClick={handleDisagree}>Cancel</Button>
                            <Button className="editBtn" sx={{p: "12px", backgroundColor: "#153250"}} onClick={handleAgree}>Ok</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </Box>
        </>
    );
};

export default TableRows2;