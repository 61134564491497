import React from "react";
import { ViewMode, Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { InitTasks } from "./helper";
import {Box, Typography} from "@mui/material";

const GuanttChart = ({Cat}) => {
    const [view, setView] = React.useState(ViewMode.Day);
    const tasks = InitTasks({Cat});
    const [isChecked, setIsChecked] = React.useState(true);

    let columnWidth = 50;
    if (view === ViewMode.Month)
    {
        columnWidth = 200;
    } else if (view === ViewMode.Week)
    {
        columnWidth = 150;
    }

    if (tasks.length > 0){
        return (
            <>
                <div id = "guantchart">
                    <Gantt
                        tasks={tasks}

                        listCellWidth={isChecked ? "155px" : ""}
                        //ganttHeight={300}
                        columnWidth={columnWidth}
                    />
                </div>
            </>
        );
    }else{
        return (
            <Box sx = {{ margin: "15% auto", boxShadow: "0 0 10px 5px #0000002e",maxWidth: "600px", textAligh: "center", borderRadius: "3px"}} >
                <Box sx = {{textAlign: "center", p : 3}}>
                    <Typography  > There is no task yet! </Typography>
                </Box>
            </Box>
        );
    }

};
export default GuanttChart;
