import {createContext, useState} from "react";
export default createContext ({
    result: false,
    drawerOpen : false,
    setDrawerOpen: () => {},
    guides: true,
    setGuides: () => {},
    fullGuideLine: () => {},
    projectInfoUpdate: () => {},
    projName:"",
    setProjName: () => {},
    projData: [],
    setProjData: () => {},
    projList: [],
    setProjList: () => {},
    onValUpdate: () => {},
    currentDate: "",
    mentionOption:"",
    setMentionOption: () => {},
    makeMention: () => {},
    mentionOpen:"",
    setMentionOpen: () => {},
    selected:[],
    setSelected: () => {},
    postContent:"",
    setPostContent: () => {},
    sendMention: () => {},
    statusOptions: [],
    type:"",
    setType: () => {},
    setProjectInfo: () => {},
    headCells:[],
    Category:[],
    open:"",
    setOpen: () => {},
    tabClick: () => {},
    Staff: [],
    setStaff: () => {},
    anchorEl:"",
    setAnchorEl: () => {},
    dialogOpen:"",
    setDialogOpen: () => {},
    projectAnchorEl:"",
    setProjectAnchorEl: () => {},
    snackOpen:"",
    setSnackOpen: () => {},
    setSnackContent: () => {},

    handleSnackClose: () => {},
    snackContent:"",
    editTask2: () => {},
    deleteInfo:"",
    setDeleteInfo: () => {},
    projectInfoUpdate2: () => {},
    dialogContent:"",
    setDialogContent: () => {},
    elRefs:"",
    setElRefs: () => {},
    targetRefIndex:"",
    setTargetRefIndex: () => {},
    userInfo:"",
    setUserfInfo: () => {},
    openModal:"",
    setOpenModal: () => {},
    TemporaryProject: () => {},
    setError: () => {},
    confirmedOffDate:[],
    setConfirmedOffDate: () => {},
    declinedOffDate:[],
    setDeclinedOffDate: () => {},
    waitingOffDate:[],
    setWaitingOffDate: () => {},
    offSiteDates:[],
    setOffSiteDates: () => {},
    timeSheet:[],
    setTimeSheet: () => {},
    totalHours:0,
    setTotalHour: () => {},
    overTime:0,
    setOvertime: () => {},
    checkInOutTable: () => {},
    checkinBtn:false,
    checkoutBtn:false,
    checkinDate:new Date(),
    setCheckinDate: () => {},
    checksInput:[],
    temp:{},
    setTemp: () => {},
    setProjectData: () => {},
    changeID: [],
    setChangeID: () => {},
    setChecksInput: () => {},
    setCheckoutBtn: () => {},
    setClickedRow: () => {},
    clickedRow: "",
    criticalItem: false,
    setCriticalItem: () => {},
    highItem: false,
    setHighItem: () => {},
    lowItem: false,
    setLowItem: () => {},
    dialogOpenTime: false,
    setDialogOpenTime: () => {},
    dedicatedRow:[],
    setDedicatedRow: () => {},
    handleDateChange: () => {},
    handleDisagree: () => {},
    handleAgree: () => {},
    datePickerClick: () => {},
    handleOffRequest: () => {},
    openElem:"",
    setOpenElem: () => {},
    openElemRow:"",
    setOpenElemRow: () => {},
    WSH:"",
    WEH:'',
    SH:"",
    SM:"",
    WH: "",
    EH:"",
    EM:"",
    subTabs: [],
    customerName: [],
    ProjectTitle: [],
    taskTitle: [],
    selectedWait: [],
    setSelectedWait: () => {},
    selectedApproved: [],
    setSelectedApproved: () => {},
    taskHighlight: [],
    daysInMonth: () => {},
    isWeekday: () => {},
    offRecord: [],
    TabPanel: () => {},
    setProjectTitle: () => {},
    setCustomerName: () => {},
    setTaskTitle: () => {},

    staffPerformance: () => {},
    projectInvolved: [],
    taskHolder: 0,
    overdue: 0,
    progress:0,
    OffSiteDays: 0,
    OffDays: 0,
    absenceDays: 0,
    tripDates: 0,
    checkin: [],
    staffOffDate: [],
    excludeIntervals: [],
    excludeCritical: [],
    partialIntervals: [],
    partialCritical: [],
    value: {},
    setValue: () => {},
    tempId:[],
    setTempId: () => {},
    maxOrder:[],
    setMaxOrder: () => {},
    projProgress: "",
    saveProject: () => {},
    archiveProject: () => {},
    onholdProjData: [],
    onholdProjProgress:[],
    onholdProject: [],
    finishProjData:[],
    finishProjProgress:[],
    finishedProject:[],
    openH:[],
    setOpenH: () => {},
    openF:[],
    setOpenF: () => {},
    typeProject:[],
    setTypeProject: () => {},
    projectIndexes:[],
    setProjectIndexes: () => {},
    setEditedTasks: () => {},
    editedTasks: [],
    subTasks: [],

})

